/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ArcRotateCamera, DynamicTexture, Scene } from '@babylonjs/core';
import { RHIMAPOWearManagementApiClientFunctionalProductType } from '@rhim/rest/measurementService';
import { ColorScales, getColorUsingScaleStartFromEnd, head, isDefined, last } from '@rhim/utils';
import chroma from 'chroma-js';
import { TableLikeData, WallProps } from 'typings/internal/sections';

/**
 * Used to paint a 3d model in Surface Segmentation Continuous mode.
 * It updates a dynamic texture to match the given ColorScales.
 * The width of the texture equals the range of the ColorScales plus 1.
 * ( so if the ColorScales minimum threshold is 0 and the maximum is 180 we will have a 181 pixel wide texture )
 * That extra 1 pixel is used at the rightmost side of the texture and covers all values from ColorScales max threshold to Infinity
 * The height of the texture can be just 1 , but we use 2, because when viewing this texture using babylon's inspector, it does not show
 * up correctly if it has a height of 1.
 *
 * @param dynamicTexture the dynamic texture to update
 * @param colorScales a ColorScales whose thresholds and colors will be used to plot the dynamic texture
 */
export function updateColorScaleTexture(dynamicTexture: DynamicTexture, colorScales: ColorScales) {
  const colorScalesThresholdMin = head(colorScales).threshold;
  const colorScalesThresholdMax = last(colorScales).threshold;
  const colorScalesThresholdRange = Math.floor(colorScalesThresholdMax - colorScalesThresholdMin);
  // resize texture
  const dynamicTextureWidth = colorScalesThresholdRange;
  const dynamicTextureWidthIncludingLastBand = dynamicTextureWidth + 1;
  const dynamicTextureHeight = 2;
  const { width, height } = dynamicTexture.getSize();
  if (width !== dynamicTextureWidthIncludingLastBand || height !== dynamicTextureHeight) {
    dynamicTexture.scaleTo(dynamicTextureWidthIncludingLastBand, dynamicTextureHeight);
  }
  // update texture
  const ctx = dynamicTexture.getContext();
  const imageData: ImageData = ctx.getImageData(0, 0, dynamicTextureWidthIncludingLastBand, 1);
  let threshold;
  for (let index = 0; index < dynamicTextureWidthIncludingLastBand; index++) {
    if (index === dynamicTextureWidth) {
      threshold = colorScalesThresholdMax;
    } else {
      threshold = (colorScalesThresholdRange * index) / dynamicTextureWidth + colorScalesThresholdMin;
    }
    const color = getColorUsingScaleStartFromEnd(threshold, colorScales);
    const colorRgb = chroma(color).rgb();
    imageData.data[4 * index + 0] = colorRgb[0];
    imageData.data[4 * index + 1] = colorRgb[1];
    imageData.data[4 * index + 2] = colorRgb[2];
    imageData.data[4 * index + 3] = 255;
  }
  ctx.putImageData(imageData, 0, 0);
  dynamicTexture.update();
}

export function generateClusteredTexture(dynamicTexture: DynamicTexture, sortedSections: TableLikeData, colorScales: ColorScales) {
  const canvasSize = 2048;
  const { width, height } = dynamicTexture.getSize();
  if (width !== canvasSize || height !== canvasSize) {
    dynamicTexture.scaleTo(canvasSize, canvasSize);
  }
  const ctx = dynamicTexture.getContext();

  const {
    wallplot,
    bottom,
    meta: { columns, rows, bottomRadiusNumber },
  } = sortedSections;

  // The max size to which we're going to scale the radiuses. Make 5% bigger to have a gap
  const maxBottomRadius = bottom[bottomRadiusNumber - 1]!.radius * 1.05;
  const maxDepth = wallplot[rows - 1]!.depth;
  const maxRadius = canvasSize / 4;
  const interpolateDegreesToCanvasWidth = (angleStart: number, angleEnd: number): [number, number] => {
    // the texture should be flipped
    const tStart = (360 - angleStart) / 360;
    const tEnd = (360 - angleEnd) / 360;

    return [canvasSize * tStart, canvasSize * tEnd];
  };

  const interpolateDepthToCanvasHeight = (depth: number): number => {
    const t = depth / maxDepth;
    return (canvasSize / 2) * t;
  };

  const scaleBottomRadius = (radius: number): number => {
    const t = radius / maxBottomRadius;
    return maxRadius * t;
  };

  ctx.fillStyle = 'black';
  const fontSize = 18;
  ctx.font = `${fontSize}px sans-serif`;

  for (let i = 0; i < columns; i++) {
    for (let j = 0; j < rows - 1; j++) {
      const currentSection = wallplot[i * rows + j]!;
      const nextSectionBellowCurrent = wallplot[i * rows + j + 1]!;
      const nextSection = columns === i + 1 ? wallplot[j]! : wallplot[(i + 1) * rows + j]!;

      const [horizontalStart, horizontalEnd] = interpolateDegreesToCanvasWidth(currentSection.angle, nextSection.angle === 0 ? 360 : nextSection.angle);
      const verticalStart = interpolateDepthToCanvasHeight(currentSection.depth);
      const verticalEnd = interpolateDepthToCanvasHeight(nextSectionBellowCurrent.depth);
      const rblValue = wallplot[i * rows + j]?.rbl as number | null;

      ctx.beginPath();
      ctx.fillStyle = getColorUsingScaleStartFromEnd(rblValue ?? 0, colorScales);
      ctx.rect(horizontalStart, verticalStart, horizontalEnd - horizontalStart, verticalEnd - verticalStart);
      ctx.stroke(); // use it for strokes between the rectangles
      ctx.fill();

      ctx.fillStyle = 'black';
      const text = isDefined(rblValue) ? rblValue.toString() : '-';
      const metrics = ctx.measureText(text);
      const x = horizontalStart + (horizontalEnd - horizontalStart - metrics.width) / 2;
      const y = verticalStart + (verticalEnd - verticalStart + fontSize) / 2;
      ctx.fillText(text, x, y);
    }
  }

  // we receive the data values from current pointer to the next
  // that's why limiting loops to "length - 1"
  // values should be used from the current section
  for (let i = 0; i < columns; i++) {
    for (let j = 0; j < bottomRadiusNumber - 1; j++) {
      const currentSection = bottom[i * bottomRadiusNumber + j] as WallProps;
      const nextFartherFromCentreSection = bottom[i * bottomRadiusNumber + j + 1] as WallProps;

      // if it is a lat section, we need to close circle till the first section
      const nextSection = columns === i + 1 ? bottom[j]! : bottom[(i + 1) * bottomRadiusNumber + j]!;

      const angleStart = (currentSection.angle * Math.PI) / 180 - Math.PI / 2;
      const angleEnd = (nextSection.angle * Math.PI) / 180 - Math.PI / 2;

      const radiusStart = scaleBottomRadius(currentSection.radius ?? 0);
      const radiusEnd = scaleBottomRadius(nextFartherFromCentreSection.radius ?? 0);
      const rblValue = currentSection.rbl as number | null;

      ctx.beginPath();
      ctx.fillStyle = getColorUsingScaleStartFromEnd(rblValue ?? 0, colorScales);
      ctx.arc(maxRadius, 3 * maxRadius, radiusStart, angleStart, angleEnd, false);
      ctx.arc(maxRadius, 3 * maxRadius, radiusEnd, angleEnd, angleStart, true);
      ctx.closePath();
      ctx.fill();
      ctx.stroke(); // use it for strokes between the rectangles
    }
  }

  dynamicTexture.update();
}

/**
 * Checks whether the ArcRotateCamera in question is animating either due to inertia (#1) or
 * due to any animatables set on it which are currently in motion (#2).
 *
 * (#1) An ArcRotateCamera has some inertia by default and once the mouse keys have been released it
 * may animate its rotation/position/zoom and gradually slow down before coming to a halt.
 *
 * (#2) For instance, whenever we click the "Home" button in the 3d view,
 * we set a number of animatables on the camera that animate its rotation/target/zoom to their default values.
 * ( see function setCameraView in Viz3dWithControls )
 *
 * @param camera the scene's active ArcRotateCamera
 * @returns true if the camera's animation has completed, false if still animating
 */
export function haveAnimationsStopped(scene: Scene) {
  const cameras = (isDefined(scene.activeCameras) && scene.activeCameras.length > 0 ? scene.activeCameras : [scene.activeCamera]) as ArcRotateCamera[];

  return (
    scene.animatables.length === 0 &&
    cameras.every(
      (camera) =>
        camera.inertialAlphaOffset === 0 &&
        camera.inertialBetaOffset === 0 &&
        camera.inertialRadiusOffset === 0 &&
        camera.inertialPanningX === 0 &&
        camera.inertialPanningY === 0
    )
  );
}

export const isFunctionalProductOnTheSideOfVessel = (type: RHIMAPOWearManagementApiClientFunctionalProductType) =>
  type === RHIMAPOWearManagementApiClientFunctionalProductType.Taphole || type === RHIMAPOWearManagementApiClientFunctionalProductType.Trunnion;
