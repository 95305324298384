import { settings } from '@rhim/design';
import { isDefined, isObject } from '@rhim/utils';
import classNames from 'classnames';
import React, { cloneElement } from 'react';
import styled from 'styled-components';

import { Spinner } from '../Spinner';

const StyledButton = styled.button`
  --color: ${settings.colors.Primary.Grey_2};

  width: var(--rhim-button-width, auto);
  color: var(--color);
  font-family: ${settings.typography.FontFamily.Bold};
  border: none;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  outline: none;
  position: relative;

  &.in-progress,
  &.is-disabled {
    pointer-events: auto;
  }

  svg {
    color: var(--color);
  }

  span.icon-start,
  span.icon-end {
    display: inline-flex;
  }

  /* SIZE */
  &.btn-size-big-56 {
    height: var(--rhim-button-height, 56px);
  }

  &.btn-size-medium-48 {
    height: var(--rhim-button-height, 48px);
  }

  &.btn-size-small-40 {
    height: var(--rhim-button-height, 40px);
  }

  &.btn-size-x-small-32 {
    height: var(--rhim-button-height, 32px);
  }

  /* BACKGROUND */
  &.btn-background-ghost {
    --color: ${settings.colors.Primary.Blue_9};

    background-color: transparent;
    border: 2px solid var(--color);
    font-family: ${settings.typography.FontFamily.Bold};

    &:hover {
      --color: ${settings.colors.Primary.Blue_8};
    }

    &:hover:active:not(.is-disabled) {
      --color: ${settings.colors.Primary.Blue_7};
    }

    .icon-end {
      margin-left: ${settings.Spacing.Spacing_100};
    }

    &.is-disabled {
      --color: ${settings.colors.Primary.Grey_4};
    }
  }

  &.btn-background-ghost-border-mode {
    border: none;
    font-family: ${settings.typography.FontFamily.Regular};
  }

  &.btn-operation-mode {
    --color: ${settings.colors.Primary.Grey_2};

    background-color: ${settings.colors.Primary.Blue_8};

    &.is-disabled {
      background-color: ${settings.colors.Primary.Grey_2};

      --color: ${settings.colors.Primary.Grey_4};
    }

    svg {
      color: var(--color);
    }

    &:hover:not(.is-disabled) {
      background-color: ${settings.colors.Primary.Blue_7};

      --color: ${settings.colors.Monochromatic.White};

      &:active {
        background-color: ${settings.colors.Primary.Blue_6};
      }
    }

    span.operationStatusIndicatorLeftMargin {
      width: 9px;
    }

    span.operation-status-indicator {
      --size: 8px;
      --spacing: ${settings.Spacing.Spacing_100};

      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      position: absolute;
      top: var(--spacing);
      right: var(--spacing);

      &.operation-status-indicator-color-green {
        background-color: ${settings.colors.Operational.State_Green_2};
      }

      &.operation-status-indicator-color-grey {
        background-color: ${settings.colors.Operational.State_Notif_Grey_2};
      }
    }
  }

  &.btn-background-primary-9-brand {
    background-color: ${settings.colors.Primary.Blue_9};
    border-color: ${settings.colors.Primary.Blue_9};
    border-width: 2px;
    border-style: solid;

    &:hover {
      background-color: ${settings.colors.Primary.Blue_8};
      border-color: ${settings.colors.Primary.Blue_8};
    }

    &:active {
      background-color: ${settings.colors.Primary.Blue_7};
      border-color: ${settings.colors.Primary.Blue_7};
    }

    &.is-disabled {
      --color: ${settings.colors.Primary.Grey_4};

      background-color: ${settings.colors.Primary.Grey_2};
      border-color: ${settings.colors.Primary.Blue_2};
    }
  }

  &.btn-background-primary-9-brand-inverted {
    background-color: ${settings.colors.Monochromatic.White};
    color: ${settings.colors.Primary.Blue_9};

    svg {
      color: ${settings.colors.Primary.Blue_9};
    }

    &:hover {
      background-color: ${settings.colors.Primary.Grey_2};
    }

    &:focus {
      outline: 1px solid ${settings.colors.Primary.Grey_4};
      outline-offset: 2px;
    }

    &:active {
      background-color: ${settings.colors.Primary.Blue_1};
    }

    &.is-disabled {
      color: ${settings.colors.Primary.Grey_9};
      background-color: ${settings.colors.Primary.Grey_8};
    }
  }

  /* PADDING, FONT-SIZE */
  &.btn-size-big-56,
  &.btn-size-medium-48 {
    padding: 0 ${settings.Spacing.Spacing_300};
    font-size: ${settings.typography.FontSize.Medium};

    .icon-start svg {
      margin-right: ${settings.Spacing.Spacing_150};
    }

    .icon-end svg {
      margin-left: ${settings.Spacing.Spacing_150};
    }
  }

  &.btn-size-small-40,
  &.btn-size-x-small-32 {
    padding: 0 ${settings.Spacing.Spacing_200};
    font-size: ${settings.typography.FontSize.Small};

    .icon-start {
      margin-right: ${settings.Spacing.Spacing_100};
    }

    .icon-end {
      margin-left: ${settings.Spacing.Spacing_100};
    }
  }

  div.container {
    display: flex;
    justify-content: center;
    align-items: center;

    span.label {
      display: inline-flex;
      flex-shrink: 0;
      white-space: nowrap;
    }

    &.inProgress {
      > span {
        visibility: hidden;
      }
    }
  }

  div.icon-spin-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    overflow: hidden;

    span.icon-spin {
      display: flex;
    }
  }
`;

type ButtonIcon = {
  icon?: React.ReactElement<{ fill?: string }>;
  position: 'start' | 'end';
  loading?: boolean;
};

type ModeOperation = {
  isOperationStatusIndicatorShowing: boolean;
  operationStatusIndicatorColor?: 'green' | 'grey';
};

type Mode = 'filled' | 'ghost' | 'ghost-border' | ModeOperation;

function isOperationMode(mode: Mode): mode is ModeOperation {
  return isObject(mode);
}

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  dataTestId?: string;
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: ButtonIcon;
  className?: string;
  size?: 'big-56' | 'medium-48' | 'small-40' | 'x-small-32';
  mode?: Mode;
  background?: 'primary-9-brand' | 'primary-9-brand-inverted';
  disabled?: boolean;
  isInProgress?: boolean;
  autoFocus?: boolean;
}
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      dataTestId,
      label,
      onClick,
      icon,
      className,
      size = 'small-40',
      mode = 'filled',
      isInProgress = false,
      background = 'primary-9-brand',
      disabled = false,
      autoFocus = false,
      ...rest
    },
    ref
  ) => {
    const buttonClassNames = classNames(
      {
        [`btn-size-${size}`]: true,
        'btn-background-ghost': mode === 'ghost' || mode === 'ghost-border',
        'btn-background-ghost-border-mode': mode === 'ghost-border',
        [`btn-background-${background}`]: mode === 'filled',
        'btn-operation-mode': isOperationMode(mode),
        'is-disabled': disabled,
        'in-progress': isInProgress,
        hmmm: isInProgress,
      },
      className
    );

    const containerClassNames = classNames({
      container: true,
      inProgress: isInProgress,
    });

    const iconWithProps = isDefined(icon) && isDefined(icon.icon) && cloneElement(icon.icon, { fill: 'currentColor' });

    const spinnerIcon = <Spinner size="24" rotationDuration={2.5} inverted={!disabled} />;

    return (
      <StyledButton
        ref={ref}
        data-test-id={dataTestId}
        onClick={onClick}
        type="button"
        className={buttonClassNames}
        autoFocus={autoFocus}
        disabled={disabled}
        {...rest}
      >
        <div className={containerClassNames}>
          {icon?.position === 'start' && <span className="icon-start">{icon.loading === true ? spinnerIcon : iconWithProps}</span>}
          <span className="label">{label}</span>
          {icon?.position === 'end' && <span className="icon-end">{icon.loading === true ? spinnerIcon : iconWithProps}</span>}
          {isOperationMode(mode) && mode.isOperationStatusIndicatorShowing && (
            <>
              <span className="operationStatusIndicatorLeftMargin" />
              <span
                className={classNames({ 'operation-status-indicator': true, [`operation-status-indicator-color-${mode.operationStatusIndicatorColor}`]: true })}
              />
            </>
          )}
          {isInProgress && (
            <div className="icon-spin-container">
              <span className="icon-start">{spinnerIcon}</span>
            </div>
          )}
        </div>
      </StyledButton>
    );
  }
);
// Pretend that Button is an Antd Button so that Antd tooltips work when our own Button is disabled
// See https://github.com/ant-design/ant-design/pull/4865, https://github.com/react-component/tooltip/issues/18#issuecomment-411476678
// @ts-expect-error This is a workaround
Button.__ANT_BUTTON = true;
Button.displayName = 'Button';
