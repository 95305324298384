import { RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto } from '@rhim/rest/customerManagement';
import { hasElements, isDefined, last } from '@rhim/utils';

import { storage } from '../../utilities';

export const getSelectedCustomer = (
  customers: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto[]
): RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto => {
  const customerId = storage.getItem('customerId');

  try {
    const id = isDefined(customerId) ? JSON.parse(customerId) : null;
    const foundCustomer = customers.find((plant: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto) => plant.customerId === id);

    if (isDefined(foundCustomer)) {
      return foundCustomer;
    } else if (hasElements(customers)) {
      return last(customers);
    } else {
      return {} as RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Can't parse plant "${customerId}"`, error);
    return {} as RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto;
  }
};
