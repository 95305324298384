import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto } from '@rhim/rest/fleetOverview';
import React from 'react';
import styled from 'styled-components';

import Heat from './Heat';
import PropertyWithValue from './PropertyWithValue';
import { PrimaryLabel, SSectionContainer as SectionContainer } from './styles';

interface Props {
  initialMeasurement: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto;
}
const SectionInitial: React.ChildlessComponent<Props> = ({ initialMeasurement }) => {
  const { t } = i18nReact.useTranslation(['shared', 'volume']);

  return (
    <SSectionContainer>
      <PrimaryLabel>{t('volume:labelInitial')}</PrimaryLabel>
      <Heat heatFrom={initialMeasurement.heat} />
      <PropertyWithValue label={t('volume:propertyVolume')} value={initialMeasurement.volume} unit={t('shared:units.cubicMeters')} />
      <PropertyWithValue label={t('volume:propertyMinRBL')} value={initialMeasurement.rblMin} unit={t('shared:units.millimetre')} />
    </SSectionContainer>
  );
};

const SSectionContainer = styled(SectionContainer)`
  padding-left: ${settings.Spacing.Spacing_100};
`;

SectionInitial.whyDidYouRender = true;
export default React.memo(SectionInitial);
