import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { Option, OptionContentwithTooltip, Select } from '@rhim/react';
import { RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto } from '@rhim/rest/customerManagement';
import { appBarSelectCustomer, customersDropdownOptions } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import assert from 'assert';
import { BaseOptionType } from 'rc-select/lib/Select';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { storage } from '../../utilities';
import { useAllowedCustomers } from '../../utilities';
import AppContext from '../AppContext';

export const SelectCustomer = function ({
  selectCustomer,
  getPopupContainer,
}: {
  selectCustomer: (customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto) => void;
  getPopupContainer: (node: HTMLElement) => HTMLDivElement | HTMLElement;
}) {
  const { t } = i18nReact.useTranslation(['app']);
  const { user, selectedCustomer, onBeforeSelectedCustomerChange } = useContext(AppContext);
  const customers = useAllowedCustomers(user, selectedCustomer);

  const persistSelectedCustomer = React.useCallback((selectedCustomer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto) => {
    storage.setItem('customerId', JSON.stringify(selectedCustomer.customerId));
  }, []);

  /**
   * Persist the selected customer's id in local storage whenever it changes
   */
  React.useEffect(() => {
    persistSelectedCustomer(selectedCustomer);
  }, [selectedCustomer, persistSelectedCustomer]);

  React.useEffect(() => {
    if (!customers.find((customer) => customer.customerId === selectedCustomer.customerId)) {
      const selectedNewCustomer = customers[0];
      if (isDefined(selectedNewCustomer)) {
        selectCustomer(selectedNewCustomer);
        persistSelectedCustomer(selectedNewCustomer);
      }
    }
  }, [selectCustomer, customers, selectedCustomer.customerId, persistSelectedCustomer]);

  const handleCustomerSelected = React.useCallback(
    (value: string) => {
      if (isDefined(selectedCustomer.customerId) && selectedCustomer.customerId === value) {
        // user just re-clicked on the currently selected customer
        return;
      }
      // Customer is about to change : if there is special handling set, then use that one otherwise allow the change to happen
      const onBeforeCustomerChange = onBeforeSelectedCustomerChange ?? (() => Promise.resolve(true));
      onBeforeCustomerChange(value as UUID).then((shouldProceedWithChangingCustomer: boolean) => {
        if (!shouldProceedWithChangingCustomer) {
          return;
        }
        const customer = customers.find((_customer) => _customer.customerId === value);
        assert(isDefined(customer), `Customer with id : ${value} not found in customers`);
        selectCustomer(customer);
        persistSelectedCustomer(customer);
      });
    },
    [selectedCustomer, selectCustomer, customers, onBeforeSelectedCustomerChange, persistSelectedCustomer]
  );

  return (
    <SSelectCustomer
      showSearch
      onSelect={handleCustomerSelected}
      notFoundContent={<span>{t('app:appBar.noResultFound')}</span>}
      value={selectedCustomer.customerId}
      data-test-id={appBarSelectCustomer}
      size="x-small-32"
      getPopupContainer={getPopupContainer}
      dropdownMatchSelectWidth={false}
      filterOption={(input: string, option: BaseOptionType) => {
        assert(isDefined(option), 'The select must have options to show');
        assert(isDefined(option['label']), '<Option> element is missing a label.');

        return option['label'].toLowerCase().includes(input.toLowerCase());
      }}
    >
      {customers.map((customer) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const customerTitle = customer.customerDisplayName || customer.customerShortName;
        return (
          <Option data-test-id={customersDropdownOptions} key={customer.customerId} value={customer.customerId} label={customerTitle}>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <OptionContentwithTooltip getPopupContainer={getPopupContainer} label={customerTitle!} tooltipTitle={customerTitle!} />
          </Option>
        );
      })}
    </SSelectCustomer>
  );
};

const SSelectCustomer = styled(Select)`
  width: 220px;
  flex-shrink: 0;
  margin-right: ${settings.Spacing.Spacing_150};

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${settings.colors.Primary.Grey_1};
  }
`;
