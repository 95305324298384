import { settings } from '@rhim/design';

export const FIRST_LAST_LABEL_OFFSET = 2;
export const TICK_LABEL_COLOR = settings.colors.Primary.Grey_6;

export const TICK_LABEL_PROPS = {
  fontFamily: `${settings.typography.FontFamily.Regular}`,
  fontSize: `${settings.typography.FontSize.X_Small}`,
  color: TICK_LABEL_COLOR,
  fill: TICK_LABEL_COLOR,
} as const;

export const AXIS_LABEL_HEIGHT = 16;
export const AXIS_LABEL_ICON_SIZE = 16;
export const AXIS_LABEL_OFFSET_VERTICAL = 4;

export const AXIS_STYLES = {
  stroke: settings.colors.Primary.Grey_4,
  tickStroke: settings.colors.Primary.Grey_6,
  tickLength: 4,
  tickLabelProps: TICK_LABEL_PROPS,
};

export const AXIS_BOTTOM_HEIGHT = 40;

export const AXIS_BOTTOM_STYLES = {
  ...AXIS_STYLES,
  tickLabelProps: {
    ...TICK_LABEL_PROPS,
    dy: '0.25em',
    y: 14,
    textAnchor: 'middle',
  },
  top: 0,
  left: 0,
  height: AXIS_BOTTOM_HEIGHT,
} as const;

export const AXIS_LEFT_WIDTH = 56;
export const AXIS_LEFT_HEIGHT = 120;
export const AXIS_LEFT_MARGIN_TOP = AXIS_LABEL_HEIGHT;
export const AXIS_LEFT_MARGIN_BOTTOM = AXIS_LEFT_MARGIN_TOP;

export const AXIS_LEFT_STYLES = {
  ...AXIS_STYLES,
  tickLabelProps: {
    ...TICK_LABEL_PROPS,
    x: -6,
  },
  left: AXIS_LEFT_WIDTH,
  top: AXIS_LEFT_MARGIN_TOP,
};

export const AXIS_RIGHT_WIDTH = 48;
