import { AuthError, RedirectRequest } from '@azure/msal-browser';
import { assert, isDefined, ProgrammerError } from '@rhim/utils';
import { LoaderFunction, LoaderFunctionArgs } from 'react-router-dom';

import { AuthClient, AuthInfo } from './client';
import { isLoginCancelled, isLoginInProgress, isPasswordForgotten } from './errors';

interface Dependencies {
  /**
   * MSAL client.
   */
  authClient: AuthClient;
  /**
   * Authority used to reset the password. Example: `environment.authority + environment.passwordResetPolicy`.
   */
  passwordResetPolicy: string | undefined;
}

interface AccountLoaderFunction extends LoaderFunction {
  (args: LoaderFunctionArgs): Promise<{ account: AuthInfo }>;
}

/**
 * A factory producing a React Router v6 loader concerned with authentication.
 *
 * @see https://react-location.tanstack.com/guides/route-loaders
 */
export const createAuthenticationLoader = ({ authClient, passwordResetPolicy }: Dependencies): AccountLoaderFunction => {
  return async () => {
    try {
      const authInfo = await authClient.rehydrate();
      return { account: authInfo };
    } catch (error) {
      assert(error instanceof AuthError, 'Assumed MSAL throws only instances of AuthError');

      // Otherwise, apply different strategies based on the error
      if (isPasswordForgotten(error) && passwordResetPolicy !== undefined) {
        const loginRequest: RedirectRequest = {
          scopes: authClient.scopes,
          authority: passwordResetPolicy,
        };

        if (isDefined(authClient.appName)) {
          loginRequest.extraQueryParameters = {
            appName: authClient.appName,
          };
        }

        await authClient.loginRedirect(loginRequest);
        throw new Error('Login required');
      } else if (isLoginCancelled(error)) {
        await authClient.logout({ postLogoutRedirectUri: '/' });
        throw new Error('Login cancelled');
      } else if (isLoginInProgress(error)) {
        // Rehydration is async and it takes some time. However, we should never end up here
        // because the result of calling authClient.rehydrate is either a success or an error.
        throw new ProgrammerError('Rehydration should never be in progress');
      } else {
        // If the error is not one of the above, we assume that the user is not authenticated
        // and we should redirect them to the login page. Note that the callbacks will never fire
        // because we are never using the promise returned by `authClient.authenticate`. When `authenticate`
        // is called, it will redirect the user to the login page and the page will be reloaded, thus
        // making the promise returned by `authClient.authenticate` resolve in a vacuum.

        // Instead, the user will load the application again, and this time, the promise returned
        // by `authClient.rehydrate` will be resolved with the account.
        const accountInfo = await authClient.authenticate();
        return { account: accountInfo };
      }
    }
  };
};
