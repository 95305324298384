import { settings } from '@rhim/design';
import React, { FC } from 'react';
import styled from 'styled-components';

type Size = 'small-24' | 'small-16' | 'tiny-12';

interface Props {
  className?: string;
  dataTestId?: string;
  color?: settings.colors.Any;
  fill?: settings.colors.Any;
  size?: Size;
}
export const Tag: FC<React.PropsWithChildren<Props>> = React.memo(function Tag({
  className,
  dataTestId,
  color = settings.colors.Primary.Grey_8,
  fill = settings.colors.Primary.Blue_2,
  size = 'small-24',
  children,
}) {
  return (
    <SSpan data-test-id={dataTestId} className={className} color={color} fill={fill} size={size}>
      {children}
    </SSpan>
  );
});

const SSpan = styled.span<{ color: settings.colors.Any; fill: settings.colors.Any; size: Size }>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 12px;
  padding: 0 ${settings.Spacing.Spacing_100};
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${(props) => {
    switch (props.size) {
      case 'small-24':
      case 'small-16':
        return settings.typography.FontSize.X_Small;
      case 'tiny-12':
        return settings.typography.FontSize.XX_Small;
      default:
        return settings.typography.FontSize.X_Small;
    }
  }};

  --height: ${(props) => {
    switch (props.size) {
      case 'small-24':
        return '24px';
      case 'small-16':
        return '16px';
      case 'tiny-12':
        return '12px';
      default:
        return '24px';
    }
  }};

  height: var(--height);
  line-height: var(--height);
  color: ${(props) => props.color};
  background-color: ${(props) => props.fill};
`;

Tag.whyDidYouRender = true;
