import { SupportedLanguageIsoCode } from '@rhim/i18n';

export const userSettingsARO = {
  decimalSeparator: false,
  language: ['en'] as SupportedLanguageIsoCode[],
  languageDirection: true,
  unitSystem: false,
};

export const userSettingsHMD = {
  decimalSeparator: false,
  language: ['en', 'pt_BR'] as SupportedLanguageIsoCode[],
  languageDirection: false,
  unitSystem: true,
};
