import { useFeatures } from '@paralleldrive/react-feature-toggles';
import {
  ConnectedMachineIcon,
  ExploreParametersIcon,
  FileGenericIcon,
  LadleOverviewIcon,
  MaintenanceIcon,
  Measurement3dIcon,
  VolumeDashboardIcon,
  WearGraphIcon,
} from '@rhim/icons/24';
import { VesselType } from '@rhim/rest';
import { usePrivileges } from '@rhim/sdk/customerManagement';
import {
  connectedMachinesFeature,
  ladleOverviewFeature,
  measurementViewFeature,
  operatorDisplayFeature,
  parametersComparisonFeature,
  predictionReportFeature,
  volumeDashboardFeature,
  wearExplorerFeature,
} from '@rhim/test-ids';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect, useMap } from 'react-use';

import { hasLadleVessel } from '../../lib';
import { CustomerFeatureFlag, Feature, FeatureFlag } from '../../settings/featureFlags';
import { PRIVILEGES, ROUTES } from '../../utilities';
import AppContext from '../AppContext';
import { DisabledFeatureTextQCKlight } from './DisabledFeatureText';

interface SidebarItem {
  /**
   * `undefined` when the feature it not behind a feature flag.
   */
  feature?: Feature | CustomerFeatureFlag | FeatureFlag;
  /**
   * Relative path to the destination.
   */
  destination: ValueOf<typeof ROUTES>;
  /**
   * `true` if the customer has that feature amongst their allowed operations (privileges).
   */
  show: boolean;
  /**
   * Based on feature flags and features enabled for this particular customer.
   */
  disabled: boolean;
  disabledText?: React.ReactNode;
  icon: React.ReactElement;
  label: string;
  position: 'above-separator' | 'below-separator' | 'drawer';
  'data-test-id': string;
}

export function useSidebarItems(): Map<VesselType, NonEmptyArray<SidebarItem>> {
  const { t } = useTranslation(['app']);

  const { selectedCustomer, user } = React.useContext(AppContext);
  const doesCustomerHaveLadleVessel = hasLadleVessel(selectedCustomer);
  const privileges = usePrivileges(user, selectedCustomer);
  const features = useFeatures();

  const hasFleetOverviewAccess = privileges.global.has(PRIVILEGES.AccessFleetOverview) || privileges.customer.has(PRIVILEGES.AccessFleetOverview);
  const hasWearExplorerAccesss = privileges.global.has(PRIVILEGES.AccessWearExplorer) || privileges.customer.has(PRIVILEGES.AccessWearExplorer);
  const hasMeasurementViewAccess = privileges.global.has(PRIVILEGES.AccessMeasurementView) || privileges.customer.has(PRIVILEGES.AccessMeasurementView);
  const hasRhLegacyPredictionAccess =
    privileges.global.has(PRIVILEGES.AccessRhLegacyPrediction) || privileges.customer.has(PRIVILEGES.AccessRhLegacyPrediction);
  const hasOperatorDisplayAccess = privileges.global.has(PRIVILEGES.AccessOperatorDisplay) || privileges.customer.has(PRIVILEGES.AccessOperatorDisplay);
  const hasCompareProcessParameterAccess =
    privileges.global.has(PRIVILEGES.CompareProcessParameter) || privileges.customer.has(PRIVILEGES.CompareProcessParameter);

  const allItems = React.useMemo(() => {
    // Figure out if we need to include these conditions as well
    //  featuresForThisVesselType.includes(Feature.FleetOverview);
    // featuresForThisVesselType.includes(Feature.Display)
    return {
      [ROUTES.FLEET_OVERVIEW]: {
        feature: Feature.FleetOverview,
        position: 'above-separator',
        destination: ROUTES.FLEET_OVERVIEW,
        show: doesCustomerHaveLadleVessel && hasFleetOverviewAccess,
        disabled: !features.includes(CustomerFeatureFlag.QCKLight),
        icon: <LadleOverviewIcon />,
        label: t('app:appBar.fleetOverview'),
        'data-test-id': ladleOverviewFeature,
      },
      [ROUTES.REPORT]: {
        feature: Feature.Report,
        position: 'above-separator',
        destination: ROUTES.REPORT,
        show: hasWearExplorerAccesss,
        disabled: !features.includes(CustomerFeatureFlag.WearExplorer),
        icon: <WearGraphIcon />,
        label: t('app:appBar.report'),
        'data-test-id': wearExplorerFeature,
      },
      [ROUTES.RH_REPORT]: {
        feature: Feature.RhReport,
        position: 'above-separator',
        destination: ROUTES.RH_REPORT,
        show: hasRhLegacyPredictionAccess,
        disabled: !features.includes(CustomerFeatureFlag.LegacyRhView),
        icon: <FileGenericIcon />,
        label: t('app:appBar.rhreport'),
        'data-test-id': predictionReportFeature,
      },
      [ROUTES.DISPLAY]: {
        feature: Feature.Display,
        position: 'above-separator',
        destination: ROUTES.DISPLAY,
        show: hasOperatorDisplayAccess,
        disabled: !features.includes(CustomerFeatureFlag.OperatorDisplay),
        icon: <MaintenanceIcon />,
        label: t('app:appBar.display'),
        'data-test-id': operatorDisplayFeature,
      },
      [ROUTES.PARAMETERS_COMPARISON]: {
        feature: CustomerFeatureFlag.ParameterComparison,
        position: 'above-separator',
        destination: ROUTES.PARAMETERS_COMPARISON,
        show: hasCompareProcessParameterAccess,
        disabled: !features.includes(CustomerFeatureFlag.ParameterComparison),
        icon: <ExploreParametersIcon />,
        label: t('app:appBar.parametersComparison'),
        'data-test-id': parametersComparisonFeature,
      },
      [ROUTES.MEASUREMENT_VIEW]: {
        feature: Feature.MeasurementView,
        position: 'below-separator',
        destination: ROUTES.MEASUREMENT_VIEW,
        show: hasMeasurementViewAccess,
        disabled: !features.includes(CustomerFeatureFlag.QCKLight),
        disabledText: <DisabledFeatureTextQCKlight />,
        icon: <Measurement3dIcon />,
        label: t('app:appBar.measurementView'),
        'data-test-id': measurementViewFeature,
      },
      [ROUTES.CONNECTED_MACHINES]: {
        feature: Feature.ConnectedMachines,
        position: 'below-separator',
        destination: ROUTES.CONNECTED_MACHINES,
        show: hasMeasurementViewAccess, // TODO: think about explicit CM access
        disabled: !features.includes(CustomerFeatureFlag.QCKLight),
        disabledText: <DisabledFeatureTextQCKlight />,
        icon: <ConnectedMachineIcon />,
        label: t('app:appBar.connectedMachines'),
        'data-test-id': connectedMachinesFeature,
      },
      [ROUTES.VOLUME_CALCULATIONS]: {
        feature: Feature.VolumeCalculations,
        position: 'below-separator',
        destination: ROUTES.VOLUME_CALCULATIONS,
        show: hasFleetOverviewAccess,
        disabled: !features.includes(CustomerFeatureFlag.VolumeCalculations),
        disabledText: <DisabledFeatureTextQCKlight />,
        icon: <VolumeDashboardIcon />, // TODO use new icon
        label: t('app:appBar.volumeExplorer'),
        'data-test-id': volumeDashboardFeature,
      },
    } satisfies Partial<Record<ValueOf<typeof ROUTES>, SidebarItem>>;
  }, [
    doesCustomerHaveLadleVessel,
    features,
    hasCompareProcessParameterAccess,
    hasFleetOverviewAccess,
    hasMeasurementViewAccess,
    hasOperatorDisplayAccess,
    hasRhLegacyPredictionAccess,
    hasWearExplorerAccesss,
    t,
  ]);

  const [destinations, { setAll }] = useMap(
    new Map<VesselType, NonEmptyArray<SidebarItem>>([
      [VesselType.Bof, [allItems[ROUTES.REPORT], allItems[ROUTES.DISPLAY], allItems[ROUTES.PARAMETERS_COMPARISON], allItems[ROUTES.MEASUREMENT_VIEW]]],
      [
        VesselType.Eaf,
        [allItems[ROUTES.DISPLAY], allItems[ROUTES.MEASUREMENT_VIEW], allItems[ROUTES.CONNECTED_MACHINES], allItems[ROUTES.VOLUME_CALCULATIONS]],
      ],
      [VesselType.Ladle, [allItems[ROUTES.FLEET_OVERVIEW], allItems[ROUTES.DISPLAY], allItems[ROUTES.MEASUREMENT_VIEW]]],
      [VesselType.Rh, [allItems[ROUTES.REPORT], allItems[ROUTES.PARAMETERS_COMPARISON], allItems[ROUTES.RH_REPORT]]],
      [VesselType.Rhl, [allItems[ROUTES.MEASUREMENT_VIEW]]],
      [VesselType.Aod, [allItems[ROUTES.MEASUREMENT_VIEW]]],
      [VesselType.Bfr, [allItems[ROUTES.MEASUREMENT_VIEW]]],
      [VesselType.Srf, [allItems[ROUTES.MEASUREMENT_VIEW]]],
      [VesselType.Cou, [allItems[ROUTES.MEASUREMENT_VIEW]]],
    ])
  );

  // TODO: something is inherently wrong with this duplicated setup
  // When `allItems` change, we need to recompute the destinations
  useDeepCompareEffect(() => {
    setAll(
      new Map<VesselType, NonEmptyArray<SidebarItem>>([
        [VesselType.Bof, [allItems[ROUTES.REPORT], allItems[ROUTES.DISPLAY], allItems[ROUTES.PARAMETERS_COMPARISON], allItems[ROUTES.MEASUREMENT_VIEW]]],
        [
          VesselType.Eaf,
          [allItems[ROUTES.DISPLAY], allItems[ROUTES.MEASUREMENT_VIEW], allItems[ROUTES.CONNECTED_MACHINES], allItems[ROUTES.VOLUME_CALCULATIONS]],
        ],
        [VesselType.Ladle, [allItems[ROUTES.FLEET_OVERVIEW], allItems[ROUTES.DISPLAY], allItems[ROUTES.MEASUREMENT_VIEW]]],
        [VesselType.Rh, [allItems[ROUTES.REPORT], allItems[ROUTES.PARAMETERS_COMPARISON], allItems[ROUTES.RH_REPORT]]],
        [VesselType.Rhl, [allItems[ROUTES.MEASUREMENT_VIEW]]],
        [VesselType.Aod, [allItems[ROUTES.MEASUREMENT_VIEW]]],
        [VesselType.Bfr, [allItems[ROUTES.MEASUREMENT_VIEW]]],
        [VesselType.Srf, [allItems[ROUTES.MEASUREMENT_VIEW]]],
        [VesselType.Cou, [allItems[ROUTES.MEASUREMENT_VIEW]]],
      ])
    );
  }, [allItems, setAll]);

  return destinations;
}
