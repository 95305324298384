import { useEffectOnce } from '@rhim/react';
import { RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto } from '@rhim/rest/customerManagement';
import { assert, isDefined } from '@rhim/utils';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AppContext from '../app/AppContext';
import { Invariants } from '../errors';
import { useCustomerIdHashmap } from '../hooks/useCustomerIdHashmap';
import { PARAMS } from '../lib';

const useShortHashedSelectedCustomer = (customerId: UUID, route: string) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const queryCustomerHash = params.get(PARAMS.CUSTOMER_ID);
  const { getCustomerIdByHash, getCustomerIdParam } = useCustomerIdHashmap();
  const { user, setOnBeforeSelectedCustomerChange, setSelectedCustomer, onBeforeSelectedCustomerChange } = useContext(AppContext);

  const hasCustomerAccess = useCallback(
    (): boolean => !isDefined(queryCustomerHash) || (isDefined(queryCustomerHash) && isDefined(getCustomerIdByHash(queryCustomerHash))),
    [getCustomerIdByHash, queryCustomerHash]
  );

  const onBeforeSelectedCustomerChangeCallback = useMemo(() => {
    return () => (newCustomerId: UUID) => {
      return new Promise<boolean>((resolve) => {
        navigate(`${route}?${getCustomerIdParam(newCustomerId)}`, { replace: true });
        resolve(true);
      });
    };
  }, [route, navigate, getCustomerIdParam]);

  useEffect(() => {
    if (!isDefined(user.customers) || !isDefined(queryCustomerHash)) {
      return;
    }

    if (!isDefined(onBeforeSelectedCustomerChange)) {
      setOnBeforeSelectedCustomerChange(onBeforeSelectedCustomerChangeCallback);
    }

    if (!hasCustomerAccess()) {
      throw new Error(Invariants.NoAccessToCustomer);
    }

    const queryActualCustomerId = getCustomerIdByHash(queryCustomerHash);
    if (queryActualCustomerId !== customerId) {
      const customer = user.customers.find(
        (_customer: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto) => _customer.customerId === queryActualCustomerId
      );
      assert(isDefined(customer), `Customer with id : ${queryActualCustomerId} not found in customers`);
      setSelectedCustomer(customer);
    }
  }, [
    customerId,
    hasCustomerAccess,
    onBeforeSelectedCustomerChange,
    setOnBeforeSelectedCustomerChange,
    onBeforeSelectedCustomerChangeCallback,
    user.customers,
    queryCustomerHash,
    getCustomerIdByHash,
    setSelectedCustomer,
  ]);

  useEffectOnce(() => {
    return () => {
      if (onBeforeSelectedCustomerChange) {
        // On unmount, reset the "onBeforeSelectedCustomerChange" to its default value of undefined
        setOnBeforeSelectedCustomerChange(undefined);
      }
    };
  });

  return { hasCustomerAccess };
};

export default useShortHashedSelectedCustomer;
