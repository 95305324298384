import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { HeatIcon } from '@rhim/icons/16';
import { DropdownPanel, Option, OptionContent, Select } from '@rhim/react';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto } from '@rhim/rest/fleetOverview';
import { ensure, isDefined } from '@rhim/utils';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import MeasurementSelectionItem, { DropdownMeasurement } from '../../../components/MeasurementSelectionDropdown/MeasurementSelectionItem';
import { isSiblingMeasurementOfSameHeat } from '../../MeasurementView/utils';
import MeasurementSelectionShortcut from './MeasurementSelectionShortcut';
import { useCustomerTimezoneName } from './useCustomerTimezoneName';

export const PICKER_WIDTH_PX = 125;

export const SORT_BY_DECREASING_MEASUREMENT_TAKEN = (a: { measurementTaken: string }, b: { measurementTaken: string }) =>
  new Date(b.measurementTaken).getTime() - new Date(a.measurementTaken).getTime();

interface Props {
  className?: string;
  showShortcuts?: boolean;
  selectedCompareToMeasurement: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto | undefined;
  sortedMeasurementsToCompareTo: DropdownMeasurement[];
  onMeasurementClicked: (measurementId: string) => void;
}
const ComparisonPicker: React.ChildlessComponent<Props> = ({
  className,
  showShortcuts = true,
  selectedCompareToMeasurement,
  sortedMeasurementsToCompareTo,
  onMeasurementClicked,
}) => {
  const { t } = i18nReact.useTranslation(['app', 'volume']);
  const ref = useRef<HTMLDivElement>(null);
  const timezoneName = useCustomerTimezoneName();
  const [isExpanded, setIsExpanded] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const measurementItemsRefs = useRef<HTMLDivElement[]>([]);
  measurementItemsRefs.current = [];

  const addToRefs = (el: HTMLDivElement | null) => {
    if (isDefined(el)) {
      measurementItemsRefs.current.push(el);
    }
  };

  // Filter available measurements by input value
  const filteredMeasurementsToCompareTo: DropdownMeasurement[] = useMemo(() => {
    return filterValue !== ''
      ? sortedMeasurementsToCompareTo.filter((measurementToCompareTo) => measurementToCompareTo.heat.toString().includes(filterValue))
      : sortedMeasurementsToCompareTo;
  }, [filterValue, sortedMeasurementsToCompareTo]);

  const collapseDropdown = useCallback(() => {
    setIsExpanded(false);
    setFilterValue('');
  }, []);

  const handleMouseClick = useCallback(
    (event: MouseEvent) => {
      if (isDefined(ref.current) && !ref.current.contains(event.target as HTMLElement)) {
        collapseDropdown();
      }
    },
    [collapseDropdown]
  );

  useEffect(() => {
    document.addEventListener('click', handleMouseClick, true);
    return () => document.removeEventListener('click', handleMouseClick);
  }, [handleMouseClick]);

  const scrollToMeasurement = useCallback(
    (measurementId: string) => {
      const index = sortedMeasurementsToCompareTo.findIndex((measurement) => measurement.id === measurementId);
      if (index === -1) {
        return;
      }
      const measurementItemRef = measurementItemsRefs.current[index];
      if (!isDefined(measurementItemRef)) {
        return;
      }
      measurementItemRef.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'start',
      });
    },
    [sortedMeasurementsToCompareTo]
  );

  const handleMeasurementClicked = useCallback(
    (measurementId: string) => {
      collapseDropdown();
      onMeasurementClicked(measurementId);
      setFilterValue('');
    },
    [collapseDropdown, onMeasurementClicked]
  );

  const handleDropdownVisibleChange = useCallback(() => {
    setIsExpanded(true);
  }, []);

  /**
   * Once the dropdown expands & its items shown and get refs assigned to them,
   * scroll into view the currently selected compare-to measurement.
   */
  useEffect(() => {
    if (isExpanded && isDefined(selectedCompareToMeasurement)) {
      scrollToMeasurement(selectedCompareToMeasurement.measurementId);
    }
  }, [isExpanded, selectedCompareToMeasurement, scrollToMeasurement]);

  const measurementPrevious = ensure(sortedMeasurementsToCompareTo[0]);
  const measurementInitial = ensure(sortedMeasurementsToCompareTo[sortedMeasurementsToCompareTo.length - 1]);

  return (
    <SWrapper ref={ref} className={className}>
      <Select
        showSearch
        value={selectedCompareToMeasurement?.measurementId}
        size="x-small-32"
        open={false}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        searchValue={filterValue}
        onSearch={(value: string) => {
          setFilterValue(value);
        }}
      >
        {isDefined(selectedCompareToMeasurement) && (
          <Option
            key={selectedCompareToMeasurement.measurementId}
            value={selectedCompareToMeasurement.measurementId}
            label={selectedCompareToMeasurement.heat.toString()}
          >
            <OptionContent icon={<HeatIcon />} label={selectedCompareToMeasurement.heat.toString()} />
          </Option>
        )}
      </Select>
      {isExpanded && (
        <SDropdownMeasurementsContainer>
          {showShortcuts && (
            <SMeasurementSelectionShortcutsContainer>
              <MeasurementSelectionShortcut
                measurementId={measurementPrevious.id}
                label={t('volume:shortcutMeasurementPrevious')}
                heat={measurementPrevious.heat}
                isSelected={isDefined(selectedCompareToMeasurement) ? measurementPrevious.id === selectedCompareToMeasurement.measurementId : false}
                onShortcutMeasurementClicked={handleMeasurementClicked}
              />
              <MeasurementSelectionShortcut
                measurementId={measurementInitial.id}
                label={t('volume:shortcutMeasurementInitial')}
                heat={measurementInitial.heat}
                isSelected={isDefined(selectedCompareToMeasurement) ? measurementInitial.id === selectedCompareToMeasurement.measurementId : false}
                onShortcutMeasurementClicked={handleMeasurementClicked}
              />
            </SMeasurementSelectionShortcutsContainer>
          )}
          <SDropdownPanel isExpanded={true}>
            {filteredMeasurementsToCompareTo.length !== 0 ? (
              <STable cellPadding="0" cellSpacing="0">
                <tbody>
                  {filteredMeasurementsToCompareTo.map((measurementToCompareTo) => {
                    return (
                      <MeasurementSelectionItem
                        ref={addToRefs}
                        variant="small-40"
                        showMeasurementIcon={false}
                        showTimeAgo={false}
                        isEarlierMeasurementOfSameHeat={isSiblingMeasurementOfSameHeat(measurementToCompareTo, 'earlier', sortedMeasurementsToCompareTo)}
                        isLaterMeasurementOfSameHeat={isSiblingMeasurementOfSameHeat(measurementToCompareTo, 'later', sortedMeasurementsToCompareTo)}
                        key={measurementToCompareTo.id}
                        measurement={measurementToCompareTo}
                        isSelectedMeasurement={measurementToCompareTo.id === selectedCompareToMeasurement?.measurementId}
                        onMeasurementClicked={handleMeasurementClicked}
                        customerTimezone={timezoneName}
                      />
                    );
                  })}
                </tbody>
              </STable>
            ) : (
              <SNoResultsFound>{t('app:appBar.noResultFound')}</SNoResultsFound>
            )}
          </SDropdownPanel>
        </SDropdownMeasurementsContainer>
      )}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: ${PICKER_WIDTH_PX}px;
`;

const SDropdownPanel = styled(DropdownPanel)`
  min-width: 100%;
  max-height: 400px;
  position: static;
`;

const STable = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid ${settings.colors.Primary.Grey_2};

    td {
      white-space: nowrap;
    }
  }
`;

const SNoResultsFound = styled.span`
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.Small};
  line-height: 32px;
  color: ${settings.colors.Primary.Grey_4};
  padding: ${settings.Spacing.Spacing_50} ${settings.Spacing.Spacing_150};
`;

const SMeasurementSelectionShortcutsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${settings.colors.Primary.Grey_3};
`;

const SDropdownMeasurementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  row-gap: 2px;
  background-color: ${settings.colors.Primary.Grey_2};
  z-index: 999;
`;

ComparisonPicker.whyDidYouRender = true;
export default React.memo(ComparisonPicker);
