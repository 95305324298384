import { settings } from '@rhim/design';
import { IconButton } from '@rhim/react';
import { movementTypeToggleMeasurementView } from '@rhim/test-ids';
import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

interface ToggleButtonProps {
  control: Control;
  selectedControlId: string;
  onToggleClicked: (selectedControlId: string) => void;
}
const ToggleButton: FC<React.PropsWithChildren<ToggleButtonProps>> = ({ control, selectedControlId, onToggleClicked }) => {
  const handleButtonClicked = useCallback(() => {
    onToggleClicked(control.id);
  }, [control.id, onToggleClicked]);

  return (
    <SIconButton
      key={control.id}
      icon={control.icon}
      tooltip={control.tooltip}
      tooltipPlacement="right"
      isSelected={control.id === selectedControlId}
      onPress={handleButtonClicked}
      isFilled={control.id === selectedControlId}
    />
  );
};

export type Control = {
  icon: React.ReactElement;
  id: string;
  tooltip: string;
};

interface Props {
  className?: string;
  controls: Control[];
  onToggleClicked: (selectedControlId: string) => void;
  selectedControlId: string;
}

const FloatingToggleToolbar: FC<React.PropsWithChildren<Props>> = ({ className, controls, selectedControlId, onToggleClicked }) => {
  return (
    <StyledWrapper data-test-id={movementTypeToggleMeasurementView} className={className}>
      {controls.map((control) => (
        <ToggleButton key={control.id} control={control} selectedControlId={selectedControlId} onToggleClicked={onToggleClicked} />
      ))}
    </StyledWrapper>
  );
};

export default React.memo(FloatingToggleToolbar);

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${settings.Spacing.Spacing_300};
  z-index: 1;
`;

interface StyledButtonProps {
  isSelected: boolean;
}

const SIconButton = styled(IconButton)<StyledButtonProps>`
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => (props.isSelected ? `1px solid ${settings.colors.Primary.Blue_9}` : `1px solid ${settings.colors.Primary.Grey_3}`)};
  background-color: ${(props) => (props.isSelected ? settings.colors.Primary.Blue_9 : settings.colors.Monochromatic.White)};

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-width: ${(props) => (props.isSelected ? '2px' : '0')};
  }

  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-width: ${(props) => (props.isSelected ? '2px' : '0')};
  }
`;
