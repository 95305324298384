import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto } from '@rhim/rest/fleetOverview';
import React from 'react';

import Heat from './Heat';
import PropertyWithValue from './PropertyWithValue';
import { PrimaryLabel, SSectionContainer } from './styles';

interface Props {
  currentMeasurement: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto | undefined;
}
const SectionCurrent: React.ChildlessComponent<Props> = ({ currentMeasurement }) => {
  const { t } = i18nReact.useTranslation(['shared', 'volume']);

  return (
    <SSectionContainer style={{ backgroundColor: settings.colors.Primary.Blue_1 }}>
      <PrimaryLabel backgroundColor={settings.colors.Primary.Blue_8}>{t('volume:labelCurrent')}</PrimaryLabel>
      <Heat heatFrom={currentMeasurement?.heat} />
      <PropertyWithValue label={t('volume:propertyVolume')} value={currentMeasurement?.volume} unit={t('shared:units.cubicMeters')} />
      <PropertyWithValue label={t('volume:propertyMinRBL')} value={currentMeasurement?.rblMin} unit={t('shared:units.millimetre')} />
    </SSectionContainer>
  );
};

SectionCurrent.whyDidYouRender = true;
export default React.memo(SectionCurrent);
