import { AuthError } from '@azure/msal-browser';
import { PermissionManager } from '@rhim/react';
import { RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto, RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto } from '@rhim/rest/customerManagement';
import { hasElements, isDefined, sortAlphabeticallyBy, specific } from '@rhim/utils';
import { ExpressiveQueryKey, QueryFunctionContext, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/customerManagement';
import { authClient } from '../utilities';

type Payload = RHIMAPOCustomerManagementWebV2ModelsUserInfoResponseDto;

export const sortCustomers = (
  customers: RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto[] | null | undefined
): RHIMAPOCustomerManagementWebV2ModelsCustomerSummaryDto[] | null | undefined => {
  if (isDefined(customers)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return [...customers].sort(sortAlphabeticallyBy('customerDisplayName', 'customerShortName'));
  }
  return customers;
};

export const useUserInfo = (
  apoID?: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
): UseQueryResult<Payload | undefined> => {
  return useQuery(getKey(apoID), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    enabled: isDefined(apoID),
  });
};

const getKey = (apoId?: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'customerManagement',
      scope: 'user-info',
      entity: 'detail',
      apoId,
    },
  ]);

const queryFn = ({ queryKey: [{ apoId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.userInfo
    .getUserinfoMe(apoId, { signal, ...axiosConfiguration })
    .then((response) => ({ ...response.data, customers: sortCustomers(response.data.customers) }))
    .then(async (userInfo) => {
      // Stale token check
      // #222757 detect stale tokens by doing a sanity check if the information in the token still matches the current user roles
      // if not, we trigger an automatic logout to force the user to login again. After this, the current data will be represented in the token as well
      const activeToken = await authClient.rehydrate();
      const permissions = new PermissionManager(activeToken);
      const plants: string[] = permissions.getAssociatedPlants();
      const assignedCustomers = userInfo.customers;

      if (!isDefined(assignedCustomers) || !hasElements(assignedCustomers)) {
        // check if we have a breaking change in customer assignment
        if (hasElements(plants)) {
          // user has no assigned customers currently, but the active token still has plants assigned -> customer got removed in the meanwhile and we have to log out the user to purge the token
          await authClient.logout({ postLogoutRedirectUri: window.location.origin });
        }

        // no customer assigned yet and token is in-sync -> continue since this is a valid state (we might want to present a welcome screen or something)
        return userInfo;
      }

      // if the user has a global role, they will be able to access any customer, therefore no stale token check needed
      if (hasElements(permissions.getGlobalRoles())) {
        return userInfo;
      }

      // detect stale tokens by cross checking all customers from the backend with the information in the token to ensure token information is up-to-date (divergencies can happen if the user assignments have changed during the token lifetime)
      if (!assignedCustomers.every((customer) => plants.some((plantId) => plantId === customer.customerId))) {
        throw AuthError.createUnexpectedError('Stale user information. Please logout and login again');
      }

      // token information is up-to-date -> good to go!
      return userInfo;
    });
};

useUserInfo.getKey = getKey;
useUserInfo.queryFn = queryFn;
