import { useMemoCompare } from '@rhim/react';
import { RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto } from '@rhim/rest/wearManagement';
import { hasElements, head, isDefined } from '@rhim/utils';
import { join } from 'lodash';
import React from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { LOCAL_STORAGE_PREFIX_APO } from '../utilities/storage';

const logger = log.child('useSelectedReport');

function useSelectedReportId() {
  return useLocalStorageState<string | null>(join([LOCAL_STORAGE_PREFIX_APO, 'selectedReportId'], ''), {
    defaultValue: null,
  });
}

export function useSelectedReport(
  reports?: RHIMAPOReportingWearManagementApiV1ModelsPredictionOverviewDto[]
): [string | null, React.Dispatch<React.SetStateAction<string | null>>] {
  const [persistedReportId, setPersistedReportId] = useSelectedReportId();

  logger.debug(`Launched report id persistence. Initial value: ${persistedReportId}`);

  React.useDebugValue(persistedReportId);

  React.useEffect(() => {
    if (!isDefined(reports)) {
      logger.debug('⛔️ No reports available. Removing the persisted report id from local storage.');

      setPersistedReportId(null);
      return;
    }
    const persistedReport = reports.find((report) => report.id === persistedReportId);
    const isPersistedReportIdValid = isDefined(persistedReport);

    if (isPersistedReportIdValid) {
      logger.debug(
        `✅ Persisted report id DOES belong to one of the availabe reports. Returning the report id found in local storage: ${persistedReport.id}. All reports:`,
        reports.map((report) => report.id)
      );

      return;
    } else if (hasElements(reports)) {
      const firstAvailableReport = head(reports);

      logger.debug(
        `⚠️ Persisted report id DOES NOT belong to one of the available reports. Returning the first report id available : ${firstAvailableReport.id}. All reports:`,
        reports.map((report) => report.id)
      );

      setPersistedReportId(firstAvailableReport.id);
    } else {
      logger.debug('⛔️ No reports available. Removing the persisted reports id from local storage.');

      setPersistedReportId(null);
    }
  }, [persistedReportId, setPersistedReportId, reports]);

  const memoizedValue: [string | null, React.Dispatch<React.SetStateAction<string | null>>] = useMemoCompare(
    [persistedReportId, setPersistedReportId],
    ([previousId], [nextId]) => {
      return previousId === nextId;
    }
  );

  return memoizedValue;
}
