import {
  Configuration,
  MeasurementFilesApi,
  MeasurementViewApi,
  PointCloudModificationsApi,
  PointCloudRegisterApi,
  ReferenceMeasurementsApi,
  UploadApi,
  ValidateApi,
} from '@rhim/rest/measurementService';

import { axiosApiInstance } from '../../utilities';

const configuration: Configuration = {} as Configuration;

const BASE_URL = '/api/measurementservice/v1';

export const referenceMeasurements = new ReferenceMeasurementsApi(configuration, BASE_URL, axiosApiInstance);
export const measurementFiles = new MeasurementFilesApi(configuration, BASE_URL, axiosApiInstance);
export const pointCloud = {
  modify: new PointCloudModificationsApi(configuration, BASE_URL, axiosApiInstance),
  register: new PointCloudRegisterApi(configuration, BASE_URL, axiosApiInstance),
};
export const measurementView = new MeasurementViewApi(configuration, BASE_URL, axiosApiInstance);
export const validateApi = new ValidateApi(configuration, BASE_URL, axiosApiInstance);
export const qcklightUploadApi = new UploadApi(configuration, BASE_URL, axiosApiInstance);
