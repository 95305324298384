/**
 * Please follow the naming convention: <name of Element> <type of Element> <location of Element>
 * e.g. customersDropdownAppBar
 */

export const tooltipContentLabel = 'tooltipContentLabel';

export const optionContentLabel = 'optionContentLabel';

//Splash Screen
export const splashScreenContainer = 'splash';
export const startButtonSplashScreen = 'start';
export const cantSignInLabel = 'cantSignIn';
export const getInTouchLinkSplashScreen = 'getInTouch';
export const titleSplashScreen = 'title';
export const subTitleSplashScreen = 'subTitle';
export const versionLabelSplashScreen = 'version';

//Footer
export const footer = 'footer';
export const imprintLinkFooter = 'imprintLink';
export const privacyLinkFooter = 'privacyLink';
export const termsOfUseLinkFooter = 'touLink';
export const copyrightsLabelFooter = 'copyrightInfo';

//AppBar
export const appBar = 'appBar';
export const appBarProductShortName = 'appBarProductShortName';
export const userSettingsCogButtonAppBar = 'userSettingsCogButton';
export const homeLinkAppBar = 'home';
export const navigationItemsContainerAppBar = 'navigationItemsContainerAppBar';
export const logOutLinkAppBar = 'LOGOUT';
export const appBarSelectCustomer = 'appBarSelectCustomer';
export const userNameLabelAppBar = 'userNameLabel';
export const menuListItemName = 'MenuListItem';

//DataCenter
export const rhSelectorButtonDataCenter = 'rh-selector';
export const aodSelectorButtonDataCenter = 'aod-selector';
export const srfSelectorButtonDataCenter = 'srf-selector';

export const bfrSelectorButtonDataCenter = 'bfr-selector';

export const bofSelectorButtonDataCenter = 'bof-selector';
export const ladleSelectorButtonDataCenter = 'ladle-selector';
export const rhLowerVesselSelectorButtonDataCenter = 'rhl-selector';
export const eafSelectorButtonDataCenter = 'eaf-selector';
export const couSelectorButtonDataCenter = 'cou-selector';
export const crkSelectorButtonDataCenter = 'crk-selector';

//Measurement View
export const threeDVisualizationContainerMeasurementView = '3dVisualizationWrapper';
export const movementTypeToggleMeasurementView = 'FloatingToggleToolbar';
export const modelViewButtonsMeasurementView = 'FloatingFunctionButtons';
export const threeDViewMeasurementView = '3dView';
export const rotationYWidgetMeasurementView = 'RotationDegreesWidgetY';
export const rotationXWidgetMeasurementView = 'RotationDegreesWidgetX';
export const colorMappingPanelMeasurementView = 'mappingPanel';
export const visualizationPanelContainerMeasurementView = 'visualizationPanelContainerMeasurementView';
export const colorScaleStepContainersMeasurementView = 'colorScaleStepContainersMeasurementView';

//Legal Information
export const legalInformationContainer = 'legalInfo';
export const titleLegalInformationContainer = 'title';
export const tabContainerLegalInformationContainer = 'tabView';

// Modal
export const modalPrimaryButton = 'modalPrimaryButton';
export const modalCancelButton = 'modalCancelButton';

// Modal Dialog
export const modalDialogCancelButton = 'modalDialogCancelButton';
export const modalDialogSecondaryButton = 'modalDialogSecondaryButton';
export const modalDialogPrimaryButton = 'modalDialogPrimaryButton';
export const modalDialogHeader = 'modalDialogHeader';
export const modalDialogDescription = 'modalDialogDescription';

// Error Page
export const errorPanelContainerErrorPage = 'errorPanelContainerErrorPage';
export const errorPageHeadingLabelErrorPage = 'errorPageHeading';
export const errorPageSubHeadingLabelErrorPage = 'errorPageSubHeading';
export const primaryButtonErrorPage = 'primaryButtonErrorPage';
export const secondaryButtonErrorPage = 'secondaryButtonErrorPage';

//Left sidebar
export const wrapperLeftSidebar = 'wrapperLeftSidebar';
export const expandToggleButtonLeftSidebar = 'expandToggleButton';

//Legend
export const legendWrapper = 'legendWrapper';
