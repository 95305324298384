import { createStorage, isFunction } from '@rhim/utils';
import { join } from 'lodash';
import useLocalStorageState from 'use-local-storage-state';

export const LOCAL_STORAGE_PREFIX_APO = 'apo-portal.';

// eslint-disable-next-line no-storage/no-browser-storage
export const storage = createStorage(window.localStorage, LOCAL_STORAGE_PREFIX_APO);

// eslint-disable-next-line no-storage/no-browser-storage
export const sessionStorage = createStorage(window.sessionStorage, LOCAL_STORAGE_PREFIX_APO);

type PromisifyMethods<T> = {
  [K in keyof T]: T[K] extends AnyFunction ? (...parameters: Parameters<T[K]>) => Promise<ReturnType<T[K]>> : T[K];
};

type AsynchronousStorage = PromisifyMethods<Storage>;

export function promisify(store: Storage): AsynchronousStorage {
  return new Proxy(store, {
    get(target, name: string) {
      const value = target[name];
      if (isFunction(value)) {
        return function (...args: unknown[]) {
          return Reflect.apply(value, storage, args);
        };
      }

      return Reflect.get(target, name, target);
    },
  }) as unknown as AsynchronousStorage;
}

export function useCookieNotice() {
  return useLocalStorageState<boolean>(join([LOCAL_STORAGE_PREFIX_APO, 'show-cookie-notice'], ''), {
    defaultValue: true,
  });
}
