import { Trans } from '@rhim/i18n';
import { Heading, Hyperlink, Legal } from '@rhim/react';
import { headerTermsOfUse } from '@rhim/test-ids';
import { FC } from 'react';

const LegalTabTermsOfUse: FC<React.PropsWithChildren> = () => {
  return (
    <Trans i18nKey="static:legal.topics.terms-of-use.content">
      <Heading data-test-id={headerTermsOfUse} level="h2">
        Digital Service Terms of Usage
      </Heading>
      <Legal.Paragraph>
        These Terms of Usage (<Legal.Strong>&quot;Terms&quot;</Legal.Strong>), apply to your usage of this homepage plus all sub-websites (
        <Legal.Strong>&quot;Website&quot;</Legal.Strong> or <Legal.Strong>&quot;Digital Service&quot;</Legal.Strong>), e.g. access, browsing, storage, uploads,
        communications or printing. By using this Website or registering on the Digital Service, you expressly accept and adhere to the Terms. In case you
        disagree with the Terms, you will not be entitled to use the Website respectively will not gain access to its entire functionality.
      </Legal.Paragraph>
      <Legal.Paragraph>
        When entering, browsing and using the Digital Service, you engage with us regarding services we provide through or in connection with Automated Process
        Optimization (<Legal.Strong>&quot;APO&quot;</Legal.Strong>) and its functionalities which is a system to evaluate and forecast (i) the life cycle and
        duration of refractories materials used, (ii) the impact of different factors on the life cycle of such refractories materials and (iii) anomalies in
        the data screened (<Legal.Strong>&quot;Purpose&quot;</Legal.Strong>). In connection with the Purpose, you can, by self-upload, disclose to us
        information and/or data regarding your production, related to your business and business processes in order for us to provide you with the services
        agreed upon under separate agreements, that we will treat as confidential, pursuant to the Terms.
      </Legal.Paragraph>
      <Legal.Paragraph>
        We aim to provide uninterrupted access to the Website, but cannot guarantee its availability, as access may be interrupted at any time, in particular
        due to maintenance and updating of the Website, but also for reasons beyond our control (e.g. network or provider problems). It is within our sole
        discretion to stop the provision of the Website at all times and without stating any reason.
      </Legal.Paragraph>
      <Legal.Paragraph>
        We are permitted to revise the Terms at any time as we see fit without further notification. With the usage of the Website you confirm not to be a minor
        (under 18 years). This Website is provided to you by RHI Magnesita GmbH, FN 442121 d, Kranichberggasse 6, 1120 Vienna, Austria (&quot;we&quot; or
        &quot;us&quot;). In case of questions thereto, please contact{' '}
        <Hyperlink href="mailto:dataprivacy@RHIMagnesita.com" text="dataprivacy@RHIMagnesita.com" />.
      </Legal.Paragraph>
      <Heading level="h3">IP and Ownership Rights</Heading>
      <Legal.Paragraph>
        Other than the content, information and data that you and/or your company own and you decide, at your sole discretion, to disclose to us by uploading it
        for the Purpose, we, our subsidiaries and/or affiliated legal entities, and/or our licensors (if any) own all rights to the intellectual property and
        material contained in this Website (e.g. company logos, photos, images, illustrations, texts, video clips, design of the Website) and all such rights
        are reserved. You are granted a limited right, subject to the restrictions provided in the Terms, to access and view the material contained on this
        Website. No further right shall result from your use of the website regarding any intellectual property not already owned by you.
      </Legal.Paragraph>
      <Legal.Paragraph>
        If not specified otherwise, the ownership rights and copyright for information and documents provided on or through the Website lie exclusively with us.
        Use of the Website is only permitted for the Purpose and any usage beyond this, in particular incorporation, storage or reproduction of the content in
        other websites, in other electronic, digital or further media or systems, as well as copying, processing or commercial use require explicit prior
        permission from us in writing.
      </Legal.Paragraph>
      <Legal.Paragraph>
        All trademarks, logos, images etc. used and shown on the Website and the associated brand, usage and copyright rights are owned exclusively by us or the
        indicated rights owner. Use of these, regardless of the presence of a corresponding indication such as ® or ™, is explicitly prohibited.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Downloading and/or use of the image data available on the Website is fundamentally prohibited. The use of image data is permissible exclusively for
        media purposes, and only image data that has been provided by us in the download area of the Website may be used for this purpose.
      </Legal.Paragraph>
      <Heading level="h3">Restrictions</Heading>
      <Legal.Paragraph>
        The Website may not be used as a medium for racist, violence-glorifying, xenophobic, obscene, malicious or other illegal purposes. Please note that you
        are not entitled to and shall refrain at all times from the following:
      </Legal.Paragraph>
      <Legal.List>
        <Legal.Bullet>
          uploading or posting of any content that is illegal under any jurisdiction, in particular to violate the copyrights of software, photos, images, text,
          video and trademark rights
        </Legal.Bullet>
        <Legal.Bullet>
          carrying out any activities that could be considered offensive, defamatory, unauthorized, malicious, invasive of privacy, glorifying violence or
          racist or abusive or infringing on any rights of third parties;
        </Legal.Bullet>
        <Legal.Bullet>
          reproducing or publishing and/or using any content, information, intellectual property, data, component or similar (
          <Legal.Strong>&quot;Website Material&quot;</Legal.Strong>), in particular in any media for any purpose (e.g. business purpose);
        </Legal.Bullet>
        <Legal.Bullet>selling, sublicensing, disclosing and/or otherwise commercializing Website Material;</Legal.Bullet>
        <Legal.Bullet>public disclosure of Website material;</Legal.Bullet>
        <Legal.Bullet>
          using the Website in any way that is, or may be, damaging to this Website (e.g. use the site for political, promotional or religious purposes or to
          publish any promotional or advertising content);
        </Legal.Bullet>
        <Legal.Bullet>using this Website in any way that impacts user access to the Website, whether your own or of third parties;</Legal.Bullet>
        <Legal.Bullet>
          using the Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or
          business entity;
        </Legal.Bullet>
        <Legal.Bullet>
          engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website;
        </Legal.Bullet>
        <Legal.Bullet>using this Website to engage in any advertising or marketing; and</Legal.Bullet>
        <Legal.Bullet>disclosing your access data to third persons;</Legal.Bullet>
        <Legal.Bullet>
          unauthorized accessing or interruption, damage, overloading or disruption of any part of the Website, the server on which the Website is stored or the
          servers, computers or databases connected to the Website; or attack the Website via a denial of service or distributed denial of service attack; or
          any other attempt to impair the functionality of the Website;
        </Legal.Bullet>
        <Legal.Bullet>introducing viruses, Trojan horses, worms, logic bombs or other content that may be malicious or harmful;</Legal.Bullet>
        <Legal.Bullet>
          usage of &quot;deep-link&quot;, &quot;page-scrape&quot;, &quot;robot&quot; or &quot;spider&quot; techniques or any other automatic device, program,
          algorithm or methodology, or any equivalent manual process, to access, obtain, copy or monitor any portion of the Website, or the usage of these
          methods to reproduce or circumvent the navigational structure or presentation of the Website in order to obtain any content, documents or information
          not purposefully made available on the Website
        </Legal.Bullet>
      </Legal.List>
      <Legal.Paragraph>
        Certain areas of this Website are restricted from access by you and we may further restrict access to any areas of this Website, at any time, in our
        sole and absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality of such
        information.
      </Legal.Paragraph>
      <Heading level="h3">Usage of Website and Digital Service</Heading>
      <Legal.Paragraph>
        The use of the Website requires you to have the necessary hardware and software. Please note that that the technical structure of the Internet does not
        allow for absolute security, availability and integrity in data transmission. Accordingly, we accept no responsibility for any errors, omissions,
        deletions, delays, malfunctions (e.g. due to viruses) in the communication channels, hardware and software not under our control or in the event of
        unauthorized use or possible deterioration of the content published on the Website.
      </Legal.Paragraph>
      <Legal.Paragraph>
        If you open an account with and/or for the Digital Service, you will receive an account login and an access password. It is your sole responsibility to
        (i) ensure the confidentiality of the account login and password and protect it from access by third parties, (ii) update and verify your password
        regularly and (iii) to notify us immediately if there has been any unauthorized use of your account or any breach of security. You can change your
        password at any time.
      </Legal.Paragraph>
      <Legal.Paragraph>To access the Digital Service, you must be of legal age and have a valid business e-mail address.</Legal.Paragraph>
      <Legal.Paragraph>
        When filling in forms, the information in the fields marked with an asterisk is mandatory. Incomplete or incorrect information will not be registered.
        You warrant that the information you provide is complete and correct.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You agree that the data entered by you may be stored, processed and transmitted by us in accordance with the legal requirements. Please inform us if
        your details change. Some areas of the website can only be accessed with a personal user ID and password. For security reasons, the number of login
        attempts is limited.
      </Legal.Paragraph>
      <Legal.Paragraph>
        <Legal.Strong>&quot;Your Content&quot;</Legal.Strong> means any data, graph, spread sheet, audio, video, text, images or any other material or format of
        archive you choose to upload and/or display on the Website related to the Purpose. By uploading and/or displaying Your Content, you grant us free and
        unlimited permission to access and process the information.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You warrant that you have full ownership and IP-rights to Your Content which does not infringe any third party’s rights and that you will transfer,
        assign or similar all necessary rights in Your Content to us pursuant to the Purpose and/or any other terms and separate agreements concluded with you.
        You acknowledge that integrity and security of the data and information comprised and uploaded as Your Content is within your sole responsibility and
        liability and that it is legally permissibly transferred, reliable, correct, accurate, complete and not manipulated (entire raw data). In particular you
        assure that no trade or business secrets are transferred or revealed. You are responsible and liable for Your Content until finally uploaded into the
        Digital Service (in particular responsible for your authorized users, loss, damage or manipulation). We reserve the right to remove any of Your Content
        at any time and for any reason, without further notice.
      </Legal.Paragraph>
      <Legal.Paragraph>
        We shall be entitled to process, analyze, diagnose, assemble, combine, decompile, use, calculate algorithms, transfer, and otherwise operate Your
        Content in order to create output for all purposes without restrictions, in particular for providing, compiling and calculating updates, reports,
        customer applications, or similar, also with relevant data from other customers or parties and can use it to provide and deliver to other customers.
      </Legal.Paragraph>
      <Heading level="h3">Confidentiality</Heading>
      <Legal.Paragraph>
        We will keep Your Content as strictly confidential and will not disclose any of Your Content to any third parties except to our group companies and/or
        consultants and advisors who have a need to know of such information in connection with the Purpose, unless agreed upon otherwise in separate agreements
        concluded.
      </Legal.Paragraph>
      <Heading level="h3">Warranties and Liabilities</Heading>
      <Legal.Paragraph>
        We apply the required care in business operations to ensure that the information on the Website is correct and current. This Website and its content is
        provided &quot;as is&quot; and &quot;as available&quot; and we make no express or implied representations or warranties, of any kind related to this
        Website or the materials, services or products contained on this Website, in particular not to any availability, completeness, reliability, safety,
        correctness or up-to-dateness or that the Website or the server that makes it available are free of viruses or other harmful components including but
        not limited to any warranties of merchantability, non-infringement of copyright and fitness for a particular purpose.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Additionally, nothing contained on this Website shall be construed as providing consult or advice to you unless otherwise agreed upon. We also reserve
        the right to carry out changes or additions to the provided information without prior notice.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You understand that we cannot guarantee that files downloaded from the Internet or from the Site will be free of viruses or other destructive code, and
        you are responsible for implementing appropriate procedures and checks to meet your requirements for anti-virus protection, accuracy of data input and
        output, and a means of recovering lost data independent of our Site. We shall not be liable for any loss or damage resulting from a distributed
        denial-of-service-attack, viruses or other technologically harmful content that infect your computer equipment, programs, data or other property on
        account of your use of the Website or any services obtained through the use of the Website or download from the Website or any Website linked to it.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Your use of the Website, its content and any products, services or downloads you obtain through the Website is at your sole risk, we do not guarantee
        that defects will be corrected, that the Website or the server that makes it available are free of viruses or other harmful components or that the
        Website or any services and products obtained through (directly or indirectly) it will meet your requirements or expectations.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Hyperlinks contained on the Website, e.g. to facilitate navigation on the Internet, may lead you to websites published by third parties, the contents of
        which we do not control. In such cases you acknowledge that we undertake no liability as to their content, security measures or similar as this is
        outside our area of responsibility.
      </Legal.Paragraph>
      <Legal.Paragraph>
        In no event shall we, not any of our group companies, nor any of our/their directors or employees, be liable for any claim, loss or damage arising out
        of or in any way connected with your use of this Website and/or its unavailability, whether such liability is under contract, tort or otherwise, and
        neither we nor any of our group companies, nor our directors or employees shall be liable for any indirect, consequential or special liability arising
        out of or in any way related to your use of this Website, including, but not limited to, any losses and damages.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You hereby indemnify us and our group companies (if affected) from and against any and all liabilities, costs, demands, causes of action, damages and
        expenses (including attorney’s fees) arising out of or in any way related to your breach of any of the provisions of the Terms.
      </Legal.Paragraph>
      <Legal.Paragraph>
        The products and services advertised or presented on the Website do not constitute an offer or a solicitation of an offer to buy. It is a general
        presentation of the range of products and services which we sell or provide.
      </Legal.Paragraph>
      <Legal.Paragraph>
        The advice or results of the analysis tool provided in the Digital Service is a mere simulation and does not replace an assessment of your local
        business processes. We cannot therefore guarantee that the advice and results will meet your needs.
      </Legal.Paragraph>
      <Legal.Paragraph>
        For this reason, the use of the hyperlinks and the use of the websites of third parties is outside our area of responsibility.
      </Legal.Paragraph>
      <Heading level="h3">General</Heading>
      <Legal.Paragraph>
        None of the information contained on the Website or in associated documents and files is or is intended to represent a solicitation to purchase or trade
        with you shares of RHI Magnesita N.V.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Should individual provisions of the Terms be or become illegal, invalid, or unenforceable, in whole or in part, under any applicable enactment or rule
        of law, such illegality, invalidity or unenforceability shall not affect the validity of the remaining provisions. This provision shall be replaced by a
        term that closest reflects the intended purpose of the Terms.
      </Legal.Paragraph>
      <Legal.Paragraph>
        We are permitted to assign, transfer, and subcontract our rights and/or obligations under the Terms without any further notification.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You shall observe the RHIM Code of Conduct available at{' '}
        <Hyperlink rel="nofollow noopener" href="https://www.rhimagnesita.com" text="www.rhimagnesita.com" />
      </Legal.Paragraph>
      <Legal.Paragraph>
        The Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between us in relation to your use of
        the Website, and it shall prevail all other agreements and understandings with respect to the same.
      </Legal.Paragraph>
      <Legal.Paragraph>
        The Terms are governed by and construed in accordance with the Austrian law, with the exception of its conflict of law provisions. The place of
        jurisdiction is the responsible court in Vienna, Austria.
      </Legal.Paragraph>
    </Trans>
  );
};

export default LegalTabTermsOfUse;
