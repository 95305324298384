import { settings } from '@rhim/design';
import { FullscreenExitIcon, FullscreenIcon } from '@rhim/icons/24';
import {
  RHIMMeasurementServiceContractsDataLiningCondition,
  RHIMMeasurementServiceContractsDataLiningMaintenanceType,
  RHIMMeasurementServiceContractsDataMeasurementFileType,
  RHIMMeasurementServiceContractsDataVesselLining,
  RHIMMeasurementServiceV1ModelsMeasurementFileDto,
} from '@rhim/rest/measurementService';
import { isDefined } from '@rhim/utils';
import { TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const getPreRenderedMeasurementFileId = (referenceMeasurementFiles: RHIMMeasurementServiceV1ModelsMeasurementFileDto[]): string => {
  const prerenderedPointCloudFileType = RHIMMeasurementServiceContractsDataMeasurementFileType.Pcprerendered;
  const fileWithPrerenderedPointCloud = referenceMeasurementFiles.find((file) => file.measurementFileType === prerenderedPointCloudFileType);
  if (!isDefined(fileWithPrerenderedPointCloud)) {
    throw new Error(`Reference measument has no file with : '${prerenderedPointCloudFileType}' file type.`);
  }
  return fileWithPrerenderedPointCloud.id;
};

export const getVesselLiningLabel = (t: TFunction<'ingress'>, vesselLining: RHIMMeasurementServiceContractsDataVesselLining | 'All') => {
  return t(`qckLightPointCloudUploadAndRegistration.fieldVesselLiningOptions.${vesselLining}`, { ns: 'ingress' });
};

export const getLiningTemperatureLabel = (t: TFunction<'ingress'>, liningTemperature: 'Hot' | 'Cold') => {
  return t(`qckLightPointCloudUploadAndRegistration.fieldLiningTemperatureOptions.${liningTemperature}`, { ns: 'ingress' });
};

export const useVesselLiningAndConditionLabel = (vesselLining: string | undefined, liningCondition: string | undefined): string | undefined => {
  const { t } = useTranslation('ingress');

  if (!isDefined(vesselLining)) {
    return undefined;
  }

  const liningLabel = getVesselLiningLabel(t, vesselLining as RHIMMeasurementServiceContractsDataVesselLining);
  const conditionLabel = isDefined(liningCondition)
    ? getLiningConditionLabel(t, liningCondition as RHIMMeasurementServiceContractsDataLiningCondition)
    : undefined;

  return isDefined(conditionLabel) ? `${liningLabel} - ${conditionLabel}` : liningLabel;
};

export function useVesselLiningLabel() {
  const { t } = useTranslation('ingress');
  return (vesselLining: RHIMMeasurementServiceContractsDataVesselLining | 'All') => getVesselLiningLabel(t, vesselLining);
}

export const getLiningConditionLabel = (t: TFunction<'ingress'>, liningCondition: RHIMMeasurementServiceContractsDataLiningCondition) => {
  return t(`qckLightPointCloudUploadAndRegistration.fieldLiningCondition.${liningCondition}`, { ns: 'ingress' });
};

export const getMaintenanceTypeLabel = (t: TFunction<'ingress'>, maintenanceType: RHIMMeasurementServiceContractsDataLiningMaintenanceType) => {
  return t(`qckLightPointCloudUploadAndRegistration.fieldMaintenanceType.${maintenanceType}`, { ns: 'ingress' });
};

export const STitle = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Medium};
  line-height: 20px;
  margin-bottom: ${settings.Spacing.Spacing_50};
`;

export const STitleVesselState = styled(STitle)`
  margin-top: ${settings.Spacing.Spacing_200};
`;

export const SMetadataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${settings.Spacing.Spacing_300};
  row-gap: ${settings.Spacing.Spacing_200};
`;

export const SHeading = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Large};
`;

export const SInstructions = styled.p`
  margin-top: ${settings.Spacing.Spacing_400};
`;

export const BORDER_COLOR = settings.colors.Primary.Grey_3;
export const FULL_SCREEN_ICON = <FullscreenIcon />;
export const FULL_SCREEN_EXIT_ICON = <FullscreenExitIcon />;
