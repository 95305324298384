import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import React from 'react';
import styled from 'styled-components';

const IMAGE_VOLUME_DISPLAY_ASSETS_PATH = 'assets/tempVolumeDisplay.png';

interface Props {
  volumeDepth: number;
}
const Section3DView: React.FunctionComponent<Props> = ({ volumeDepth }) => {
  const { t } = i18nReact.useTranslation(['volume']);

  return (
    <SWrapper>
      <SImage src={IMAGE_VOLUME_DISPLAY_ASSETS_PATH} />
      <SLabel>{`${t('volume:volumeLimitHeight')}: ${volumeDepth}m`}</SLabel>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  margin-left: ${settings.Spacing.Spacing_600};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: ${settings.Spacing.Spacing_50};
  padding: ${settings.Spacing.Spacing_400};
  background-color: ${settings.colors.Primary.Grey_2};
`;

const SImage = styled.img`
  width: 300px;
`;

const SLabel = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_8};
`;

Section3DView.whyDidYouRender = true;
export default React.memo(Section3DView);
