import { VesselType } from '@rhim/rest';

import { Feature } from '../../settings';

/**
 * Matrix specifying which feature supports which vessel types
 */
export const vesselsPerFeature = {
  [Feature.ProcessData]: [VesselType.Bof, VesselType.Rh],
  [Feature.MeasurementData]: [VesselType.Bof, VesselType.Rh],
  [Feature.PointCloudData]: [VesselType.Eaf, VesselType.Ladle, VesselType.Rhl],
  [Feature.Report]: [VesselType.Bof, VesselType.Rh],
  [Feature.MeasurementView]: [VesselType.Aod, VesselType.Bfr, VesselType.Bof, VesselType.Eaf, VesselType.Ladle, VesselType.Rhl, VesselType.Srf, VesselType.Cou],
  [Feature.ConnectedMachines]: [VesselType.Eaf],
  [Feature.VolumeCalculations]: [VesselType.Eaf],
  [Feature.Display]: [VesselType.Bof, VesselType.Eaf, VesselType.Ladle],
  [Feature.RhReport]: [VesselType.Rh],
  [Feature.AroReport]: [VesselType.Crk],
  [Feature.FleetOverview]: [VesselType.Ladle],
  [Feature.ParameterComparison]: [VesselType.Bof, VesselType.Rh],
};

export function getFeaturesForVesselType(vesselType: VesselType): Feature[] {
  return Object.entries(vesselsPerFeature)
    .filter(([, vesselTypes]) => vesselTypes.includes(vesselType))
    .map(([feature]) => feature as Feature);
}
