/* tslint:disable */
/* eslint-disable */
/**
 * Measurement Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface RHIMAPOCoreWebProblemDetailsApoProblemDetails
 */
export interface RHIMAPOCoreWebProblemDetailsApoProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCoreWebProblemDetailsApoProblemDetails
     */
    'errorCode'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof RHIMAPOCoreWebProblemDetailsApoProblemDetails
     */
    'data'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCoreWebProblemDetailsApoProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCoreWebProblemDetailsApoProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOCoreWebProblemDetailsApoProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCoreWebProblemDetailsApoProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOCoreWebProblemDetailsApoProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOWearManagementApiClientAbsoluteThresholdDto
 */
export interface RHIMAPOWearManagementApiClientAbsoluteThresholdDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOWearManagementApiClientAbsoluteThresholdDto
     */
    'threshold': number;
    /**
     * 
     * @type {RHIMContractsMaintenanceTaskSeverity}
     * @memberof RHIMAPOWearManagementApiClientAbsoluteThresholdDto
     */
    'severity': RHIMContractsMaintenanceTaskSeverity;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientAbsoluteThresholdDto
     */
    'maintenanceTask'?: string | null;
    /**
     * 
     * @type {RHIMContractsThresholdType}
     * @memberof RHIMAPOWearManagementApiClientAbsoluteThresholdDto
     */
    'thresholdType': RHIMContractsThresholdType;
}


/**
 * 
 * @export
 * @interface RHIMAPOWearManagementApiClientCircleDto
 */
export interface RHIMAPOWearManagementApiClientCircleDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOWearManagementApiClientCircleDto
     */
    'circleRadius': number;
    /**
     * 
     * @type {RHIMContractsRegionPoint}
     * @memberof RHIMAPOWearManagementApiClientCircleDto
     */
    'midPoint': RHIMContractsRegionPoint;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOWearManagementApiClientColorScaleType = {
    Rbl: 'RBL',
    Wear: 'Wear'
} as const;

export type RHIMAPOWearManagementApiClientColorScaleType = typeof RHIMAPOWearManagementApiClientColorScaleType[keyof typeof RHIMAPOWearManagementApiClientColorScaleType];


/**
 * 
 * @export
 * @interface RHIMAPOWearManagementApiClientColorscaleDto
 */
export interface RHIMAPOWearManagementApiClientColorscaleDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientColorscaleDto
     */
    'vesselType': string;
    /**
     * 
     * @type {RHIMAPOWearManagementApiClientColorScaleType}
     * @memberof RHIMAPOWearManagementApiClientColorscaleDto
     */
    'colorScaleType'?: RHIMAPOWearManagementApiClientColorScaleType | null;
    /**
     * 
     * @type {Array<RHIMAPOWearManagementApiClientThresholdAndColorDto>}
     * @memberof RHIMAPOWearManagementApiClientColorscaleDto
     */
    'thresholdsAndColors': Array<RHIMAPOWearManagementApiClientThresholdAndColorDto>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMAPOWearManagementApiClientFunctionalProductType = {
    Taphole: 'Taphole',
    PurgePlug: 'PurgePlug',
    Trunnion: 'Trunnion',
    LooseTyre: 'LooseTyre',
    FixedTyre: 'FixedTyre',
    GirthGear: 'GirthGear'
} as const;

export type RHIMAPOWearManagementApiClientFunctionalProductType = typeof RHIMAPOWearManagementApiClientFunctionalProductType[keyof typeof RHIMAPOWearManagementApiClientFunctionalProductType];


/**
 * 
 * @export
 * @interface RHIMAPOWearManagementApiClientOrientationLabelDto
 */
export interface RHIMAPOWearManagementApiClientOrientationLabelDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOWearManagementApiClientOrientationLabelDto
     */
    'angle': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientOrientationLabelDto
     */
    'displayText'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMAPOWearManagementApiClientRegionDto
 */
export interface RHIMAPOWearManagementApiClientRegionDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     */
    'vesselId': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     * @deprecated
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     */
    'shortName'?: string | null;
    /**
     * 
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * 
     * @type {Array<RHIMContractsRegionTag>}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     */
    'tags'?: Array<RHIMContractsRegionTag> | null;
    /**
     * 
     * @type {RHIMContractsCoordinateSystem2D}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     */
    'coordinateSystem': RHIMContractsCoordinateSystem2D;
    /**
     * 
     * @type {Array<RHIMContractsRegionPoint>}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     */
    'area'?: Array<RHIMContractsRegionPoint> | null;
    /**
     * 
     * @type {Array<RHIMAPOWearManagementApiClientThresholdWearCurveDto>}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     */
    'thresholdWearCurves'?: Array<RHIMAPOWearManagementApiClientThresholdWearCurveDto> | null;
    /**
     * 
     * @type {Array<RHIMAPOWearManagementApiClientAbsoluteThresholdDto>}
     * @memberof RHIMAPOWearManagementApiClientRegionDto
     */
    'absoluteThresholds'?: Array<RHIMAPOWearManagementApiClientAbsoluteThresholdDto> | null;
}


/**
 * 
 * @export
 * @interface RHIMAPOWearManagementApiClientThresholdAndColorDto
 */
export interface RHIMAPOWearManagementApiClientThresholdAndColorDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOWearManagementApiClientThresholdAndColorDto
     */
    'threshold': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientThresholdAndColorDto
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface RHIMAPOWearManagementApiClientThresholdWearCurveDto
 */
export interface RHIMAPOWearManagementApiClientThresholdWearCurveDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOWearManagementApiClientThresholdWearCurveDto
     */
    'relativeInitialLiningThickness': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOWearManagementApiClientThresholdWearCurveDto
     */
    'relativeLiningThicknessAtExpectedLifetime': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOWearManagementApiClientThresholdWearCurveDto
     */
    'curveBendingFactor': number;
    /**
     * 
     * @type {RHIMContractsMaintenanceTaskSeverity}
     * @memberof RHIMAPOWearManagementApiClientThresholdWearCurveDto
     */
    'severity': RHIMContractsMaintenanceTaskSeverity;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientThresholdWearCurveDto
     */
    'maintenanceTask'?: string | null;
    /**
     * 
     * @type {RHIMContractsThresholdType}
     * @memberof RHIMAPOWearManagementApiClientThresholdWearCurveDto
     */
    'wearCurveType': RHIMContractsThresholdType;
}


/**
 * 
 * @export
 * @interface RHIMAPOWearManagementApiClientVesselFunctionalProductDto
 */
export interface RHIMAPOWearManagementApiClientVesselFunctionalProductDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientVesselFunctionalProductDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientVesselFunctionalProductDto
     */
    'vesselId': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMAPOWearManagementApiClientVesselFunctionalProductDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {RHIMAPOWearManagementApiClientFunctionalProductType}
     * @memberof RHIMAPOWearManagementApiClientVesselFunctionalProductDto
     */
    'type': RHIMAPOWearManagementApiClientFunctionalProductType;
    /**
     * 
     * @type {RHIMContractsRegionLocation}
     * @memberof RHIMAPOWearManagementApiClientVesselFunctionalProductDto
     */
    'regionLocation': RHIMContractsRegionLocation;
    /**
     * 
     * @type {RHIMAPOWearManagementApiClientCircleDto}
     * @memberof RHIMAPOWearManagementApiClientVesselFunctionalProductDto
     */
    'area': RHIMAPOWearManagementApiClientCircleDto;
    /**
     * 
     * @type {number}
     * @memberof RHIMAPOWearManagementApiClientVesselFunctionalProductDto
     */
    'tiltAngle': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsCoordinateSystem2D = {
    Xy: 'XY',
    RTheta: 'RTheta',
    ThetaZ: 'ThetaZ'
} as const;

export type RHIMContractsCoordinateSystem2D = typeof RHIMContractsCoordinateSystem2D[keyof typeof RHIMContractsCoordinateSystem2D];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsFileType = {
    PostMortem: 'PostMortem',
    ProcessData: 'ProcessData',
    ProcessMetrixFiltered: 'ProcessMetrixFiltered',
    ProcessMetrixRaw: 'ProcessMetrixRaw',
    ProcessMetrixVertex: 'ProcessMetrixVertex',
    ProcessMetrixSummary: 'ProcessMetrixSummary',
    ProcessMetrixZip: 'ProcessMetrixZip',
    Stl: 'STL',
    HarmonizedSummary: 'HarmonizedSummary',
    ProcessDataSplit: 'ProcessDataSplit',
    Pts: 'PTS',
    Clinker: 'Clinker',
    KilnFeed: 'KilnFeed',
    HotMeal: 'HotMeal',
    Lignite: 'Lignite',
    StopLogs: 'StopLogs',
    IrScan: 'IRScan',
    KilnShellCooling: 'KilnShellCooling',
    KilnProcessData: 'KilnProcessData',
    FerrotronSummary: 'FerrotronSummary'
} as const;

export type RHIMContractsFileType = typeof RHIMContractsFileType[keyof typeof RHIMContractsFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsMaintenanceTaskSeverity = {
    None: 'None',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
} as const;

export type RHIMContractsMaintenanceTaskSeverity = typeof RHIMContractsMaintenanceTaskSeverity[keyof typeof RHIMContractsMaintenanceTaskSeverity];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsRegionLocation = {
    Bottom: 'Bottom',
    Barrel: 'Barrel',
    Cone: 'Cone',
    ThroatIn: 'ThroatIn',
    ThroatOut: 'ThroatOut'
} as const;

export type RHIMContractsRegionLocation = typeof RHIMContractsRegionLocation[keyof typeof RHIMContractsRegionLocation];


/**
 * 
 * @export
 * @interface RHIMContractsRegionPoint
 */
export interface RHIMContractsRegionPoint {
    /**
     * 
     * @type {number}
     * @memberof RHIMContractsRegionPoint
     */
    'r': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMContractsRegionPoint
     */
    'theta': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMContractsRegionPoint
     */
    'z': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMContractsRegionPoint
     */
    'x': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMContractsRegionPoint
     */
    'y': number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsRegionTag = {
    None: 'None',
    OperatorView: 'OperatorView',
    MeasurementView: 'MeasurementView',
    Research: 'Research',
    WearPredictions: 'WearPredictions',
    TemperaturePredictions: 'TemperaturePredictions'
} as const;

export type RHIMContractsRegionTag = typeof RHIMContractsRegionTag[keyof typeof RHIMContractsRegionTag];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsThresholdType = {
    Target: 'Target',
    Lower: 'Lower',
    LowerPlotBoundary: 'LowerPlotBoundary',
    Critical: 'Critical',
    UpperPlotBoundary: 'UpperPlotBoundary',
    Upper: 'Upper'
} as const;

export type RHIMContractsThresholdType = typeof RHIMContractsThresholdType[keyof typeof RHIMContractsThresholdType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsVesselType = {
    Bof: 'BOF',
    Rh: 'RH',
    Eaf: 'EAF',
    Ladle: 'Ladle',
    Crk: 'CRK',
    Rhl: 'RHL',
    Aod: 'AOD',
    Bfr: 'BFR',
    Srf: 'SRF',
    Cou: 'COU'
} as const;

export type RHIMContractsVesselType = typeof RHIMContractsVesselType[keyof typeof RHIMContractsVesselType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataLiningCondition = {
    Initial: 'Initial',
    NormalUsed: 'NormalUsed',
    AfterRepair: 'AfterRepair'
} as const;

export type RHIMMeasurementServiceContractsDataLiningCondition = typeof RHIMMeasurementServiceContractsDataLiningCondition[keyof typeof RHIMMeasurementServiceContractsDataLiningCondition];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataLiningMaintenanceType = {
    BricksChanged: 'BricksChanged',
    Gunning: 'Gunning',
    Fettling: 'Fettling',
    SlagSplashing: 'SlagSplashing'
} as const;

export type RHIMMeasurementServiceContractsDataLiningMaintenanceType = typeof RHIMMeasurementServiceContractsDataLiningMaintenanceType[keyof typeof RHIMMeasurementServiceContractsDataLiningMaintenanceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataMeasurementFileType = {
    _3d: '3d',
    Stl: 'stl',
    Pcraw: 'pcraw',
    Pcaligned: 'pcaligned',
    Pcprerendered: 'pcprerendered',
    Pcreduced: 'pcreduced',
    Rbl: 'rbl',
    Wear: 'wear',
    Pctrans: 'pctrans',
    Pcfiltered: 'pcfiltered',
    Hrrbl: 'hrrbl',
    Hrwear: 'hrwear',
    Wallplot: 'wallplot',
    Bottomplot: 'bottomplot',
    Meta: 'meta',
    Statistics: 'statistics',
    Sectioncuts: 'sectioncuts',
    Distancesplot: 'distancesplot',
    Video: 'video',
    Image: 'image',
    Segmentation: 'segmentation',
    Hrsegmentation: 'hrsegmentation',
    Report: 'report',
    Lcsvertex: 'lcsvertex',
    Volumes: 'volumes',
    Watertightmesh: 'watertightmesh',
    Regionvalues: 'regionvalues',
    Regions: 'regions'
} as const;

export type RHIMMeasurementServiceContractsDataMeasurementFileType = typeof RHIMMeasurementServiceContractsDataMeasurementFileType[keyof typeof RHIMMeasurementServiceContractsDataMeasurementFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataMeasurementState = {
    Ingressing: 'Ingressing',
    Processing: 'Processing',
    Complete: 'Complete',
    Error: 'Error'
} as const;

export type RHIMMeasurementServiceContractsDataMeasurementState = typeof RHIMMeasurementServiceContractsDataMeasurementState[keyof typeof RHIMMeasurementServiceContractsDataMeasurementState];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataMeasurementSystems = {
    LeicaV1: 'LeicaV1',
    Lcsv1: 'LCSV1',
    FaroV1: 'FaroV1',
    FerrotronV1: 'FerrotronV1',
    FerrotronV2: 'FerrotronV2',
    HyperionV1: 'HyperionV1'
} as const;

export type RHIMMeasurementServiceContractsDataMeasurementSystems = typeof RHIMMeasurementServiceContractsDataMeasurementSystems[keyof typeof RHIMMeasurementServiceContractsDataMeasurementSystems];


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceContractsDataPoint2D
 */
export interface RHIMMeasurementServiceContractsDataPoint2D {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceContractsDataPoint2D
     */
    'r': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceContractsDataPoint2D
     */
    'theta': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceContractsDataPoint2D
     */
    'z': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceContractsDataPoint2D
     */
    'x': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceContractsDataPoint2D
     */
    'y': number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataVesselLining = {
    Shell: 'Shell',
    ShellInsulation: 'ShellInsulation',
    PermanentLining: 'PermanentLining',
    WearLining: 'WearLining'
} as const;

export type RHIMMeasurementServiceContractsDataVesselLining = typeof RHIMMeasurementServiceContractsDataVesselLining[keyof typeof RHIMMeasurementServiceContractsDataVesselLining];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceDataMeasurementPlotUnits = {
    M: 'm',
    Mm: 'mm',
    Degree: 'degree',
    Radian: 'radian'
} as const;

export type RHIMMeasurementServiceDataMeasurementPlotUnits = typeof RHIMMeasurementServiceDataMeasurementPlotUnits[keyof typeof RHIMMeasurementServiceDataMeasurementPlotUnits];


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceDataSnorkelPosition
 */
export interface RHIMMeasurementServiceDataSnorkelPosition {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceDataSnorkelPosition
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceDataSnorkelPosition
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceDataSnorkelPosition
     */
    'r': number;
}
/**
 * The list of possible validation errors for measurements, mainly used by the frontend for translations.
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceDataValidationError = {
    MeasurementInProgress: 'MeasurementInProgress',
    CampaignHeatTimestampMismatch: 'CampaignHeatTimestampMismatch',
    InvalidHeat: 'InvalidHeat',
    WlExists: 'WlExists',
    InvalidReferenceMeasurement: 'InvalidReferenceMeasurement',
    PtsFileContentInvalid: 'PtsFileContentInvalid',
    FilePartIncomplete: 'FilePartIncomplete',
    MeasurementInUse: 'MeasurementInUse',
    MissingSettings: 'MissingSettings',
    RetryAfterCancel: 'RetryAfterCancel'
} as const;

export type RHIMMeasurementServiceDataValidationError = typeof RHIMMeasurementServiceDataValidationError[keyof typeof RHIMMeasurementServiceDataValidationError];


/**
 * The list of possible validation warnings for measurements, mainly used by the frontend for translations.
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceDataValidationWarning = {
    ExistingPlDelete: 'ExistingPlDelete',
    ExistingWlDelete: 'ExistingWlDelete',
    ExistingMeasurementDelete: 'ExistingMeasurementDelete',
    IncompleteMeasurementDelete: 'IncompleteMeasurementDelete',
    NoRblCalculation: 'NoRblCalculation',
    PossiblyInaccurateWear: 'PossiblyInaccurateWear',
    PlPreviousCampaign: 'PlPreviousCampaign'
} as const;

export type RHIMMeasurementServiceDataValidationWarning = typeof RHIMMeasurementServiceDataValidationWarning[keyof typeof RHIMMeasurementServiceDataValidationWarning];


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsBottomPlotCellDto
 */
export interface RHIMMeasurementServiceV1ModelsBottomPlotCellDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsBottomPlotCellDto
     */
    'angleStart': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsBottomPlotCellDto
     */
    'angleEnd': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsBottomPlotCellDto
     */
    'radiusStart': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsBottomPlotCellDto
     */
    'radiusEnd': number;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsPlotValuesDto}
     * @memberof RHIMMeasurementServiceV1ModelsBottomPlotCellDto
     */
    'rbl'?: RHIMMeasurementServiceV1ModelsPlotValuesDto | null;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsPlotValuesDto}
     * @memberof RHIMMeasurementServiceV1ModelsBottomPlotCellDto
     */
    'wear'?: RHIMMeasurementServiceV1ModelsPlotValuesDto | null;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsBottomPlotDto
 */
export interface RHIMMeasurementServiceV1ModelsBottomPlotDto {
    /**
     * 
     * @type {Array<RHIMMeasurementServiceV1ModelsBottomPlotCellDto>}
     * @memberof RHIMMeasurementServiceV1ModelsBottomPlotDto
     */
    'data': Array<RHIMMeasurementServiceV1ModelsBottomPlotCellDto>;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsBottomPlotMetadataDto}
     * @memberof RHIMMeasurementServiceV1ModelsBottomPlotDto
     */
    'metadata': RHIMMeasurementServiceV1ModelsBottomPlotMetadataDto;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsWallPlotDto}
     * @memberof RHIMMeasurementServiceV1ModelsBottomPlotDto
     */
    'wallPlotThroatIn'?: RHIMMeasurementServiceV1ModelsWallPlotDto | null;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsWallPlotDto}
     * @memberof RHIMMeasurementServiceV1ModelsBottomPlotDto
     */
    'wallPlotThroatOut'?: RHIMMeasurementServiceV1ModelsWallPlotDto | null;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsBottomPlotMetadataDto
 */
export interface RHIMMeasurementServiceV1ModelsBottomPlotMetadataDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsBottomPlotMetadataDto
     */
    'maxRadius': number;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto
 */
export interface RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto {
    /**
     * The list of correspondent source points.
     * @type {Array<Array<number>>}
     * @memberof RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto
     */
    'sourcePoints': Array<Array<number>>;
    /**
     * The list of correspondent target points.
     * @type {Array<Array<number>>}
     * @memberof RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto
     */
    'targetPoints': Array<Array<number>>;
    /**
     * The transformation matrix.
     * @type {Array<number>}
     * @memberof RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto
     */
    'transformationMatrix': Array<number>;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsCalculateTransformPointsResultDto
 */
export interface RHIMMeasurementServiceV1ModelsCalculateTransformPointsResultDto {
    /**
     * The calculated transformation matrix.
     * @type {Array<number>}
     * @memberof RHIMMeasurementServiceV1ModelsCalculateTransformPointsResultDto
     */
    'transformationMatrix': Array<number>;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsCampaignDto
 */
export interface RHIMMeasurementServiceV1ModelsCampaignDto {
    /**
     * The Id of a campaign. A value of 0 indicates that there are measurements that are not assigned to a campaign.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsCampaignDto
     */
    'id': number;
    /**
     * The relation to a Vessel.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsCampaignDto
     */
    'vesselId': string;
    /**
     * The text to be displayed for a campaign.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsCampaignDto
     */
    'displayText': string;
}
/**
 * Represents the data of a measurement.
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementDataDto
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementDataDto {
    /**
     * The Id of the measurement.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDataDto
     */
    'id': string;
    /**
     * The number of the campaign.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDataDto
     */
    'campaign': number;
    /**
     * The number of the heat.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDataDto
     */
    'heat': number;
    /**
     * The time at which the measurement was taken.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDataDto
     */
    'measurementTaken': string;
    /**
     * The lining of the vessel.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDataDto
     */
    'vesselLining': string;
    /**
     * True if the vessel was hot during the measurement, otherwise false.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDataDto
     */
    'isHotMeasurement': boolean;
    /**
     * The condition of the lining.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDataDto
     */
    'liningCondition': string;
    /**
     * If true this measurement is involved in registration and validation.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDataDto
     */
    'isReferable': boolean;
    /**
     * The type of the maintenance, this is optional.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDataDto
     */
    'liningMaintenanceType'?: string | null;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementState}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDataDto
     */
    'status': RHIMMeasurementServiceContractsDataMeasurementState;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementSystems}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDataDto
     */
    'measurementSystem'?: RHIMMeasurementServiceContractsDataMeasurementSystems | null;
}


/**
 * Represents the data of a measurement.
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementDto
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementDto {
    /**
     * The id of the vessel.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'vesselId': string;
    /**
     * Used to store any information to a measurement.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'notes'?: string | null;
    /**
     * The list of exiting files related to this measurement.
     * @type {Array<RHIMMeasurementServiceV1ModelsMeasurementFileDto>}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'measurementFiles': Array<RHIMMeasurementServiceV1ModelsMeasurementFileDto>;
    /**
     * The Id of the measurement.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'id': string;
    /**
     * The number of the campaign.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'campaign': number;
    /**
     * The number of the heat.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'heat': number;
    /**
     * The time at which the measurement was taken.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'measurementTaken': string;
    /**
     * The lining of the vessel.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'vesselLining': string;
    /**
     * True if the vessel was hot during the measurement, otherwise false.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'isHotMeasurement': boolean;
    /**
     * The condition of the lining.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'liningCondition': string;
    /**
     * If true this measurement is involved in registration and validation.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'isReferable': boolean;
    /**
     * The type of the maintenance, this is optional.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'liningMaintenanceType'?: string | null;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementState}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'status': RHIMMeasurementServiceContractsDataMeasurementState;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementSystems}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementDto
     */
    'measurementSystem'?: RHIMMeasurementServiceContractsDataMeasurementSystems | null;
}


/**
 * Represents the information for a measurement file.
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementFileDto
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementFileDto {
    /**
     * The identifier of the measurement file.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementFileDto
     */
    'id': string;
    /**
     * The type of the measurement file.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementFileDto
     */
    'measurementFileType': string;
    /**
     * The length of the content for this measurement file.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementFileDto
     */
    'size': number;
    /**
     * The time at which the file was created or modified.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementFileDto
     */
    'lastModified': string;
    /**
     * The type of the content.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementFileDto
     */
    'contentType': string;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementMetadataDto {
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementSystems}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'measurementSystem'?: RHIMMeasurementServiceContractsDataMeasurementSystems | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'vesselHeight': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'vesselLength': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'vesselWidth': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'vesselInnerDiameter': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'vesselOuterDiameter': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'bottomDepth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'topDepth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'bottomPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'bottomDistance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'volumeStartDepth'?: number | null;
    /**
     * 
     * @type {RHIMMeasurementServiceDataSnorkelPosition}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'snorkelIn'?: RHIMMeasurementServiceDataSnorkelPosition | null;
    /**
     * 
     * @type {RHIMMeasurementServiceDataSnorkelPosition}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'snorkelOut'?: RHIMMeasurementServiceDataSnorkelPosition | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'snorkelHeight': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'throatHeight': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'throatDistance': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'snorkelInOuterDiameter': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'snorkelInInnerDiameter': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'snorkelOutOuterDiameter': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'snorkelOutInnerDiameter': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'wallplotStepSizeDepth': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'wallplotStepSizeAngle': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'wallplotStepSizeHeight': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'wallplotStepSizeLength': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'snorkelWallplotStepSizeDepth': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'snorkelWallplotStepSizeAngle': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'bottomplotStepSizeRadius': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'bottomplotStepSizeAngle': number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'bottomplotAngleStepSizePerRadius': { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'sectionCutsStepSizeDepth': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'sectionCutsStepSizeAngle': number;
    /**
     * 
     * @type {RHIMMeasurementServiceDataMeasurementPlotUnits}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'statisticalUnits': RHIMMeasurementServiceDataMeasurementPlotUnits;
    /**
     * 
     * @type {RHIMMeasurementServiceDataMeasurementPlotUnits}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'depthUnits': RHIMMeasurementServiceDataMeasurementPlotUnits;
    /**
     * 
     * @type {RHIMMeasurementServiceDataMeasurementPlotUnits}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'angleUnits': RHIMMeasurementServiceDataMeasurementPlotUnits;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'wallDistanceMin': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'wallDistanceMax': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'reducedPointCloudFactor': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'unitsFactor': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'minimumCorrespondentPoints': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'maximumCorrespondentPoints': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementMetadataDto
     */
    'correspondentPointClusterRadius': number;
}


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementStatusDto
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementStatusDto {
    /**
     * The Id of the measurement status
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementStatusDto
     */
    'id': string;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementState}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementStatusDto
     */
    'state': RHIMMeasurementServiceContractsDataMeasurementState;
    /**
     * A reason why this state was set. Can be empty and is mostly used for an error state.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementStatusDto
     */
    'reason'?: string | null;
    /**
     * The utc timestamp when the status was created.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementStatusDto
     */
    'createdOn': string;
}


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementValidationErrorDto
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementValidationErrorDto {
    /**
     * 
     * @type {RHIMMeasurementServiceDataValidationError}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementValidationErrorDto
     */
    'error': RHIMMeasurementServiceDataValidationError;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementValidationErrorDto
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementValidationErrorDto
     */
    'details'?: string | null;
}


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementValidationWarningDto
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementValidationWarningDto {
    /**
     * 
     * @type {RHIMMeasurementServiceDataValidationWarning}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementValidationWarningDto
     */
    'warning': RHIMMeasurementServiceDataValidationWarning;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementValidationWarningDto
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementValidationWarningDto
     */
    'details'?: string | null;
}


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementViewDataDto {
    /**
     * The list of minimum brick length\'s per region
     * @type {Array<RHIMOperatorDisplayServiceClientRegionMinimumBrickLengthDto>}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'minimumBrickLengthPerRegion': Array<RHIMOperatorDisplayServiceClientRegionMinimumBrickLengthDto>;
    /**
     * The list of regions
     * @type {Array<RHIMAPOWearManagementApiClientRegionDto>}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'regions': Array<RHIMAPOWearManagementApiClientRegionDto>;
    /**
     * The list of brick suppliers per region
     * @type {Array<RHIMMeasurementServiceV1ModelsRegionBrickSupplierDto>}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'brickSuppliersPerRegion': Array<RHIMMeasurementServiceV1ModelsRegionBrickSupplierDto>;
    /**
     * The minimum brick length value if available otherwise null
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'rblMinValue'?: number | null;
    /**
     * The maximum brick length value if available otherwise null
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'rblMaxValue'?: number | null;
    /**
     * The minimum wear value if available otherwise null
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'wearMinValue'?: number | null;
    /**
     * The maximum wear value if available otherwise null
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'wearMaxValue'?: number | null;
    /**
     * Indicates if a 3d (.glb) file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'hasView3d': boolean;
    /**
     * Indicates if a reduced point cloud file to view is available.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'hasViewPointCloud': boolean;
    /**
     * Indicates if an rbl file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'hasRbl': boolean;
    /**
     * Indicates if a wear file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'hasWear': boolean;
    /**
     * Indicates if a wall plot file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'hasWallPlot': boolean;
    /**
     * Indicates if a bottom plot file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'hasBottomPlot': boolean;
    /**
     * Indicates if a meta file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'hasMetadata': boolean;
    /**
     * Indicates if a section cuts file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'hasSectionCuts': boolean;
    /**
     * Indicates if a volumes file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'hasVolumes': boolean;
    /**
     * The id of the vessel.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'vesselId': string;
    /**
     * Used to store any information to a measurement.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'notes'?: string | null;
    /**
     * The list of exiting files related to this measurement.
     * @type {Array<RHIMMeasurementServiceV1ModelsMeasurementFileDto>}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'measurementFiles': Array<RHIMMeasurementServiceV1ModelsMeasurementFileDto>;
    /**
     * The Id of the measurement.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'id': string;
    /**
     * The number of the campaign.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'campaign': number;
    /**
     * The number of the heat.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'heat': number;
    /**
     * The time at which the measurement was taken.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'measurementTaken': string;
    /**
     * The lining of the vessel.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'vesselLining': string;
    /**
     * True if the vessel was hot during the measurement, otherwise false.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'isHotMeasurement': boolean;
    /**
     * The condition of the lining.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'liningCondition': string;
    /**
     * If true this measurement is involved in registration and validation.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'isReferable': boolean;
    /**
     * The type of the maintenance, this is optional.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'liningMaintenanceType'?: string | null;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementState}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'status': RHIMMeasurementServiceContractsDataMeasurementState;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementSystems}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDataDto
     */
    'measurementSystem'?: RHIMMeasurementServiceContractsDataMeasurementSystems | null;
}


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementViewDto
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementViewDto {
    /**
     * Indicates if a 3d (.glb) file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'hasView3d': boolean;
    /**
     * Indicates if a reduced point cloud file to view is available.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'hasViewPointCloud': boolean;
    /**
     * Indicates if an rbl file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'hasRbl': boolean;
    /**
     * Indicates if a wear file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'hasWear': boolean;
    /**
     * Indicates if a wall plot file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'hasWallPlot': boolean;
    /**
     * Indicates if a bottom plot file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'hasBottomPlot': boolean;
    /**
     * Indicates if a meta file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'hasMetadata': boolean;
    /**
     * Indicates if a section cuts file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'hasSectionCuts': boolean;
    /**
     * Indicates if a volumes file is available for this measurement.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'hasVolumes': boolean;
    /**
     * The id of the vessel.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'vesselId': string;
    /**
     * Used to store any information to a measurement.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'notes'?: string | null;
    /**
     * The list of exiting files related to this measurement.
     * @type {Array<RHIMMeasurementServiceV1ModelsMeasurementFileDto>}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'measurementFiles': Array<RHIMMeasurementServiceV1ModelsMeasurementFileDto>;
    /**
     * The Id of the measurement.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'id': string;
    /**
     * The number of the campaign.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'campaign': number;
    /**
     * The number of the heat.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'heat': number;
    /**
     * The time at which the measurement was taken.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'measurementTaken': string;
    /**
     * The lining of the vessel.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'vesselLining': string;
    /**
     * True if the vessel was hot during the measurement, otherwise false.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'isHotMeasurement': boolean;
    /**
     * The condition of the lining.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'liningCondition': string;
    /**
     * If true this measurement is involved in registration and validation.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'isReferable': boolean;
    /**
     * The type of the maintenance, this is optional.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'liningMaintenanceType'?: string | null;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementState}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'status': RHIMMeasurementServiceContractsDataMeasurementState;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementSystems}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewDto
     */
    'measurementSystem'?: RHIMMeasurementServiceContractsDataMeasurementSystems | null;
}


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementViewVesselDataDto
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementViewVesselDataDto {
    /**
     * 
     * @type {Array<RHIMAPOWearManagementApiClientVesselFunctionalProductDto>}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewVesselDataDto
     */
    'vesselFunctionalProducts': Array<RHIMAPOWearManagementApiClientVesselFunctionalProductDto>;
    /**
     * 
     * @type {RHIMAPOWearManagementApiClientColorscaleDto}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewVesselDataDto
     */
    'colorscaleRbl': RHIMAPOWearManagementApiClientColorscaleDto;
    /**
     * 
     * @type {RHIMAPOWearManagementApiClientColorscaleDto}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewVesselDataDto
     */
    'colorscaleWear': RHIMAPOWearManagementApiClientColorscaleDto;
    /**
     * 
     * @type {Array<RHIMAPOWearManagementApiClientRegionDto>}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewVesselDataDto
     */
    'regions': Array<RHIMAPOWearManagementApiClientRegionDto>;
    /**
     * 
     * @type {Array<RHIMAPOWearManagementApiClientOrientationLabelDto>}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementViewVesselDataDto
     */
    'orientationLabels': Array<RHIMAPOWearManagementApiClientOrientationLabelDto>;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDto
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDto {
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDto
     */
    'previousCampaignMeasurement'?: RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement | null;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDto
     */
    'permanentLiningMeasurement'?: RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement | null;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDto
     */
    'wearLiningInitialMeasurement'?: RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement | null;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDto
     */
    'wearLiningUsedMeasurement'?: RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDto
     */
    'wearLiningUsedCount': number;
}
/**
 * Represents the data of a measurement.
 * @export
 * @interface RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
 */
export interface RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement {
    /**
     * The Id of the measurement.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
     */
    'id': string;
    /**
     * The number of the campaign.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
     */
    'campaign': number;
    /**
     * The number of the heat.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
     */
    'heat': number;
    /**
     * The time at which the measurement was taken.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
     */
    'measurementTaken': string;
    /**
     * The lining of the vessel.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
     */
    'vesselLining': string;
    /**
     * True if the vessel was hot during the measurement, otherwise false.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
     */
    'isHotMeasurement': boolean;
    /**
     * The condition of the lining.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
     */
    'liningCondition': string;
    /**
     * If true this measurement is involved in registration and validation.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
     */
    'isReferable': boolean;
    /**
     * The type of the maintenance, this is optional.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
     */
    'liningMaintenanceType'?: string | null;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementState}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
     */
    'status': RHIMMeasurementServiceContractsDataMeasurementState;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementSystems}
     * @memberof RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDtoPreviousCampaignMeasurement
     */
    'measurementSystem'?: RHIMMeasurementServiceContractsDataMeasurementSystems | null;
}


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsPlotRegionValueDto
 */
export interface RHIMMeasurementServiceV1ModelsPlotRegionValueDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsPlotRegionValueDto
     */
    'regionName': string;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsPlotValuesDto}
     * @memberof RHIMMeasurementServiceV1ModelsPlotRegionValueDto
     */
    'rbl'?: RHIMMeasurementServiceV1ModelsPlotValuesDto | null;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsPlotValuesDto}
     * @memberof RHIMMeasurementServiceV1ModelsPlotRegionValueDto
     */
    'wear'?: RHIMMeasurementServiceV1ModelsPlotValuesDto | null;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataPoint2D}
     * @memberof RHIMMeasurementServiceV1ModelsPlotRegionValueDto
     */
    'rblMinPoint'?: RHIMMeasurementServiceContractsDataPoint2D | null;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataPoint2D}
     * @memberof RHIMMeasurementServiceV1ModelsPlotRegionValueDto
     */
    'rblMaxPoint'?: RHIMMeasurementServiceContractsDataPoint2D | null;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataPoint2D}
     * @memberof RHIMMeasurementServiceV1ModelsPlotRegionValueDto
     */
    'wearMinPoint'?: RHIMMeasurementServiceContractsDataPoint2D | null;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataPoint2D}
     * @memberof RHIMMeasurementServiceV1ModelsPlotRegionValueDto
     */
    'wearMaxPoint'?: RHIMMeasurementServiceContractsDataPoint2D | null;
    /**
     * 
     * @type {RHIMContractsCoordinateSystem2D}
     * @memberof RHIMMeasurementServiceV1ModelsPlotRegionValueDto
     */
    'coordinateSystem': RHIMContractsCoordinateSystem2D;
}


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsPlotValuesDto
 */
export interface RHIMMeasurementServiceV1ModelsPlotValuesDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPlotValuesDto
     */
    'min': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPlotValuesDto
     */
    'max': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPlotValuesDto
     */
    'med': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPlotValuesDto
     */
    'avg': number;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsPointCloudModificationsDto
 */
export interface RHIMMeasurementServiceV1ModelsPointCloudModificationsDto {
    /**
     * The Id of the uploaded point cloud (usually .pts) file. If the data center service is used, this Id corresponds to the BatchId.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDto
     */
    'pointCloudFileId': string;
    /**
     * Set this value to true if a point cloud registration is in progress, otherwise false for just meshing the point cloud file.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDto
     */
    'withRegistration': boolean;
    /**
     * The transformation matrix.
     * @type {Array<number>}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDto
     */
    'transformationMatrix': Array<number>;
    /**
     * The top height of a vessel.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDto
     */
    'topDepth'?: number | null;
    /**
     * The bottom position of a vessel.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDto
     */
    'bottomPosition'?: number | null;
    /**
     * The distance between top and bottom a vessel.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDto
     */
    'bottomDistance'?: number | null;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelIn}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDto
     */
    'snorkelIn'?: RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelIn | null;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelOut}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDto
     */
    'snorkelOut'?: RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelOut | null;
}
/**
 * The position of the \"in\" snorkel otherwise null.
 * @export
 * @interface RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelIn
 */
export interface RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelIn {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelIn
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelIn
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelIn
     */
    'r': number;
}
/**
 * The position of the \"out\" snorkel otherwise null.
 * @export
 * @interface RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelOut
 */
export interface RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelOut {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelOut
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelOut
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudModificationsDtoSnorkelOut
     */
    'r': number;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsPointCloudRegisterDto
 */
export interface RHIMMeasurementServiceV1ModelsPointCloudRegisterDto {
    /**
     * The Id of the uploaded point cloud (usually .pts) file. If the data center service is used, this Id corresponds to the BatchId.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudRegisterDto
     */
    'pointCloudFileId': string;
    /**
     * The Id of the referenced measurement.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudRegisterDto
     */
    'referenceMeasurementId': string;
    /**
     * The list of correspondent source points.
     * @type {Array<Array<number>>}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudRegisterDto
     */
    'sourcePoints': Array<Array<number>>;
    /**
     * The list of correspondent target points.
     * @type {Array<Array<number>>}
     * @memberof RHIMMeasurementServiceV1ModelsPointCloudRegisterDto
     */
    'targetPoints': Array<Array<number>>;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsRegionBrickSupplierDto
 */
export interface RHIMMeasurementServiceV1ModelsRegionBrickSupplierDto {
    /**
     * The Id of the Region
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsRegionBrickSupplierDto
     */
    'regionId': string;
    /**
     * The list of suppliers
     * @type {Array<string>}
     * @memberof RHIMMeasurementServiceV1ModelsRegionBrickSupplierDto
     */
    'brickSuppliers': Array<string>;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsSectionCutsDto
 */
export interface RHIMMeasurementServiceV1ModelsSectionCutsDto {
    /**
     * 
     * @type {{ [key: string]: Array<RHIMMeasurementServiceV1ModelsSectionCutsPointDto>; }}
     * @memberof RHIMMeasurementServiceV1ModelsSectionCutsDto
     */
    'data': { [key: string]: Array<RHIMMeasurementServiceV1ModelsSectionCutsPointDto>; };
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsSectionCutsMetadataDto}
     * @memberof RHIMMeasurementServiceV1ModelsSectionCutsDto
     */
    'metadata': RHIMMeasurementServiceV1ModelsSectionCutsMetadataDto;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsSectionCutsMetadataDto
 */
export interface RHIMMeasurementServiceV1ModelsSectionCutsMetadataDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsSectionCutsMetadataDto
     */
    'maxRadius': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsSectionCutsMetadataDto
     */
    'maxDepth': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsSectionCutsMetadataDto
     */
    'angleStep': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsSectionCutsMetadataDto
     */
    'depthStep': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsSectionCutsMetadataDto
     */
    'bottomDepth': number;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsSectionCutsPointDto
 */
export interface RHIMMeasurementServiceV1ModelsSectionCutsPointDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsSectionCutsPointDto
     */
    'radius'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsSectionCutsPointDto
     */
    'depth'?: number | null;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsPlotValuesDto}
     * @memberof RHIMMeasurementServiceV1ModelsSectionCutsPointDto
     */
    'rbl'?: RHIMMeasurementServiceV1ModelsPlotValuesDto | null;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsPlotValuesDto}
     * @memberof RHIMMeasurementServiceV1ModelsSectionCutsPointDto
     */
    'wear'?: RHIMMeasurementServiceV1ModelsPlotValuesDto | null;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsUploadFileChunkDto
 */
export interface RHIMMeasurementServiceV1ModelsUploadFileChunkDto {
    /**
     * The number of the current file part.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsUploadFileChunkDto
     */
    'chunkNumber': number;
    /**
     * The size of the current file part.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsUploadFileChunkDto
     */
    'chunkSize': number;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsUploadPtsFileDto
 */
export interface RHIMMeasurementServiceV1ModelsUploadPtsFileDto {
    /**
     * The original file name.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'fileName': string;
    /**
     * The size of the complete file in bytes.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'fileSize': number;
    /**
     * The size of the current file part.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'chunkSize': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'referenceMeasurementId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'campaign': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'heat': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'measurementTaken': string;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'viewOnly': boolean;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'vesselLining': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'liningCondition': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'liningMaintenanceType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'hotMeasurement': boolean;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsFileDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsUploadPtsResponseDto
 */
export interface RHIMMeasurementServiceV1ModelsUploadPtsResponseDto {
    /**
     * The Id of the point cloud file if validation and upload succeeded, otherwise null.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsResponseDto
     */
    'pointCloudFileId'?: string | null;
    /**
     * The Id of the measurement if validation and upload was successful, otherwise null
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsResponseDto
     */
    'measurementId'?: string | null;
    /**
     * The list of warnings.
     * @type {Array<RHIMMeasurementServiceV1ModelsMeasurementValidationWarningDto>}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsResponseDto
     */
    'warnings': Array<RHIMMeasurementServiceV1ModelsMeasurementValidationWarningDto>;
    /**
     * The list of Errors.
     * @type {Array<RHIMMeasurementServiceV1ModelsMeasurementValidationErrorDto>}
     * @memberof RHIMMeasurementServiceV1ModelsUploadPtsResponseDto
     */
    'errors': Array<RHIMMeasurementServiceV1ModelsMeasurementValidationErrorDto>;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsValidatePtsDto
 */
export interface RHIMMeasurementServiceV1ModelsValidatePtsDto {
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsValidatePtsDto
     */
    'usePermanentLiningPreviousCampaign': boolean;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsValidatePtsDto
     */
    'campaign': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsValidatePtsDto
     */
    'heat': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidatePtsDto
     */
    'measurementTaken': string;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsValidatePtsDto
     */
    'viewOnly': boolean;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidatePtsDto
     */
    'vesselLining': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidatePtsDto
     */
    'liningCondition': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidatePtsDto
     */
    'liningMaintenanceType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsValidatePtsDto
     */
    'hotMeasurement': boolean;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidatePtsDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsValidateResponseDto
 */
export interface RHIMMeasurementServiceV1ModelsValidateResponseDto {
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDto
     */
    'referenceMeasurement'?: RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement | null;
    /**
     * The list of warnings.
     * @type {Array<RHIMMeasurementServiceV1ModelsMeasurementValidationWarningDto>}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDto
     */
    'warnings': Array<RHIMMeasurementServiceV1ModelsMeasurementValidationWarningDto>;
    /**
     * The list of Errors.
     * @type {Array<RHIMMeasurementServiceV1ModelsMeasurementValidationErrorDto>}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDto
     */
    'errors': Array<RHIMMeasurementServiceV1ModelsMeasurementValidationErrorDto>;
}
/**
 * Represents the data of a measurement.
 * @export
 * @interface RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
 */
export interface RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement {
    /**
     * The id of the vessel.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'vesselId': string;
    /**
     * Used to store any information to a measurement.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'notes'?: string | null;
    /**
     * The list of exiting files related to this measurement.
     * @type {Array<RHIMMeasurementServiceV1ModelsMeasurementFileDto>}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'measurementFiles': Array<RHIMMeasurementServiceV1ModelsMeasurementFileDto>;
    /**
     * The Id of the measurement.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'id': string;
    /**
     * The number of the campaign.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'campaign': number;
    /**
     * The number of the heat.
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'heat': number;
    /**
     * The time at which the measurement was taken.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'measurementTaken': string;
    /**
     * The lining of the vessel.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'vesselLining': string;
    /**
     * True if the vessel was hot during the measurement, otherwise false.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'isHotMeasurement': boolean;
    /**
     * The condition of the lining.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'liningCondition': string;
    /**
     * If true this measurement is involved in registration and validation.
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'isReferable': boolean;
    /**
     * The type of the maintenance, this is optional.
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'liningMaintenanceType'?: string | null;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementState}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'status': RHIMMeasurementServiceContractsDataMeasurementState;
    /**
     * 
     * @type {RHIMMeasurementServiceContractsDataMeasurementSystems}
     * @memberof RHIMMeasurementServiceV1ModelsValidateResponseDtoReferenceMeasurement
     */
    'measurementSystem'?: RHIMMeasurementServiceContractsDataMeasurementSystems | null;
}


/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsVesselDto
 */
export interface RHIMMeasurementServiceV1ModelsVesselDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsVesselDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsVesselDto
     */
    'shortName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsVesselDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsVesselDto
     */
    'vesselType': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsVesselDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMMeasurementServiceV1ModelsVesselDto
     */
    'customerShortName': string;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsView3dSettingsDto
 */
export interface RHIMMeasurementServiceV1ModelsView3dSettingsDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'wallplotStepSizeDepth': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'wallplotStepSizeAngle': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'wallplotStepSizeHeight': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'wallplotStepSizeLength': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'snorkelWallplotStepSizeDepth': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'snorkelWallplotStepSizeAngle': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'bottomplotStepSizeRadius': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'bottomplotStepSizeAngle': number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'bottomplotAngleStepSizePerRadius': { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'sectionCutsStepSizeDepth': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'sectionCutsStepSizeAngle': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'reducedPointCloudFactor': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'unitsFactor': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'minimumCorrespondentPoints': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'maximumCorrespondentPoints': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'correspondentPointClusterRadius': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'wallDistanceMin': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'wallDistanceMax': number;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'bottomPositionSelection': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'volumeCalculation': boolean;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsDto
     */
    'volumeStartDepth': number;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
 */
export interface RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallplotStepSizeDepthDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallplotStepSizeAngleDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallplotStepSizeHeightDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallplotStepSizeLengthDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'snorkelWallplotStepSizeDepthDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'snorkelWallplotStepSizeAngleDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'bottomplotStepSizeRadiusDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'bottomplotStepSizeAngleDefault': number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'bottomplotAngleStepSizePerRadiusDefault': { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'sectionCutsStepSizeDepthDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'sectionCutsStepSizeAngleDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'reducedPointCloudFactorDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'unitsFactorDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'minimumCorrespondentPointsDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'maximumCorrespondentPointsDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'correspondentPointClusterRadiusDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallDistanceMinDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallDistanceMaxDefault': number;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'bottomPositionSelectionDefault': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'volumeCalculationDefault': boolean;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'volumeStartDepthDefault': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallplotStepSizeDepth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallplotStepSizeAngle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallplotStepSizeHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallplotStepSizeLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'snorkelWallplotStepSizeDepth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'snorkelWallplotStepSizeAngle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'bottomplotStepSizeRadius'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'bottomplotStepSizeAngle'?: number | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'bottomplotAngleStepSizePerRadius'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'sectionCutsStepSizeDepth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'sectionCutsStepSizeAngle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'reducedPointCloudFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'unitsFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'minimumCorrespondentPoints'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'maximumCorrespondentPoints'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'correspondentPointClusterRadius'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallDistanceMin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'wallDistanceMax'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'bottomPositionSelection'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'volumeCalculation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto
     */
    'volumeStartDepth'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
 */
export interface RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'wallplotStepSizeDepth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'wallplotStepSizeAngle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'wallplotStepSizeHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'wallplotStepSizeLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'snorkelWallplotStepSizeDepth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'snorkelWallplotStepSizeAngle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'bottomplotStepSizeRadius'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'bottomplotStepSizeAngle'?: number | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'bottomplotAngleStepSizePerRadius'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'sectionCutsStepSizeDepth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'sectionCutsStepSizeAngle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'reducedPointCloudFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'unitsFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'minimumCorrespondentPoints'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'maximumCorrespondentPoints'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'correspondentPointClusterRadius'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'wallDistanceMin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'wallDistanceMax'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'bottomPositionSelection'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'volumeCalculation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto
     */
    'volumeStartDepth'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsVolumeValueDto
 */
export interface RHIMMeasurementServiceV1ModelsVolumeValueDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsVolumeValueDto
     */
    'depth': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsVolumeValueDto
     */
    'volume': number;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsVolumesPlotDto
 */
export interface RHIMMeasurementServiceV1ModelsVolumesPlotDto {
    /**
     * 
     * @type {Array<RHIMMeasurementServiceV1ModelsVolumeValueDto>}
     * @memberof RHIMMeasurementServiceV1ModelsVolumesPlotDto
     */
    'volumes': Array<RHIMMeasurementServiceV1ModelsVolumeValueDto>;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsWallPlotCellDto
 */
export interface RHIMMeasurementServiceV1ModelsWallPlotCellDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsWallPlotCellDto
     */
    'angle': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsWallPlotCellDto
     */
    'depth': number;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsPlotValuesDto}
     * @memberof RHIMMeasurementServiceV1ModelsWallPlotCellDto
     */
    'rbl'?: RHIMMeasurementServiceV1ModelsPlotValuesDto | null;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsPlotValuesDto}
     * @memberof RHIMMeasurementServiceV1ModelsWallPlotCellDto
     */
    'wear'?: RHIMMeasurementServiceV1ModelsPlotValuesDto | null;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsWallPlotDto
 */
export interface RHIMMeasurementServiceV1ModelsWallPlotDto {
    /**
     * 
     * @type {Array<RHIMMeasurementServiceV1ModelsWallPlotCellDto>}
     * @memberof RHIMMeasurementServiceV1ModelsWallPlotDto
     */
    'data': Array<RHIMMeasurementServiceV1ModelsWallPlotCellDto>;
    /**
     * 
     * @type {RHIMMeasurementServiceV1ModelsWallPlotMetadataDto}
     * @memberof RHIMMeasurementServiceV1ModelsWallPlotDto
     */
    'metadata': RHIMMeasurementServiceV1ModelsWallPlotMetadataDto;
}
/**
 * 
 * @export
 * @interface RHIMMeasurementServiceV1ModelsWallPlotMetadataDto
 */
export interface RHIMMeasurementServiceV1ModelsWallPlotMetadataDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsWallPlotMetadataDto
     */
    'columns': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMMeasurementServiceV1ModelsWallPlotMetadataDto
     */
    'rows': number;
}
/**
 * 
 * @export
 * @interface RHIMOperatorDisplayServiceClientRegionMinimumBrickLengthDto
 */
export interface RHIMOperatorDisplayServiceClientRegionMinimumBrickLengthDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMOperatorDisplayServiceClientRegionMinimumBrickLengthDto
     */
    'regionId': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMOperatorDisplayServiceClientRegionMinimumBrickLengthDto
     */
    'minimumBrickLength': number;
}

/**
 * CalculationsApi - axios parameter creator
 * @export
 */
export const CalculationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Triggers the calculation of a transformation matrix.
         * @summary Triggers the calculation of a transformation matrix.
         * @param {string} vesselId Format - uuid. The vessel Id for authorization
         * @param {RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto} [rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto] The source and target points and the transformation matrix (pointcloudmodifications)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCalculationsVesselidTransformpoints: async (vesselId: string, rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto?: RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postCalculationsVesselidTransformpoints', 'vesselId', vesselId)
            const localVarPath = `/Calculations/{vesselId}/transformpoints`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalculationsApi - functional programming interface
 * @export
 */
export const CalculationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalculationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Triggers the calculation of a transformation matrix.
         * @summary Triggers the calculation of a transformation matrix.
         * @param {string} vesselId Format - uuid. The vessel Id for authorization
         * @param {RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto} [rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto] The source and target points and the transformation matrix (pointcloudmodifications)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCalculationsVesselidTransformpoints(vesselId: string, rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto?: RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsCalculateTransformPointsResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCalculationsVesselidTransformpoints(vesselId, rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalculationsApi - factory interface
 * @export
 */
export const CalculationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalculationsApiFp(configuration)
    return {
        /**
         * Triggers the calculation of a transformation matrix.
         * @summary Triggers the calculation of a transformation matrix.
         * @param {string} vesselId Format - uuid. The vessel Id for authorization
         * @param {RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto} [rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto] The source and target points and the transformation matrix (pointcloudmodifications)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCalculationsVesselidTransformpoints(vesselId: string, rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto?: RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsCalculateTransformPointsResultDto> {
            return localVarFp.postCalculationsVesselidTransformpoints(vesselId, rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalculationsApi - object-oriented interface
 * @export
 * @class CalculationsApi
 * @extends {BaseAPI}
 */
export class CalculationsApi extends BaseAPI {
    /**
     * Triggers the calculation of a transformation matrix.
     * @summary Triggers the calculation of a transformation matrix.
     * @param {string} vesselId Format - uuid. The vessel Id for authorization
     * @param {RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto} [rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto] The source and target points and the transformation matrix (pointcloudmodifications)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculationsApi
     */
    public postCalculationsVesselidTransformpoints(vesselId: string, rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto?: RHIMMeasurementServiceV1ModelsCalculateTransformPointsDto, options?: AxiosRequestConfig) {
        return CalculationsApiFp(this.configuration).postCalculationsVesselidTransformpoints(vesselId, rHIMMeasurementServiceV1ModelsCalculateTransformPointsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CampaignsApi - axios parameter creator
 * @export
 */
export const CampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets an overview of measurements for the specified vessel and campaign.
         * @summary Gets an overview of measurements for the specified vessel and campaign.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignsVesselidCampaignOverview: async (vesselId: string, campaign: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getCampaignsVesselidCampaignOverview', 'vesselId', vesselId)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('getCampaignsVesselidCampaignOverview', 'campaign', campaign)
            const localVarPath = `/Campaigns/{vesselId}/{campaign}/overview`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaign"}}`, encodeURIComponent(String(campaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignsApi - functional programming interface
 * @export
 */
export const CampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets an overview of measurements for the specified vessel and campaign.
         * @summary Gets an overview of measurements for the specified vessel and campaign.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignsVesselidCampaignOverview(vesselId: string, campaign: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignsVesselidCampaignOverview(vesselId, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignsApi - factory interface
 * @export
 */
export const CampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignsApiFp(configuration)
    return {
        /**
         * Gets an overview of measurements for the specified vessel and campaign.
         * @summary Gets an overview of measurements for the specified vessel and campaign.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignsVesselidCampaignOverview(vesselId: string, campaign: number, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementsOfCampaignOverviewDto> {
            return localVarFp.getCampaignsVesselidCampaignOverview(vesselId, campaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignsApi - object-oriented interface
 * @export
 * @class CampaignsApi
 * @extends {BaseAPI}
 */
export class CampaignsApi extends BaseAPI {
    /**
     * Gets an overview of measurements for the specified vessel and campaign.
     * @summary Gets an overview of measurements for the specified vessel and campaign.
     * @param {string} vesselId Format - uuid. The id of the vessel.
     * @param {number} campaign Format - int32. The number of the campaign.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getCampaignsVesselidCampaignOverview(vesselId: string, campaign: number, options?: AxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).getCampaignsVesselidCampaignOverview(vesselId, campaign, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MeasurementApi - axios parameter creator
 * @export
 */
export const MeasurementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the details of a measurement.
         * @summary Gets the details of a measurement.
         * @param {string} measurementId Format - uuid. The id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementMeasurementid: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getMeasurementMeasurementid', 'measurementId', measurementId)
            const localVarPath = `/Measurement/{measurementId}`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the current state of a measurement.
         * @summary Gets the current state of a measurement.
         * @param {string} measurementId Format - uuid. The id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementMeasurementidState: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getMeasurementMeasurementidState', 'measurementId', measurementId)
            const localVarPath = `/Measurement/{measurementId}/state`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasurementApi - functional programming interface
 * @export
 */
export const MeasurementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasurementApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the details of a measurement.
         * @summary Gets the details of a measurement.
         * @param {string} measurementId Format - uuid. The id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementMeasurementid(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementMeasurementid(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the current state of a measurement.
         * @summary Gets the current state of a measurement.
         * @param {string} measurementId Format - uuid. The id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementMeasurementidState(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementMeasurementidState(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeasurementApi - factory interface
 * @export
 */
export const MeasurementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasurementApiFp(configuration)
    return {
        /**
         * Gets the details of a measurement.
         * @summary Gets the details of a measurement.
         * @param {string} measurementId Format - uuid. The id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementMeasurementid(measurementId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementDto> {
            return localVarFp.getMeasurementMeasurementid(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the current state of a measurement.
         * @summary Gets the current state of a measurement.
         * @param {string} measurementId Format - uuid. The id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementMeasurementidState(measurementId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementStatusDto> {
            return localVarFp.getMeasurementMeasurementidState(measurementId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasurementApi - object-oriented interface
 * @export
 * @class MeasurementApi
 * @extends {BaseAPI}
 */
export class MeasurementApi extends BaseAPI {
    /**
     * Gets the details of a measurement.
     * @summary Gets the details of a measurement.
     * @param {string} measurementId Format - uuid. The id of the measurement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApi
     */
    public getMeasurementMeasurementid(measurementId: string, options?: AxiosRequestConfig) {
        return MeasurementApiFp(this.configuration).getMeasurementMeasurementid(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the current state of a measurement.
     * @summary Gets the current state of a measurement.
     * @param {string} measurementId Format - uuid. The id of the measurement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApi
     */
    public getMeasurementMeasurementidState(measurementId: string, options?: AxiosRequestConfig) {
        return MeasurementApiFp(this.configuration).getMeasurementMeasurementidState(measurementId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MeasurementFilesApi - axios parameter creator
 * @export
 */
export const MeasurementFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Downloads a measurement file.
         * @summary Downloads a measurement file.
         * @param {string} measurementFileId Format - uuid. The id of the requested measurement file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementfilesMeasurementfileid: async (measurementFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementFileId' is not null or undefined
            assertParamExists('getMeasurementfilesMeasurementfileid', 'measurementFileId', measurementFileId)
            const localVarPath = `/MeasurementFiles/{measurementFileId}`
                .replace(`{${"measurementFileId"}}`, encodeURIComponent(String(measurementFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns region values file
         * @summary Returns region values file
         * @param {string} measurementFileId Format - uuid. The Id of the measurement file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementfilesRegionvaluesMeasurementfileid: async (measurementFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementFileId' is not null or undefined
            assertParamExists('getMeasurementfilesRegionvaluesMeasurementfileid', 'measurementFileId', measurementFileId)
            const localVarPath = `/MeasurementFiles/regionvalues/{measurementFileId}`
                .replace(`{${"measurementFileId"}}`, encodeURIComponent(String(measurementFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns volumes file
         * @summary Returns volumes file
         * @param {string} measurementFileId Format - uuid. The Id of the measurement file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementfilesVolumesMeasurementfileid: async (measurementFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementFileId' is not null or undefined
            assertParamExists('getMeasurementfilesVolumesMeasurementfileid', 'measurementFileId', measurementFileId)
            const localVarPath = `/MeasurementFiles/volumes/{measurementFileId}`
                .replace(`{${"measurementFileId"}}`, encodeURIComponent(String(measurementFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasurementFilesApi - functional programming interface
 * @export
 */
export const MeasurementFilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasurementFilesApiAxiosParamCreator(configuration)
    return {
        /**
         * Downloads a measurement file.
         * @summary Downloads a measurement file.
         * @param {string} measurementFileId Format - uuid. The id of the requested measurement file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementfilesMeasurementfileid(measurementFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementfilesMeasurementfileid(measurementFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns region values file
         * @summary Returns region values file
         * @param {string} measurementFileId Format - uuid. The Id of the measurement file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementfilesRegionvaluesMeasurementfileid(measurementFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMMeasurementServiceV1ModelsPlotRegionValueDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementfilesRegionvaluesMeasurementfileid(measurementFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns volumes file
         * @summary Returns volumes file
         * @param {string} measurementFileId Format - uuid. The Id of the measurement file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementfilesVolumesMeasurementfileid(measurementFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsVolumesPlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementfilesVolumesMeasurementfileid(measurementFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeasurementFilesApi - factory interface
 * @export
 */
export const MeasurementFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasurementFilesApiFp(configuration)
    return {
        /**
         * Downloads a measurement file.
         * @summary Downloads a measurement file.
         * @param {string} measurementFileId Format - uuid. The id of the requested measurement file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementfilesMeasurementfileid(measurementFileId: string, options?: any): AxiosPromise<File> {
            return localVarFp.getMeasurementfilesMeasurementfileid(measurementFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns region values file
         * @summary Returns region values file
         * @param {string} measurementFileId Format - uuid. The Id of the measurement file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementfilesRegionvaluesMeasurementfileid(measurementFileId: string, options?: any): AxiosPromise<Array<RHIMMeasurementServiceV1ModelsPlotRegionValueDto>> {
            return localVarFp.getMeasurementfilesRegionvaluesMeasurementfileid(measurementFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns volumes file
         * @summary Returns volumes file
         * @param {string} measurementFileId Format - uuid. The Id of the measurement file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementfilesVolumesMeasurementfileid(measurementFileId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsVolumesPlotDto> {
            return localVarFp.getMeasurementfilesVolumesMeasurementfileid(measurementFileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasurementFilesApi - object-oriented interface
 * @export
 * @class MeasurementFilesApi
 * @extends {BaseAPI}
 */
export class MeasurementFilesApi extends BaseAPI {
    /**
     * Downloads a measurement file.
     * @summary Downloads a measurement file.
     * @param {string} measurementFileId Format - uuid. The id of the requested measurement file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementFilesApi
     */
    public getMeasurementfilesMeasurementfileid(measurementFileId: string, options?: AxiosRequestConfig) {
        return MeasurementFilesApiFp(this.configuration).getMeasurementfilesMeasurementfileid(measurementFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns region values file
     * @summary Returns region values file
     * @param {string} measurementFileId Format - uuid. The Id of the measurement file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementFilesApi
     */
    public getMeasurementfilesRegionvaluesMeasurementfileid(measurementFileId: string, options?: AxiosRequestConfig) {
        return MeasurementFilesApiFp(this.configuration).getMeasurementfilesRegionvaluesMeasurementfileid(measurementFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns volumes file
     * @summary Returns volumes file
     * @param {string} measurementFileId Format - uuid. The Id of the measurement file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementFilesApi
     */
    public getMeasurementfilesVolumesMeasurementfileid(measurementFileId: string, options?: AxiosRequestConfig) {
        return MeasurementFilesApiFp(this.configuration).getMeasurementfilesVolumesMeasurementfileid(measurementFileId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MeasurementViewApi - axios parameter creator
 * @export
 */
export const MeasurementViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the content of a 3d (glb) file.
         * @summary Returns the content of a 3d (glb) file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementid3d: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getMeasurementviewFileMeasurementid3d', 'measurementId', measurementId)
            const localVarPath = `/MeasurementView/file/{measurementId}/3d`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the content of a measurement related bottom plot file.
         * @summary Returns the content of a measurement related bottom plot file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidBottomplot: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getMeasurementviewFileMeasurementidBottomplot', 'measurementId', measurementId)
            const localVarPath = `/MeasurementView/file/{measurementId}/bottomplot`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the content of a measurement related metadata file.
         * @summary Returns the content of a measurement related metadata file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidMeta: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getMeasurementviewFileMeasurementidMeta', 'measurementId', measurementId)
            const localVarPath = `/MeasurementView/file/{measurementId}/meta`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the content of a measurement related low res RBL file.
         * @summary Returns the content of a measurement related low res RBL file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidRbl: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getMeasurementviewFileMeasurementidRbl', 'measurementId', measurementId)
            const localVarPath = `/MeasurementView/file/{measurementId}/rbl`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the content of a measurement related metadata file.
         * @summary Returns the content of a measurement related metadata file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidSectioncuts: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getMeasurementviewFileMeasurementidSectioncuts', 'measurementId', measurementId)
            const localVarPath = `/MeasurementView/file/{measurementId}/sectioncuts`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the content of a reduced point cloud (json) file.
         * @summary Returns the content of a reduced point cloud (json) file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidViewpointcloud: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getMeasurementviewFileMeasurementidViewpointcloud', 'measurementId', measurementId)
            const localVarPath = `/MeasurementView/file/{measurementId}/viewpointcloud`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the content of a measurement related wall plot file.
         * @summary Returns the content of a measurement related wall plot file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidWallplot: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getMeasurementviewFileMeasurementidWallplot', 'measurementId', measurementId)
            const localVarPath = `/MeasurementView/file/{measurementId}/wallplot`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the content of a measurement related low res Wear file.
         * @summary Returns the content of a measurement related low res Wear file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidWear: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getMeasurementviewFileMeasurementidWear', 'measurementId', measurementId)
            const localVarPath = `/MeasurementView/file/{measurementId}/wear`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details for the specified measurement.
         * @summary Returns the details for the specified measurement.
         * @param {string} measurementId Format - uuid. the Id of the measurement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewMeasurementidDetails: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getMeasurementviewMeasurementidDetails', 'measurementId', measurementId)
            const localVarPath = `/MeasurementView/{measurementId}/details`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the measurement view settings for a customer and vesselType.
         * @summary Returns the measurement view settings for a customer and vesselType.
         * @param {string} customerId Format - uuid. The id of the customer.
         * @param {string} vesselType The type of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewSettingsCustomeridVesseltype: async (customerId: string, vesselType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getMeasurementviewSettingsCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('getMeasurementviewSettingsCustomeridVesseltype', 'vesselType', vesselType)
            const localVarPath = `/MeasurementView/settings/{customerId}/{vesselType}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the measurement view settings for a specific vessel with default values from the underlying vesselType settings.
         * @summary Returns the measurement view settings for a specific vessel with default values from the underlying vesselType settings.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewSettingsEditVesselid: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementviewSettingsEditVesselid', 'vesselId', vesselId)
            const localVarPath = `/MeasurementView/settings/edit/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the measurement view settings for a specific vessel.
         * @summary Returns the measurement view settings for a specific vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewSettingsVesselid: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementviewSettingsVesselid', 'vesselId', vesselId)
            const localVarPath = `/MeasurementView/settings/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of campaigns for which measurements can be visualized.
         * @summary Returns a list of campaigns for which measurements can be visualized.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselidCampaigns: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementviewVesselidCampaigns', 'vesselId', vesselId)
            const localVarPath = `/MeasurementView/{vesselId}/campaigns`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of measurements for a vessel where the notes field is like the requested value.  In order to enable search the following key expression(s) will be substituted:  ((*)) -> %
         * @summary Returns a list of measurements for a vessel where the notes field is like the requested value.  In order to enable search the following key expression(s) will be substituted:  ((*)) -> %
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {string} [notes] A value for the search in the Notes field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselidMeasurementsByNotes: async (vesselId: string, notes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementviewVesselidMeasurementsByNotes', 'vesselId', vesselId)
            const localVarPath = `/MeasurementView/{vesselId}/measurements/by-notes`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of measurements for a vessel where the notes field matches the requested value.
         * @summary Returns a list of measurements for a vessel where the notes field matches the requested value.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {string} [notes] A value for the search in the Notes field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselidMeasurementsByNotesExact: async (vesselId: string, notes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementviewVesselidMeasurementsByNotesExact', 'vesselId', vesselId)
            const localVarPath = `/MeasurementView/{vesselId}/measurements/by-notes-exact`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of measurements for a vessel and a campaign in which there are files for visualization.
         * @summary Returns a list of measurements for a vessel and a campaign in which there are files for visualization.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The Id of the campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselidMeasurementsCampaign: async (vesselId: string, campaign: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementviewVesselidMeasurementsCampaign', 'vesselId', vesselId)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('getMeasurementviewVesselidMeasurementsCampaign', 'campaign', campaign)
            const localVarPath = `/MeasurementView/{vesselId}/measurements/{campaign}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaign"}}`, encodeURIComponent(String(campaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns data of a vessel for the measurement view.
         * @summary Returns data of a vessel for the measurement view.
         * @param {string} vesselId Format - uuid. the Id of the measurement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselidVessel: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementviewVesselidVessel', 'vesselId', vesselId)
            const localVarPath = `/MeasurementView/{vesselId}/vessel`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of vessels having measurements.
         * @summary Returns a list of vessels having measurements.
         * @param {string} customerId Format - uuid. the Id of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselsCustomerid: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getMeasurementviewVesselsCustomerid', 'customerId', customerId)
            const localVarPath = `/MeasurementView/vessels/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores the measurement view settings for a customer and vesselType.
         * @summary Stores the measurement view settings for a customer and vesselType.
         * @param {string} customerId Format - uuid. The id of the customer.
         * @param {string} vesselType The type of the vessel.
         * @param {RHIMMeasurementServiceV1ModelsView3dSettingsDto} [rHIMMeasurementServiceV1ModelsView3dSettingsDto] The data to be stored.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMeasurementviewSettingsCustomeridVesseltype: async (customerId: string, vesselType: string, rHIMMeasurementServiceV1ModelsView3dSettingsDto?: RHIMMeasurementServiceV1ModelsView3dSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('postMeasurementviewSettingsCustomeridVesseltype', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('postMeasurementviewSettingsCustomeridVesseltype', 'vesselType', vesselType)
            const localVarPath = `/MeasurementView/settings/{customerId}/{vesselType}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMMeasurementServiceV1ModelsView3dSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores the measurement view settings for a vessel.
         * @summary Stores the measurement view settings for a vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto} [rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto] The data to be stored.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMeasurementviewSettingsEditVesselid: async (vesselId: string, rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto?: RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postMeasurementviewSettingsEditVesselid', 'vesselId', vesselId)
            const localVarPath = `/MeasurementView/settings/edit/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasurementViewApi - functional programming interface
 * @export
 */
export const MeasurementViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasurementViewApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the content of a 3d (glb) file.
         * @summary Returns the content of a 3d (glb) file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewFileMeasurementid3d(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewFileMeasurementid3d(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the content of a measurement related bottom plot file.
         * @summary Returns the content of a measurement related bottom plot file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewFileMeasurementidBottomplot(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsBottomPlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewFileMeasurementidBottomplot(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the content of a measurement related metadata file.
         * @summary Returns the content of a measurement related metadata file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewFileMeasurementidMeta(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewFileMeasurementidMeta(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the content of a measurement related low res RBL file.
         * @summary Returns the content of a measurement related low res RBL file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewFileMeasurementidRbl(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewFileMeasurementidRbl(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the content of a measurement related metadata file.
         * @summary Returns the content of a measurement related metadata file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewFileMeasurementidSectioncuts(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsSectionCutsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewFileMeasurementidSectioncuts(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the content of a reduced point cloud (json) file.
         * @summary Returns the content of a reduced point cloud (json) file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewFileMeasurementidViewpointcloud(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewFileMeasurementidViewpointcloud(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the content of a measurement related wall plot file.
         * @summary Returns the content of a measurement related wall plot file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewFileMeasurementidWallplot(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsWallPlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewFileMeasurementidWallplot(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the content of a measurement related low res Wear file.
         * @summary Returns the content of a measurement related low res Wear file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewFileMeasurementidWear(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewFileMeasurementidWear(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the details for the specified measurement.
         * @summary Returns the details for the specified measurement.
         * @param {string} measurementId Format - uuid. the Id of the measurement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewMeasurementidDetails(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementViewDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewMeasurementidDetails(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the measurement view settings for a customer and vesselType.
         * @summary Returns the measurement view settings for a customer and vesselType.
         * @param {string} customerId Format - uuid. The id of the customer.
         * @param {string} vesselType The type of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewSettingsCustomeridVesseltype(customerId: string, vesselType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsView3dSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewSettingsCustomeridVesseltype(customerId, vesselType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the measurement view settings for a specific vessel with default values from the underlying vesselType settings.
         * @summary Returns the measurement view settings for a specific vessel with default values from the underlying vesselType settings.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewSettingsEditVesselid(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewSettingsEditVesselid(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the measurement view settings for a specific vessel.
         * @summary Returns the measurement view settings for a specific vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewSettingsVesselid(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsView3dSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewSettingsVesselid(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of campaigns for which measurements can be visualized.
         * @summary Returns a list of campaigns for which measurements can be visualized.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewVesselidCampaigns(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMMeasurementServiceV1ModelsCampaignDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewVesselidCampaigns(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of measurements for a vessel where the notes field is like the requested value.  In order to enable search the following key expression(s) will be substituted:  ((*)) -> %
         * @summary Returns a list of measurements for a vessel where the notes field is like the requested value.  In order to enable search the following key expression(s) will be substituted:  ((*)) -> %
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {string} [notes] A value for the search in the Notes field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewVesselidMeasurementsByNotes(vesselId: string, notes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMMeasurementServiceV1ModelsMeasurementViewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewVesselidMeasurementsByNotes(vesselId, notes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of measurements for a vessel where the notes field matches the requested value.
         * @summary Returns a list of measurements for a vessel where the notes field matches the requested value.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {string} [notes] A value for the search in the Notes field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewVesselidMeasurementsByNotesExact(vesselId: string, notes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMMeasurementServiceV1ModelsMeasurementViewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewVesselidMeasurementsByNotesExact(vesselId, notes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of measurements for a vessel and a campaign in which there are files for visualization.
         * @summary Returns a list of measurements for a vessel and a campaign in which there are files for visualization.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The Id of the campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewVesselidMeasurementsCampaign(vesselId: string, campaign: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMMeasurementServiceV1ModelsMeasurementViewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewVesselidMeasurementsCampaign(vesselId, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns data of a vessel for the measurement view.
         * @summary Returns data of a vessel for the measurement view.
         * @param {string} vesselId Format - uuid. the Id of the measurement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewVesselidVessel(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementViewVesselDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewVesselidVessel(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of vessels having measurements.
         * @summary Returns a list of vessels having measurements.
         * @param {string} customerId Format - uuid. the Id of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementviewVesselsCustomerid(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMMeasurementServiceV1ModelsVesselDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementviewVesselsCustomerid(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stores the measurement view settings for a customer and vesselType.
         * @summary Stores the measurement view settings for a customer and vesselType.
         * @param {string} customerId Format - uuid. The id of the customer.
         * @param {string} vesselType The type of the vessel.
         * @param {RHIMMeasurementServiceV1ModelsView3dSettingsDto} [rHIMMeasurementServiceV1ModelsView3dSettingsDto] The data to be stored.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMeasurementviewSettingsCustomeridVesseltype(customerId: string, vesselType: string, rHIMMeasurementServiceV1ModelsView3dSettingsDto?: RHIMMeasurementServiceV1ModelsView3dSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMeasurementviewSettingsCustomeridVesseltype(customerId, vesselType, rHIMMeasurementServiceV1ModelsView3dSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stores the measurement view settings for a vessel.
         * @summary Stores the measurement view settings for a vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto} [rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto] The data to be stored.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMeasurementviewSettingsEditVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto?: RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMeasurementviewSettingsEditVesselid(vesselId, rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeasurementViewApi - factory interface
 * @export
 */
export const MeasurementViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasurementViewApiFp(configuration)
    return {
        /**
         * Returns the content of a 3d (glb) file.
         * @summary Returns the content of a 3d (glb) file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementid3d(measurementId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getMeasurementviewFileMeasurementid3d(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the content of a measurement related bottom plot file.
         * @summary Returns the content of a measurement related bottom plot file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidBottomplot(measurementId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsBottomPlotDto> {
            return localVarFp.getMeasurementviewFileMeasurementidBottomplot(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the content of a measurement related metadata file.
         * @summary Returns the content of a measurement related metadata file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidMeta(measurementId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementMetadataDto> {
            return localVarFp.getMeasurementviewFileMeasurementidMeta(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the content of a measurement related low res RBL file.
         * @summary Returns the content of a measurement related low res RBL file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidRbl(measurementId: string, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getMeasurementviewFileMeasurementidRbl(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the content of a measurement related metadata file.
         * @summary Returns the content of a measurement related metadata file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidSectioncuts(measurementId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsSectionCutsDto> {
            return localVarFp.getMeasurementviewFileMeasurementidSectioncuts(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the content of a reduced point cloud (json) file.
         * @summary Returns the content of a reduced point cloud (json) file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidViewpointcloud(measurementId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getMeasurementviewFileMeasurementidViewpointcloud(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the content of a measurement related wall plot file.
         * @summary Returns the content of a measurement related wall plot file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidWallplot(measurementId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsWallPlotDto> {
            return localVarFp.getMeasurementviewFileMeasurementidWallplot(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the content of a measurement related low res Wear file.
         * @summary Returns the content of a measurement related low res Wear file.
         * @param {string} measurementId Format - uuid. The Id of the measurement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewFileMeasurementidWear(measurementId: string, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getMeasurementviewFileMeasurementidWear(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details for the specified measurement.
         * @summary Returns the details for the specified measurement.
         * @param {string} measurementId Format - uuid. the Id of the measurement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewMeasurementidDetails(measurementId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementViewDataDto> {
            return localVarFp.getMeasurementviewMeasurementidDetails(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the measurement view settings for a customer and vesselType.
         * @summary Returns the measurement view settings for a customer and vesselType.
         * @param {string} customerId Format - uuid. The id of the customer.
         * @param {string} vesselType The type of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewSettingsCustomeridVesseltype(customerId: string, vesselType: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsView3dSettingsDto> {
            return localVarFp.getMeasurementviewSettingsCustomeridVesseltype(customerId, vesselType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the measurement view settings for a specific vessel with default values from the underlying vesselType settings.
         * @summary Returns the measurement view settings for a specific vessel with default values from the underlying vesselType settings.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewSettingsEditVesselid(vesselId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsView3dSettingsVesselDto> {
            return localVarFp.getMeasurementviewSettingsEditVesselid(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the measurement view settings for a specific vessel.
         * @summary Returns the measurement view settings for a specific vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewSettingsVesselid(vesselId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsView3dSettingsDto> {
            return localVarFp.getMeasurementviewSettingsVesselid(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of campaigns for which measurements can be visualized.
         * @summary Returns a list of campaigns for which measurements can be visualized.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselidCampaigns(vesselId: string, options?: any): AxiosPromise<Array<RHIMMeasurementServiceV1ModelsCampaignDto>> {
            return localVarFp.getMeasurementviewVesselidCampaigns(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of measurements for a vessel where the notes field is like the requested value.  In order to enable search the following key expression(s) will be substituted:  ((*)) -> %
         * @summary Returns a list of measurements for a vessel where the notes field is like the requested value.  In order to enable search the following key expression(s) will be substituted:  ((*)) -> %
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {string} [notes] A value for the search in the Notes field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselidMeasurementsByNotes(vesselId: string, notes?: string, options?: any): AxiosPromise<Array<RHIMMeasurementServiceV1ModelsMeasurementViewDto>> {
            return localVarFp.getMeasurementviewVesselidMeasurementsByNotes(vesselId, notes, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of measurements for a vessel where the notes field matches the requested value.
         * @summary Returns a list of measurements for a vessel where the notes field matches the requested value.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {string} [notes] A value for the search in the Notes field.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselidMeasurementsByNotesExact(vesselId: string, notes?: string, options?: any): AxiosPromise<Array<RHIMMeasurementServiceV1ModelsMeasurementViewDto>> {
            return localVarFp.getMeasurementviewVesselidMeasurementsByNotesExact(vesselId, notes, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of measurements for a vessel and a campaign in which there are files for visualization.
         * @summary Returns a list of measurements for a vessel and a campaign in which there are files for visualization.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The Id of the campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselidMeasurementsCampaign(vesselId: string, campaign: number, options?: any): AxiosPromise<Array<RHIMMeasurementServiceV1ModelsMeasurementViewDto>> {
            return localVarFp.getMeasurementviewVesselidMeasurementsCampaign(vesselId, campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns data of a vessel for the measurement view.
         * @summary Returns data of a vessel for the measurement view.
         * @param {string} vesselId Format - uuid. the Id of the measurement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselidVessel(vesselId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementViewVesselDataDto> {
            return localVarFp.getMeasurementviewVesselidVessel(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of vessels having measurements.
         * @summary Returns a list of vessels having measurements.
         * @param {string} customerId Format - uuid. the Id of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementviewVesselsCustomerid(customerId: string, options?: any): AxiosPromise<Array<RHIMMeasurementServiceV1ModelsVesselDto>> {
            return localVarFp.getMeasurementviewVesselsCustomerid(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores the measurement view settings for a customer and vesselType.
         * @summary Stores the measurement view settings for a customer and vesselType.
         * @param {string} customerId Format - uuid. The id of the customer.
         * @param {string} vesselType The type of the vessel.
         * @param {RHIMMeasurementServiceV1ModelsView3dSettingsDto} [rHIMMeasurementServiceV1ModelsView3dSettingsDto] The data to be stored.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMeasurementviewSettingsCustomeridVesseltype(customerId: string, vesselType: string, rHIMMeasurementServiceV1ModelsView3dSettingsDto?: RHIMMeasurementServiceV1ModelsView3dSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.postMeasurementviewSettingsCustomeridVesseltype(customerId, vesselType, rHIMMeasurementServiceV1ModelsView3dSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores the measurement view settings for a vessel.
         * @summary Stores the measurement view settings for a vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto} [rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto] The data to be stored.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMeasurementviewSettingsEditVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto?: RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.postMeasurementviewSettingsEditVesselid(vesselId, rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasurementViewApi - object-oriented interface
 * @export
 * @class MeasurementViewApi
 * @extends {BaseAPI}
 */
export class MeasurementViewApi extends BaseAPI {
    /**
     * Returns the content of a 3d (glb) file.
     * @summary Returns the content of a 3d (glb) file.
     * @param {string} measurementId Format - uuid. The Id of the measurement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewFileMeasurementid3d(measurementId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewFileMeasurementid3d(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the content of a measurement related bottom plot file.
     * @summary Returns the content of a measurement related bottom plot file.
     * @param {string} measurementId Format - uuid. The Id of the measurement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewFileMeasurementidBottomplot(measurementId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewFileMeasurementidBottomplot(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the content of a measurement related metadata file.
     * @summary Returns the content of a measurement related metadata file.
     * @param {string} measurementId Format - uuid. The Id of the measurement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewFileMeasurementidMeta(measurementId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewFileMeasurementidMeta(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the content of a measurement related low res RBL file.
     * @summary Returns the content of a measurement related low res RBL file.
     * @param {string} measurementId Format - uuid. The Id of the measurement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewFileMeasurementidRbl(measurementId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewFileMeasurementidRbl(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the content of a measurement related metadata file.
     * @summary Returns the content of a measurement related metadata file.
     * @param {string} measurementId Format - uuid. The Id of the measurement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewFileMeasurementidSectioncuts(measurementId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewFileMeasurementidSectioncuts(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the content of a reduced point cloud (json) file.
     * @summary Returns the content of a reduced point cloud (json) file.
     * @param {string} measurementId Format - uuid. The Id of the measurement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewFileMeasurementidViewpointcloud(measurementId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewFileMeasurementidViewpointcloud(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the content of a measurement related wall plot file.
     * @summary Returns the content of a measurement related wall plot file.
     * @param {string} measurementId Format - uuid. The Id of the measurement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewFileMeasurementidWallplot(measurementId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewFileMeasurementidWallplot(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the content of a measurement related low res Wear file.
     * @summary Returns the content of a measurement related low res Wear file.
     * @param {string} measurementId Format - uuid. The Id of the measurement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewFileMeasurementidWear(measurementId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewFileMeasurementidWear(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details for the specified measurement.
     * @summary Returns the details for the specified measurement.
     * @param {string} measurementId Format - uuid. the Id of the measurement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewMeasurementidDetails(measurementId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewMeasurementidDetails(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the measurement view settings for a customer and vesselType.
     * @summary Returns the measurement view settings for a customer and vesselType.
     * @param {string} customerId Format - uuid. The id of the customer.
     * @param {string} vesselType The type of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewSettingsCustomeridVesseltype(customerId: string, vesselType: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewSettingsCustomeridVesseltype(customerId, vesselType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the measurement view settings for a specific vessel with default values from the underlying vesselType settings.
     * @summary Returns the measurement view settings for a specific vessel with default values from the underlying vesselType settings.
     * @param {string} vesselId Format - uuid. The id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewSettingsEditVesselid(vesselId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewSettingsEditVesselid(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the measurement view settings for a specific vessel.
     * @summary Returns the measurement view settings for a specific vessel.
     * @param {string} vesselId Format - uuid. The id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewSettingsVesselid(vesselId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewSettingsVesselid(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of campaigns for which measurements can be visualized.
     * @summary Returns a list of campaigns for which measurements can be visualized.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewVesselidCampaigns(vesselId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewVesselidCampaigns(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of measurements for a vessel where the notes field is like the requested value.  In order to enable search the following key expression(s) will be substituted:  ((*)) -> %
     * @summary Returns a list of measurements for a vessel where the notes field is like the requested value.  In order to enable search the following key expression(s) will be substituted:  ((*)) -> %
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {string} [notes] A value for the search in the Notes field.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewVesselidMeasurementsByNotes(vesselId: string, notes?: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewVesselidMeasurementsByNotes(vesselId, notes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of measurements for a vessel where the notes field matches the requested value.
     * @summary Returns a list of measurements for a vessel where the notes field matches the requested value.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {string} [notes] A value for the search in the Notes field.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewVesselidMeasurementsByNotesExact(vesselId: string, notes?: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewVesselidMeasurementsByNotesExact(vesselId, notes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of measurements for a vessel and a campaign in which there are files for visualization.
     * @summary Returns a list of measurements for a vessel and a campaign in which there are files for visualization.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {number} campaign Format - int32. The Id of the campaign.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewVesselidMeasurementsCampaign(vesselId: string, campaign: number, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewVesselidMeasurementsCampaign(vesselId, campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns data of a vessel for the measurement view.
     * @summary Returns data of a vessel for the measurement view.
     * @param {string} vesselId Format - uuid. the Id of the measurement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewVesselidVessel(vesselId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewVesselidVessel(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of vessels having measurements.
     * @summary Returns a list of vessels having measurements.
     * @param {string} customerId Format - uuid. the Id of the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public getMeasurementviewVesselsCustomerid(customerId: string, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).getMeasurementviewVesselsCustomerid(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores the measurement view settings for a customer and vesselType.
     * @summary Stores the measurement view settings for a customer and vesselType.
     * @param {string} customerId Format - uuid. The id of the customer.
     * @param {string} vesselType The type of the vessel.
     * @param {RHIMMeasurementServiceV1ModelsView3dSettingsDto} [rHIMMeasurementServiceV1ModelsView3dSettingsDto] The data to be stored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public postMeasurementviewSettingsCustomeridVesseltype(customerId: string, vesselType: string, rHIMMeasurementServiceV1ModelsView3dSettingsDto?: RHIMMeasurementServiceV1ModelsView3dSettingsDto, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).postMeasurementviewSettingsCustomeridVesseltype(customerId, vesselType, rHIMMeasurementServiceV1ModelsView3dSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores the measurement view settings for a vessel.
     * @summary Stores the measurement view settings for a vessel.
     * @param {string} vesselId Format - uuid. The id of the vessel.
     * @param {RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto} [rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto] The data to be stored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementViewApi
     */
    public postMeasurementviewSettingsEditVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto?: RHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto, options?: AxiosRequestConfig) {
        return MeasurementViewApiFp(this.configuration).postMeasurementviewSettingsEditVesselid(vesselId, rHIMMeasurementServiceV1ModelsView3dSettingsVesselUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MeasurementsApi - axios parameter creator
 * @export
 */
export const MeasurementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the list of measurement-data for the requested vessel.
         * @summary Gets the list of measurement-data for the requested vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementsVesselid: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementsVesselid', 'vesselId', vesselId)
            const localVarPath = `/Measurements/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list of measurements for the requested vessel and campaign.
         * @summary Gets the list of measurements for the requested vessel and campaign.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementsVesselidCampaign: async (vesselId: string, campaign: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementsVesselidCampaign', 'vesselId', vesselId)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('getMeasurementsVesselidCampaign', 'campaign', campaign)
            const localVarPath = `/Measurements/{vesselId}/{campaign}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaign"}}`, encodeURIComponent(String(campaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasurementsApi - functional programming interface
 * @export
 */
export const MeasurementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasurementsApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the list of measurement-data for the requested vessel.
         * @summary Gets the list of measurement-data for the requested vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementsVesselid(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMMeasurementServiceV1ModelsMeasurementDataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementsVesselid(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the list of measurements for the requested vessel and campaign.
         * @summary Gets the list of measurements for the requested vessel and campaign.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementsVesselidCampaign(vesselId: string, campaign: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMMeasurementServiceV1ModelsMeasurementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementsVesselidCampaign(vesselId, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeasurementsApi - factory interface
 * @export
 */
export const MeasurementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasurementsApiFp(configuration)
    return {
        /**
         * Gets the list of measurement-data for the requested vessel.
         * @summary Gets the list of measurement-data for the requested vessel.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementsVesselid(vesselId: string, options?: any): AxiosPromise<Array<RHIMMeasurementServiceV1ModelsMeasurementDataDto>> {
            return localVarFp.getMeasurementsVesselid(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list of measurements for the requested vessel and campaign.
         * @summary Gets the list of measurements for the requested vessel and campaign.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementsVesselidCampaign(vesselId: string, campaign: number, options?: any): AxiosPromise<Array<RHIMMeasurementServiceV1ModelsMeasurementDto>> {
            return localVarFp.getMeasurementsVesselidCampaign(vesselId, campaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasurementsApi - object-oriented interface
 * @export
 * @class MeasurementsApi
 * @extends {BaseAPI}
 */
export class MeasurementsApi extends BaseAPI {
    /**
     * Gets the list of measurement-data for the requested vessel.
     * @summary Gets the list of measurement-data for the requested vessel.
     * @param {string} vesselId Format - uuid. The id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementsApi
     */
    public getMeasurementsVesselid(vesselId: string, options?: AxiosRequestConfig) {
        return MeasurementsApiFp(this.configuration).getMeasurementsVesselid(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list of measurements for the requested vessel and campaign.
     * @summary Gets the list of measurements for the requested vessel and campaign.
     * @param {string} vesselId Format - uuid. The id of the vessel.
     * @param {number} campaign Format - int32. The number of the campaign.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementsApi
     */
    public getMeasurementsVesselidCampaign(vesselId: string, campaign: number, options?: AxiosRequestConfig) {
        return MeasurementsApiFp(this.configuration).getMeasurementsVesselidCampaign(vesselId, campaign, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PointCloudModificationsApi - axios parameter creator
 * @export
 */
export const PointCloudModificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Takes the point cloud modifications and sends an internal session event to store the data and triggers the meshing/registering.
         * @summary Takes the point cloud modifications and sends an internal session event to store the data and triggers the meshing/registering.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {RHIMMeasurementServiceV1ModelsPointCloudModificationsDto} [rHIMMeasurementServiceV1ModelsPointCloudModificationsDto] The modifications for the point cloud file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPointcloudmodificationsVesselid: async (vesselId: string, rHIMMeasurementServiceV1ModelsPointCloudModificationsDto?: RHIMMeasurementServiceV1ModelsPointCloudModificationsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postPointcloudmodificationsVesselid', 'vesselId', vesselId)
            const localVarPath = `/PointCloudModifications/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMMeasurementServiceV1ModelsPointCloudModificationsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointCloudModificationsApi - functional programming interface
 * @export
 */
export const PointCloudModificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointCloudModificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Takes the point cloud modifications and sends an internal session event to store the data and triggers the meshing/registering.
         * @summary Takes the point cloud modifications and sends an internal session event to store the data and triggers the meshing/registering.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {RHIMMeasurementServiceV1ModelsPointCloudModificationsDto} [rHIMMeasurementServiceV1ModelsPointCloudModificationsDto] The modifications for the point cloud file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPointcloudmodificationsVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsPointCloudModificationsDto?: RHIMMeasurementServiceV1ModelsPointCloudModificationsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPointcloudmodificationsVesselid(vesselId, rHIMMeasurementServiceV1ModelsPointCloudModificationsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PointCloudModificationsApi - factory interface
 * @export
 */
export const PointCloudModificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointCloudModificationsApiFp(configuration)
    return {
        /**
         * Takes the point cloud modifications and sends an internal session event to store the data and triggers the meshing/registering.
         * @summary Takes the point cloud modifications and sends an internal session event to store the data and triggers the meshing/registering.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {RHIMMeasurementServiceV1ModelsPointCloudModificationsDto} [rHIMMeasurementServiceV1ModelsPointCloudModificationsDto] The modifications for the point cloud file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPointcloudmodificationsVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsPointCloudModificationsDto?: RHIMMeasurementServiceV1ModelsPointCloudModificationsDto, options?: any): AxiosPromise<void> {
            return localVarFp.postPointcloudmodificationsVesselid(vesselId, rHIMMeasurementServiceV1ModelsPointCloudModificationsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointCloudModificationsApi - object-oriented interface
 * @export
 * @class PointCloudModificationsApi
 * @extends {BaseAPI}
 */
export class PointCloudModificationsApi extends BaseAPI {
    /**
     * Takes the point cloud modifications and sends an internal session event to store the data and triggers the meshing/registering.
     * @summary Takes the point cloud modifications and sends an internal session event to store the data and triggers the meshing/registering.
     * @param {string} vesselId Format - uuid. The id of the vessel
     * @param {RHIMMeasurementServiceV1ModelsPointCloudModificationsDto} [rHIMMeasurementServiceV1ModelsPointCloudModificationsDto] The modifications for the point cloud file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointCloudModificationsApi
     */
    public postPointcloudmodificationsVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsPointCloudModificationsDto?: RHIMMeasurementServiceV1ModelsPointCloudModificationsDto, options?: AxiosRequestConfig) {
        return PointCloudModificationsApiFp(this.configuration).postPointcloudmodificationsVesselid(vesselId, rHIMMeasurementServiceV1ModelsPointCloudModificationsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PointCloudRegisterApi - axios parameter creator
 * @export
 */
export const PointCloudRegisterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Calling this endpoint will start the process of register and mesh the ingressed point cloud file.
         * @summary Calling this endpoint will start the process of register and mesh the ingressed point cloud file.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {RHIMMeasurementServiceV1ModelsPointCloudRegisterDto} [rHIMMeasurementServiceV1ModelsPointCloudRegisterDto] The information needed for a successful registering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPointcloudregisterVesselid: async (vesselId: string, rHIMMeasurementServiceV1ModelsPointCloudRegisterDto?: RHIMMeasurementServiceV1ModelsPointCloudRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postPointcloudregisterVesselid', 'vesselId', vesselId)
            const localVarPath = `/PointCloudRegister/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMMeasurementServiceV1ModelsPointCloudRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointCloudRegisterApi - functional programming interface
 * @export
 */
export const PointCloudRegisterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointCloudRegisterApiAxiosParamCreator(configuration)
    return {
        /**
         * Calling this endpoint will start the process of register and mesh the ingressed point cloud file.
         * @summary Calling this endpoint will start the process of register and mesh the ingressed point cloud file.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {RHIMMeasurementServiceV1ModelsPointCloudRegisterDto} [rHIMMeasurementServiceV1ModelsPointCloudRegisterDto] The information needed for a successful registering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPointcloudregisterVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsPointCloudRegisterDto?: RHIMMeasurementServiceV1ModelsPointCloudRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPointcloudregisterVesselid(vesselId, rHIMMeasurementServiceV1ModelsPointCloudRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PointCloudRegisterApi - factory interface
 * @export
 */
export const PointCloudRegisterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointCloudRegisterApiFp(configuration)
    return {
        /**
         * Calling this endpoint will start the process of register and mesh the ingressed point cloud file.
         * @summary Calling this endpoint will start the process of register and mesh the ingressed point cloud file.
         * @param {string} vesselId Format - uuid. The id of the vessel
         * @param {RHIMMeasurementServiceV1ModelsPointCloudRegisterDto} [rHIMMeasurementServiceV1ModelsPointCloudRegisterDto] The information needed for a successful registering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPointcloudregisterVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsPointCloudRegisterDto?: RHIMMeasurementServiceV1ModelsPointCloudRegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.postPointcloudregisterVesselid(vesselId, rHIMMeasurementServiceV1ModelsPointCloudRegisterDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointCloudRegisterApi - object-oriented interface
 * @export
 * @class PointCloudRegisterApi
 * @extends {BaseAPI}
 */
export class PointCloudRegisterApi extends BaseAPI {
    /**
     * Calling this endpoint will start the process of register and mesh the ingressed point cloud file.
     * @summary Calling this endpoint will start the process of register and mesh the ingressed point cloud file.
     * @param {string} vesselId Format - uuid. The id of the vessel
     * @param {RHIMMeasurementServiceV1ModelsPointCloudRegisterDto} [rHIMMeasurementServiceV1ModelsPointCloudRegisterDto] The information needed for a successful registering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointCloudRegisterApi
     */
    public postPointcloudregisterVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsPointCloudRegisterDto?: RHIMMeasurementServiceV1ModelsPointCloudRegisterDto, options?: AxiosRequestConfig) {
        return PointCloudRegisterApiFp(this.configuration).postPointcloudregisterVesselid(vesselId, rHIMMeasurementServiceV1ModelsPointCloudRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReferenceMeasurementsApi - axios parameter creator
 * @export
 */
export const ReferenceMeasurementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Finds a the reference measurement for a vessel and a campaign.
         * @summary Finds a the reference measurement for a vessel and a campaign.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign.
         * @param {number} [heat] Format - int32. The number of the heat, this is optional.
         * @param {string} [measurementTaken] Format - date-time (as date-time in RFC3339). The date time offset of the measurement when it was taken, this is optional.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferencemeasurementsVesselidCampaign: async (vesselId: string, campaign: number, heat?: number, measurementTaken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getReferencemeasurementsVesselidCampaign', 'vesselId', vesselId)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('getReferencemeasurementsVesselidCampaign', 'campaign', campaign)
            const localVarPath = `/ReferenceMeasurements/{vesselId}/{campaign}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaign"}}`, encodeURIComponent(String(campaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (heat !== undefined) {
                localVarQueryParameter['heat'] = heat;
            }

            if (measurementTaken !== undefined) {
                localVarQueryParameter['measurementTaken'] = measurementTaken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceMeasurementsApi - functional programming interface
 * @export
 */
export const ReferenceMeasurementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferenceMeasurementsApiAxiosParamCreator(configuration)
    return {
        /**
         * Finds a the reference measurement for a vessel and a campaign.
         * @summary Finds a the reference measurement for a vessel and a campaign.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign.
         * @param {number} [heat] Format - int32. The number of the heat, this is optional.
         * @param {string} [measurementTaken] Format - date-time (as date-time in RFC3339). The date time offset of the measurement when it was taken, this is optional.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferencemeasurementsVesselidCampaign(vesselId: string, campaign: number, heat?: number, measurementTaken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferencemeasurementsVesselidCampaign(vesselId, campaign, heat, measurementTaken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferenceMeasurementsApi - factory interface
 * @export
 */
export const ReferenceMeasurementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferenceMeasurementsApiFp(configuration)
    return {
        /**
         * Finds a the reference measurement for a vessel and a campaign.
         * @summary Finds a the reference measurement for a vessel and a campaign.
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign.
         * @param {number} [heat] Format - int32. The number of the heat, this is optional.
         * @param {string} [measurementTaken] Format - date-time (as date-time in RFC3339). The date time offset of the measurement when it was taken, this is optional.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferencemeasurementsVesselidCampaign(vesselId: string, campaign: number, heat?: number, measurementTaken?: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsMeasurementDto> {
            return localVarFp.getReferencemeasurementsVesselidCampaign(vesselId, campaign, heat, measurementTaken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferenceMeasurementsApi - object-oriented interface
 * @export
 * @class ReferenceMeasurementsApi
 * @extends {BaseAPI}
 */
export class ReferenceMeasurementsApi extends BaseAPI {
    /**
     * Finds a the reference measurement for a vessel and a campaign.
     * @summary Finds a the reference measurement for a vessel and a campaign.
     * @param {string} vesselId Format - uuid. The id of the vessel.
     * @param {number} campaign Format - int32. The number of the campaign.
     * @param {number} [heat] Format - int32. The number of the heat, this is optional.
     * @param {string} [measurementTaken] Format - date-time (as date-time in RFC3339). The date time offset of the measurement when it was taken, this is optional.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceMeasurementsApi
     */
    public getReferencemeasurementsVesselidCampaign(vesselId: string, campaign: number, heat?: number, measurementTaken?: string, options?: AxiosRequestConfig) {
        return ReferenceMeasurementsApiFp(this.configuration).getReferencemeasurementsVesselidCampaign(vesselId, campaign, heat, measurementTaken, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary /Upload/cancel/{measurementId} - GET
         * @param {string} measurementId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadCancelMeasurementid: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('getUploadCancelMeasurementid', 'measurementId', measurementId)
            const localVarPath = `/Upload/cancel/{measurementId}`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /Upload/begin/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadBeginVesselid: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postUploadBeginVesselid', 'vesselId', vesselId)
            const localVarPath = `/Upload/begin/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /Upload/part/{measurementId} - POST
         * @param {string} measurementId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadPartMeasurementid: async (measurementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('postUploadPartMeasurementid', 'measurementId', measurementId)
            const localVarPath = `/Upload/part/{measurementId}`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /Upload/pts/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {number} [fileSize] 
         * @param {number} [chunkCount] 
         * @param {number} [chunkSize] 
         * @param {string} [requestUtc] 
         * @param {string} [referenceMeasurementId] 
         * @param {number} [campaign] 
         * @param {number} [heat] 
         * @param {string} [measurementTaken] 
         * @param {boolean} [viewOnly] 
         * @param {string} [vesselLining] 
         * @param {string} [liningCondition] 
         * @param {string} [liningMaintenanceType] 
         * @param {boolean} [hotMeasurement] 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadPtsVesselid: async (vesselId: string, file?: File, fileName?: string, fileSize?: number, chunkCount?: number, chunkSize?: number, requestUtc?: string, referenceMeasurementId?: string, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postUploadPtsVesselid', 'vesselId', vesselId)
            const localVarPath = `/Upload/pts/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (fileSize !== undefined) { 
                localVarFormParams.append('FileSize', fileSize as any);
            }
    
            if (chunkCount !== undefined) { 
                localVarFormParams.append('ChunkCount', chunkCount as any);
            }
    
            if (chunkSize !== undefined) { 
                localVarFormParams.append('ChunkSize', chunkSize as any);
            }
    
            if (requestUtc !== undefined) { 
                localVarFormParams.append('RequestUtc', requestUtc as any);
            }
    
            if (referenceMeasurementId !== undefined) { 
                localVarFormParams.append('ReferenceMeasurementId', referenceMeasurementId as any);
            }
    
            if (campaign !== undefined) { 
                localVarFormParams.append('Campaign', campaign as any);
            }
    
            if (heat !== undefined) { 
                localVarFormParams.append('Heat', heat as any);
            }
    
            if (measurementTaken !== undefined) { 
                localVarFormParams.append('MeasurementTaken', measurementTaken as any);
            }
    
            if (viewOnly !== undefined) { 
                localVarFormParams.append('ViewOnly', viewOnly as any);
            }
    
            if (vesselLining !== undefined) { 
                localVarFormParams.append('VesselLining', vesselLining as any);
            }
    
            if (liningCondition !== undefined) { 
                localVarFormParams.append('LiningCondition', liningCondition as any);
            }
    
            if (liningMaintenanceType !== undefined) { 
                localVarFormParams.append('LiningMaintenanceType', liningMaintenanceType as any);
            }
    
            if (hotMeasurement !== undefined) { 
                localVarFormParams.append('HotMeasurement', hotMeasurement as any);
            }
    
            if (notes !== undefined) { 
                localVarFormParams.append('Notes', notes as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary /Upload/cancel/{measurementId} - GET
         * @param {string} measurementId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadCancelMeasurementid(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsUploadPtsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadCancelMeasurementid(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /Upload/begin/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadBeginVesselid(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsUploadPtsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadBeginVesselid(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /Upload/part/{measurementId} - POST
         * @param {string} measurementId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadPartMeasurementid(measurementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsUploadPtsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadPartMeasurementid(measurementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /Upload/pts/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {number} [fileSize] 
         * @param {number} [chunkCount] 
         * @param {number} [chunkSize] 
         * @param {string} [requestUtc] 
         * @param {string} [referenceMeasurementId] 
         * @param {number} [campaign] 
         * @param {number} [heat] 
         * @param {string} [measurementTaken] 
         * @param {boolean} [viewOnly] 
         * @param {string} [vesselLining] 
         * @param {string} [liningCondition] 
         * @param {string} [liningMaintenanceType] 
         * @param {boolean} [hotMeasurement] 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploadPtsVesselid(vesselId: string, file?: File, fileName?: string, fileSize?: number, chunkCount?: number, chunkSize?: number, requestUtc?: string, referenceMeasurementId?: string, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsUploadPtsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploadPtsVesselid(vesselId, file, fileName, fileSize, chunkCount, chunkSize, requestUtc, referenceMeasurementId, campaign, heat, measurementTaken, viewOnly, vesselLining, liningCondition, liningMaintenanceType, hotMeasurement, notes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * 
         * @summary /Upload/cancel/{measurementId} - GET
         * @param {string} measurementId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadCancelMeasurementid(measurementId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsUploadPtsResponseDto> {
            return localVarFp.getUploadCancelMeasurementid(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /Upload/begin/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadBeginVesselid(vesselId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsUploadPtsResponseDto> {
            return localVarFp.postUploadBeginVesselid(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /Upload/part/{measurementId} - POST
         * @param {string} measurementId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadPartMeasurementid(measurementId: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsUploadPtsResponseDto> {
            return localVarFp.postUploadPartMeasurementid(measurementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /Upload/pts/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {File} [file] 
         * @param {string} [fileName] 
         * @param {number} [fileSize] 
         * @param {number} [chunkCount] 
         * @param {number} [chunkSize] 
         * @param {string} [requestUtc] 
         * @param {string} [referenceMeasurementId] 
         * @param {number} [campaign] 
         * @param {number} [heat] 
         * @param {string} [measurementTaken] 
         * @param {boolean} [viewOnly] 
         * @param {string} [vesselLining] 
         * @param {string} [liningCondition] 
         * @param {string} [liningMaintenanceType] 
         * @param {boolean} [hotMeasurement] 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploadPtsVesselid(vesselId: string, file?: File, fileName?: string, fileSize?: number, chunkCount?: number, chunkSize?: number, requestUtc?: string, referenceMeasurementId?: string, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsUploadPtsResponseDto> {
            return localVarFp.postUploadPtsVesselid(vesselId, file, fileName, fileSize, chunkCount, chunkSize, requestUtc, referenceMeasurementId, campaign, heat, measurementTaken, viewOnly, vesselLining, liningCondition, liningMaintenanceType, hotMeasurement, notes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 
     * @summary /Upload/cancel/{measurementId} - GET
     * @param {string} measurementId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public getUploadCancelMeasurementid(measurementId: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).getUploadCancelMeasurementid(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /Upload/begin/{vesselId} - POST
     * @param {string} vesselId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public postUploadBeginVesselid(vesselId: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).postUploadBeginVesselid(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /Upload/part/{measurementId} - POST
     * @param {string} measurementId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public postUploadPartMeasurementid(measurementId: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).postUploadPartMeasurementid(measurementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /Upload/pts/{vesselId} - POST
     * @param {string} vesselId Format - uuid.
     * @param {File} [file] 
     * @param {string} [fileName] 
     * @param {number} [fileSize] 
     * @param {number} [chunkCount] 
     * @param {number} [chunkSize] 
     * @param {string} [requestUtc] 
     * @param {string} [referenceMeasurementId] 
     * @param {number} [campaign] 
     * @param {number} [heat] 
     * @param {string} [measurementTaken] 
     * @param {boolean} [viewOnly] 
     * @param {string} [vesselLining] 
     * @param {string} [liningCondition] 
     * @param {string} [liningMaintenanceType] 
     * @param {boolean} [hotMeasurement] 
     * @param {string} [notes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public postUploadPtsVesselid(vesselId: string, file?: File, fileName?: string, fileSize?: number, chunkCount?: number, chunkSize?: number, requestUtc?: string, referenceMeasurementId?: string, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).postUploadPtsVesselid(vesselId, file, fileName, fileSize, chunkCount, chunkSize, requestUtc, referenceMeasurementId, campaign, heat, measurementTaken, viewOnly, vesselLining, liningCondition, liningMaintenanceType, hotMeasurement, notes, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ValidateApi - axios parameter creator
 * @export
 */
export const ValidateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Validate PTS file upload /validate/pts/{vesselId}
         * @summary Validate PTS file upload /validate/pts/{vesselId}
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {RHIMMeasurementServiceV1ModelsValidatePtsDto} [rHIMMeasurementServiceV1ModelsValidatePtsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postValidatePtsVesselid: async (vesselId: string, rHIMMeasurementServiceV1ModelsValidatePtsDto?: RHIMMeasurementServiceV1ModelsValidatePtsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postValidatePtsVesselid', 'vesselId', vesselId)
            const localVarPath = `/Validate/pts/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMMeasurementServiceV1ModelsValidatePtsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ValidateApi - functional programming interface
 * @export
 */
export const ValidateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ValidateApiAxiosParamCreator(configuration)
    return {
        /**
         * Validate PTS file upload /validate/pts/{vesselId}
         * @summary Validate PTS file upload /validate/pts/{vesselId}
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {RHIMMeasurementServiceV1ModelsValidatePtsDto} [rHIMMeasurementServiceV1ModelsValidatePtsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postValidatePtsVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsValidatePtsDto?: RHIMMeasurementServiceV1ModelsValidatePtsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMMeasurementServiceV1ModelsValidateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postValidatePtsVesselid(vesselId, rHIMMeasurementServiceV1ModelsValidatePtsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ValidateApi - factory interface
 * @export
 */
export const ValidateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ValidateApiFp(configuration)
    return {
        /**
         * Validate PTS file upload /validate/pts/{vesselId}
         * @summary Validate PTS file upload /validate/pts/{vesselId}
         * @param {string} vesselId Format - uuid. The id of the vessel.
         * @param {RHIMMeasurementServiceV1ModelsValidatePtsDto} [rHIMMeasurementServiceV1ModelsValidatePtsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postValidatePtsVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsValidatePtsDto?: RHIMMeasurementServiceV1ModelsValidatePtsDto, options?: any): AxiosPromise<RHIMMeasurementServiceV1ModelsValidateResponseDto> {
            return localVarFp.postValidatePtsVesselid(vesselId, rHIMMeasurementServiceV1ModelsValidatePtsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ValidateApi - object-oriented interface
 * @export
 * @class ValidateApi
 * @extends {BaseAPI}
 */
export class ValidateApi extends BaseAPI {
    /**
     * Validate PTS file upload /validate/pts/{vesselId}
     * @summary Validate PTS file upload /validate/pts/{vesselId}
     * @param {string} vesselId Format - uuid. The id of the vessel.
     * @param {RHIMMeasurementServiceV1ModelsValidatePtsDto} [rHIMMeasurementServiceV1ModelsValidatePtsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValidateApi
     */
    public postValidatePtsVesselid(vesselId: string, rHIMMeasurementServiceV1ModelsValidatePtsDto?: RHIMMeasurementServiceV1ModelsValidatePtsDto, options?: AxiosRequestConfig) {
        return ValidateApiFp(this.configuration).postValidatePtsVesselid(vesselId, rHIMMeasurementServiceV1ModelsValidatePtsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VertexUploadApi - axios parameter creator
 * @export
 */
export const VertexUploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary /VertexUpload/hyperion/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {File} [vertex] 
         * @param {File} [stl] 
         * @param {number} [campaign] 
         * @param {number} [heat] 
         * @param {string} [measurementTaken] 
         * @param {boolean} [viewOnly] 
         * @param {string} [vesselLining] 
         * @param {string} [liningCondition] 
         * @param {string} [liningMaintenanceType] 
         * @param {boolean} [hotMeasurement] 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVertexuploadHyperionVesselid: async (vesselId: string, vertex?: File, stl?: File, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postVertexuploadHyperionVesselid', 'vesselId', vesselId)
            const localVarPath = `/VertexUpload/hyperion/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (vertex !== undefined) { 
                localVarFormParams.append('vertex', vertex as any);
            }
    
            if (stl !== undefined) { 
                localVarFormParams.append('stl', stl as any);
            }
    
            if (campaign !== undefined) { 
                localVarFormParams.append('Campaign', campaign as any);
            }
    
            if (heat !== undefined) { 
                localVarFormParams.append('Heat', heat as any);
            }
    
            if (measurementTaken !== undefined) { 
                localVarFormParams.append('MeasurementTaken', measurementTaken as any);
            }
    
            if (viewOnly !== undefined) { 
                localVarFormParams.append('ViewOnly', viewOnly as any);
            }
    
            if (vesselLining !== undefined) { 
                localVarFormParams.append('VesselLining', vesselLining as any);
            }
    
            if (liningCondition !== undefined) { 
                localVarFormParams.append('LiningCondition', liningCondition as any);
            }
    
            if (liningMaintenanceType !== undefined) { 
                localVarFormParams.append('LiningMaintenanceType', liningMaintenanceType as any);
            }
    
            if (hotMeasurement !== undefined) { 
                localVarFormParams.append('HotMeasurement', hotMeasurement as any);
            }
    
            if (notes !== undefined) { 
                localVarFormParams.append('Notes', notes as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /VertexUpload/lcs/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {File} [vertex] 
         * @param {File} [stl] 
         * @param {number} [campaign] 
         * @param {number} [heat] 
         * @param {string} [measurementTaken] 
         * @param {boolean} [viewOnly] 
         * @param {string} [vesselLining] 
         * @param {string} [liningCondition] 
         * @param {string} [liningMaintenanceType] 
         * @param {boolean} [hotMeasurement] 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVertexuploadLcsVesselid: async (vesselId: string, vertex?: File, stl?: File, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postVertexuploadLcsVesselid', 'vesselId', vesselId)
            const localVarPath = `/VertexUpload/lcs/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (vertex !== undefined) { 
                localVarFormParams.append('vertex', vertex as any);
            }
    
            if (stl !== undefined) { 
                localVarFormParams.append('stl', stl as any);
            }
    
            if (campaign !== undefined) { 
                localVarFormParams.append('Campaign', campaign as any);
            }
    
            if (heat !== undefined) { 
                localVarFormParams.append('Heat', heat as any);
            }
    
            if (measurementTaken !== undefined) { 
                localVarFormParams.append('MeasurementTaken', measurementTaken as any);
            }
    
            if (viewOnly !== undefined) { 
                localVarFormParams.append('ViewOnly', viewOnly as any);
            }
    
            if (vesselLining !== undefined) { 
                localVarFormParams.append('VesselLining', vesselLining as any);
            }
    
            if (liningCondition !== undefined) { 
                localVarFormParams.append('LiningCondition', liningCondition as any);
            }
    
            if (liningMaintenanceType !== undefined) { 
                localVarFormParams.append('LiningMaintenanceType', liningMaintenanceType as any);
            }
    
            if (hotMeasurement !== undefined) { 
                localVarFormParams.append('HotMeasurement', hotMeasurement as any);
            }
    
            if (notes !== undefined) { 
                localVarFormParams.append('Notes', notes as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VertexUploadApi - functional programming interface
 * @export
 */
export const VertexUploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VertexUploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary /VertexUpload/hyperion/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {File} [vertex] 
         * @param {File} [stl] 
         * @param {number} [campaign] 
         * @param {number} [heat] 
         * @param {string} [measurementTaken] 
         * @param {boolean} [viewOnly] 
         * @param {string} [vesselLining] 
         * @param {string} [liningCondition] 
         * @param {string} [liningMaintenanceType] 
         * @param {boolean} [hotMeasurement] 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postVertexuploadHyperionVesselid(vesselId: string, vertex?: File, stl?: File, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postVertexuploadHyperionVesselid(vesselId, vertex, stl, campaign, heat, measurementTaken, viewOnly, vesselLining, liningCondition, liningMaintenanceType, hotMeasurement, notes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /VertexUpload/lcs/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {File} [vertex] 
         * @param {File} [stl] 
         * @param {number} [campaign] 
         * @param {number} [heat] 
         * @param {string} [measurementTaken] 
         * @param {boolean} [viewOnly] 
         * @param {string} [vesselLining] 
         * @param {string} [liningCondition] 
         * @param {string} [liningMaintenanceType] 
         * @param {boolean} [hotMeasurement] 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postVertexuploadLcsVesselid(vesselId: string, vertex?: File, stl?: File, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postVertexuploadLcsVesselid(vesselId, vertex, stl, campaign, heat, measurementTaken, viewOnly, vesselLining, liningCondition, liningMaintenanceType, hotMeasurement, notes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VertexUploadApi - factory interface
 * @export
 */
export const VertexUploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VertexUploadApiFp(configuration)
    return {
        /**
         * 
         * @summary /VertexUpload/hyperion/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {File} [vertex] 
         * @param {File} [stl] 
         * @param {number} [campaign] 
         * @param {number} [heat] 
         * @param {string} [measurementTaken] 
         * @param {boolean} [viewOnly] 
         * @param {string} [vesselLining] 
         * @param {string} [liningCondition] 
         * @param {string} [liningMaintenanceType] 
         * @param {boolean} [hotMeasurement] 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVertexuploadHyperionVesselid(vesselId: string, vertex?: File, stl?: File, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options?: any): AxiosPromise<void> {
            return localVarFp.postVertexuploadHyperionVesselid(vesselId, vertex, stl, campaign, heat, measurementTaken, viewOnly, vesselLining, liningCondition, liningMaintenanceType, hotMeasurement, notes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /VertexUpload/lcs/{vesselId} - POST
         * @param {string} vesselId Format - uuid.
         * @param {File} [vertex] 
         * @param {File} [stl] 
         * @param {number} [campaign] 
         * @param {number} [heat] 
         * @param {string} [measurementTaken] 
         * @param {boolean} [viewOnly] 
         * @param {string} [vesselLining] 
         * @param {string} [liningCondition] 
         * @param {string} [liningMaintenanceType] 
         * @param {boolean} [hotMeasurement] 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVertexuploadLcsVesselid(vesselId: string, vertex?: File, stl?: File, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options?: any): AxiosPromise<void> {
            return localVarFp.postVertexuploadLcsVesselid(vesselId, vertex, stl, campaign, heat, measurementTaken, viewOnly, vesselLining, liningCondition, liningMaintenanceType, hotMeasurement, notes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VertexUploadApi - object-oriented interface
 * @export
 * @class VertexUploadApi
 * @extends {BaseAPI}
 */
export class VertexUploadApi extends BaseAPI {
    /**
     * 
     * @summary /VertexUpload/hyperion/{vesselId} - POST
     * @param {string} vesselId Format - uuid.
     * @param {File} [vertex] 
     * @param {File} [stl] 
     * @param {number} [campaign] 
     * @param {number} [heat] 
     * @param {string} [measurementTaken] 
     * @param {boolean} [viewOnly] 
     * @param {string} [vesselLining] 
     * @param {string} [liningCondition] 
     * @param {string} [liningMaintenanceType] 
     * @param {boolean} [hotMeasurement] 
     * @param {string} [notes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VertexUploadApi
     */
    public postVertexuploadHyperionVesselid(vesselId: string, vertex?: File, stl?: File, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options?: AxiosRequestConfig) {
        return VertexUploadApiFp(this.configuration).postVertexuploadHyperionVesselid(vesselId, vertex, stl, campaign, heat, measurementTaken, viewOnly, vesselLining, liningCondition, liningMaintenanceType, hotMeasurement, notes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /VertexUpload/lcs/{vesselId} - POST
     * @param {string} vesselId Format - uuid.
     * @param {File} [vertex] 
     * @param {File} [stl] 
     * @param {number} [campaign] 
     * @param {number} [heat] 
     * @param {string} [measurementTaken] 
     * @param {boolean} [viewOnly] 
     * @param {string} [vesselLining] 
     * @param {string} [liningCondition] 
     * @param {string} [liningMaintenanceType] 
     * @param {boolean} [hotMeasurement] 
     * @param {string} [notes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VertexUploadApi
     */
    public postVertexuploadLcsVesselid(vesselId: string, vertex?: File, stl?: File, campaign?: number, heat?: number, measurementTaken?: string, viewOnly?: boolean, vesselLining?: string, liningCondition?: string, liningMaintenanceType?: string, hotMeasurement?: boolean, notes?: string, options?: AxiosRequestConfig) {
        return VertexUploadApiFp(this.configuration).postVertexuploadLcsVesselid(vesselId, vertex, stl, campaign, heat, measurementTaken, viewOnly, vesselLining, liningCondition, liningMaintenanceType, hotMeasurement, notes, options).then((request) => request(this.axios, this.basePath));
    }
}



