import { useMemoCompare } from '@rhim/react';
import { hasElements, head, isDefined } from '@rhim/utils';
import { join } from 'lodash';
import React from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { LOCAL_STORAGE_PREFIX_APO } from '../utilities/storage';

const logger = log.child('useSelectedCampaign');

logger.mute();

function useSelectedCampaignId(initialState: number) {
  return useLocalStorageState(join([LOCAL_STORAGE_PREFIX_APO, 'selectedCampaignId'], ''), {
    defaultValue: initialState,
  });
}

export const CAMPAIGN_ID_NOT_SET_ID = -1;
export const CAMPAIGN_UNASSIGNED_ID = -2;

export function useSelectedCampaign(campaigns: RHIM.CampaignOrUnassigned[], initialValue?: number): [number, React.Dispatch<React.SetStateAction<number>>] {
  const [persistedCampaignId, setPersistedCampaignId] = useSelectedCampaignId(initialValue ?? CAMPAIGN_ID_NOT_SET_ID);

  // We are not trusting the persisted campaign, because it may not belong to the list of available campaigns.
  // In that case, we fall back to the first campaign available.
  // Otherwise we fall back to CAMPAIGN_ID_NOT_SET_ID.
  const campaignId =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    campaigns.find((campaign) => campaign.id === persistedCampaignId)?.id ?? (hasElements(campaigns) ? head(campaigns).id! : CAMPAIGN_ID_NOT_SET_ID);

  logger.debug(`Launched campaign id persistence. Initial value: ${persistedCampaignId}`);
  React.useDebugValue(persistedCampaignId);
  React.useEffect(() => {
    if (!isDefined(campaigns)) {
      logger.debug('⛔️ No campaigns available. Removing the persisted campaign id from local storage.');

      setPersistedCampaignId(CAMPAIGN_ID_NOT_SET_ID);
      return;
    }
    const persistedCampaign = campaigns.find((campaign) => campaign.id === persistedCampaignId);
    const isPersistedCampaignIdValid = isDefined(persistedCampaign);

    if (isPersistedCampaignIdValid) {
      logger.debug(
        `✅ Persisted campaign id DOES belong to one of the availabe campaigns. Returning the campaign id found in local storage: ${persistedCampaign.id}. All campaigns:`,
        campaigns.map((campaign) => campaign.id)
      );

      return;
    } else if (hasElements(campaigns)) {
      const firstAvailableCampaign = head(campaigns);
      logger.debug(
        `⚠️ Persisted campaign id DOES NOT belong to one of the available campaigns. Returning the first campaign id available : ${firstAvailableCampaign.id}. All campaigns:`,
        campaigns.map((campaign) => campaign.id)
      );

      setPersistedCampaignId(firstAvailableCampaign.id ?? CAMPAIGN_ID_NOT_SET_ID);
    } else {
      logger.debug('⛔️ No campaigns available. Removing the persisted campaign id from local storage.');

      setPersistedCampaignId(CAMPAIGN_ID_NOT_SET_ID);
    }
  }, [persistedCampaignId, setPersistedCampaignId, campaigns]);

  const memoizedValue: [number, React.Dispatch<React.SetStateAction<number>>] = useMemoCompare(
    [campaignId, setPersistedCampaignId],
    ([previousId], [nextId]) => {
      return previousId === nextId;
    }
  );

  return memoizedValue;
}
