import { settings } from '@rhim/design';
import { i18nReact, SupportedLanguageIsoCode } from '@rhim/i18n';
import { CampaignIcon, HeatIcon, LiningIcon, MeasurementIcon } from '@rhim/icons/16';
import { OpsStateCriticalIcon } from '@rhim/icons/24';
import { HexagonBOFIcon, HexagonEAFIcon, HexagonLadleIcon, HexagonRHIcon } from '@rhim/icons/32';
import { CircularProgress, FormattedDate, FormattedLength, IconLabel, MetricLengthUnit, Tooltip } from '@rhim/react';
import { VesselType } from '@rhim/rest';
import {
  circularProgressContainerFleetOverview,
  liningThicknessBarContainerFleetOverview,
  vesselCardContainersFleetOverview,
  vesselCardNameLabelsFleetOverview,
} from '@rhim/test-ids';
import { assert, ColorScales, convertDuration, differenceFromNowInMinutes, getColorUsingScale, isDefined } from '@rhim/utils';
import React, { useContext, useMemo } from 'react';
import { useHover, usePress } from 'react-aria';
import { useNavigate } from 'react-router-dom';
import shortHash from 'shorthash2';
import styled, { css } from 'styled-components';
import { UnitSystem } from 'typings';

import AppContext from '../../app/AppContext';
import { PARAMS } from '../../lib';
import { ROUTES } from '../../utilities';
import LiningThicknessBar, { CRITICAL_THRESHOLD_VALUE } from './LiningThicknessBar';
import { getTimeAsText } from './utils';

const CRITICAL_ELAPSED_TIME_SINCE_LAST_MEASUREMENT_MINUTES = 7 * 24 * 60; // one week in minutes
const LADLE_NAME_MAX_DISPLAYABLE_CHARACTER_COUNT = 10;
const LADLE_NAME_ELLIPSIS = '...';

export interface CombinedVessel extends APO.VesselV2 {
  latestMeasurementId: string | null;
  latestMeasurementTakenOn?: Date.ISO_8601 | null;
  latestMeasurementHeat: number | null;
  latestMeasurementCampaign: number | null;
  minBrickLength?: number | null;
  maxBrickLength?: number | null;
}

interface Props {
  locale: SupportedLanguageIsoCode;
  unitSystem: UnitSystem;
  vessel: CombinedVessel;
  colorScales: ColorScales;
}
const VesselCard: React.ChildlessComponent<Props> = (props) => {
  const { locale, vessel, colorScales, unitSystem } = props;
  const navigate = useNavigate();
  const { t } = i18nReact.useTranslation('fleet-overview');
  const { selectedCustomer } = useContext(AppContext);
  const doesVesselHaveMeasurement = isDefined(vessel.latestMeasurementId);
  const { hoverProps, isHovered } = useHover({ isDisabled: !doesVesselHaveMeasurement });
  const { pressProps, isPressed } = usePress({
    onPress: () => {
      if (!doesVesselHaveMeasurement) {
        return;
      }
      assert(isDefined(selectedCustomer.customerId), 'Selected customer id not set');
      assert(isDefined(vessel.latestMeasurementCampaign), 'Vessel has no latestMeasurementCampaign set');
      assert(isDefined(vessel.latestMeasurementId), 'Vessel has no latestMeasurementId set');
      const path = `${ROUTES.MEASUREMENT_VIEW}?${PARAMS.CUSTOMER_ID}=${shortHash(selectedCustomer.customerId)}&${PARAMS.VESSEL_ID}=${vessel.id}&${
        PARAMS.CAMPAIGN_ID
      }=${vessel.latestMeasurementCampaign}&${PARAMS.MEASUREMENT_ID}=${vessel.latestMeasurementId}`;
      navigate(path);
    },
  });

  let timeSinceLastMeasurementNumber,
    timeSinceLastMeasurementText,
    tooltipLastMeasurementCreatedOn,
    percentProgressToCriticalElapsedTimeSinceLastMeasurement = 0;
  if (isDefined(vessel.latestMeasurementTakenOn)) {
    const minutesSinceLastMeasurement = differenceFromNowInMinutes(vessel.latestMeasurementTakenOn as Date.ISO_8601);
    timeSinceLastMeasurementNumber = convertDuration(minutesSinceLastMeasurement);
    timeSinceLastMeasurementText = getTimeAsText(t, minutesSinceLastMeasurement);
    percentProgressToCriticalElapsedTimeSinceLastMeasurement = Math.min(
      100,
      (minutesSinceLastMeasurement * 100) / CRITICAL_ELAPSED_TIME_SINCE_LAST_MEASUREMENT_MINUTES
    );
    tooltipLastMeasurementCreatedOn = (() => {
      return (
        <SLastMeasurementCreatedOnTooltipWrapper>
          <span>{t('vesselCard.tooltips.circularProgressIndicator.lastMeasurement', { ns: 'fleet-overview' })}</span>
          <span>
            {t('vesselCard.tooltips.circularProgressIndicator.on', { ns: 'fleet-overview' })} <FormattedDate datetime={vessel.latestMeasurementTakenOn} />
          </span>
        </SLastMeasurementCreatedOnTooltipWrapper>
      );
    })();
  }

  const vesselIcon = useMemo(() => {
    switch (vessel.vesselType) {
      case VesselType.Ladle:
        return <HexagonLadleIcon />;
      case VesselType.Rh:
        return <HexagonRHIcon />;
      case VesselType.Bof:
        return <HexagonBOFIcon />;
      case VesselType.Eaf:
        return <HexagonEAFIcon />;
      default:
        throw new Error(`Icon for vessel type : ${vessel.vesselType} is unavailable`);
    }
  }, [vessel.vesselType]);

  const liningThicknessTooltip: JSX.Element | null = useMemo(() => {
    if (!isDefined(vessel.minBrickLength) || !isDefined(vessel.maxBrickLength)) {
      return null;
    }
    return (
      <>
        <SLiningThicknessTooltipHeading>{t('vesselCard.tooltips.liningThicknessBar.heading', { ns: 'fleet-overview' })}</SLiningThicknessTooltipHeading>
        <table>
          <tbody>
            <tr>
              <SLiningThicknessTooltipPropertyLabel>
                {t('vesselCard.tooltips.liningThicknessBar.minimum', { ns: 'fleet-overview' })}
              </SLiningThicknessTooltipPropertyLabel>
              <td>
                <FormattedLength
                  locale={locale}
                  lengthValue={vessel.minBrickLength}
                  sourceUnit={MetricLengthUnit.mm}
                  targetUnitSystem={unitSystem}
                  maximumFractionDigits={3}
                />
              </td>
              <td>
                <SLiningThicknessTooltipMinimumValueWrapper>
                  <SLiningThicknessTooltipMinimumValueColoredBox fill={getColorUsingScale(vessel.minBrickLength, colorScales)} />
                  {vessel.minBrickLength < CRITICAL_THRESHOLD_VALUE && (
                    <SLiningThicknessTooltipMinimumValueContainer>
                      <OpsStateCriticalIcon />
                      <span>
                        {t('vesselCard.tooltips.liningThicknessBar.criticalValue', { ns: 'fleet-overview' })} (
                        {t('vesselCard.tooltips.liningThicknessBar.lessThan', { ns: 'fleet-overview' })} {CRITICAL_THRESHOLD_VALUE})
                      </span>
                    </SLiningThicknessTooltipMinimumValueContainer>
                  )}
                </SLiningThicknessTooltipMinimumValueWrapper>
              </td>
            </tr>
            <tr>
              <td>{t('vesselCard.tooltips.liningThicknessBar.maximum', { ns: 'fleet-overview' })}</td>
              <td>
                <FormattedLength
                  locale={locale}
                  lengthValue={vessel.maxBrickLength}
                  sourceUnit={MetricLengthUnit.mm}
                  targetUnitSystem={unitSystem}
                  maximumFractionDigits={3}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }, [t, colorScales, vessel.minBrickLength, vessel.maxBrickLength, unitSystem, locale]);

  // Limit the vessel-name string character count we display or it may not fit in the ladle-card
  const vesselName =
    vessel.displayName.length > LADLE_NAME_MAX_DISPLAYABLE_CHARACTER_COUNT
      ? `${vessel.displayName.substring(0, LADLE_NAME_MAX_DISPLAYABLE_CHARACTER_COUNT - LADLE_NAME_ELLIPSIS.length)}${LADLE_NAME_ELLIPSIS}`
      : vessel.displayName;

  return (
    <SWrapper
      data-test-id={vesselCardContainersFleetOverview}
      {...hoverProps}
      {...pressProps}
      isEnabled={doesVesselHaveMeasurement}
      isHovered={isHovered}
      isPressed={isPressed}
    >
      {/* HEADER ( vessel-icon & vessel-number ) */}
      <SHeaderContainer>
        <SLeftSideIconContainer>{vesselIcon}</SLeftSideIconContainer>
        <Tooltip title={vessel.displayName} placement="top">
          <SVesselNumber data-test-id={vesselCardNameLabelsFleetOverview}>{vesselName}</SVesselNumber>
        </Tooltip>
      </SHeaderContainer>
      <SBody>
        {doesVesselHaveMeasurement ? (
          <>
            {/* CIRCULAR MEASUREMENT ELAPSED TIME */}
            {isDefined(vessel.latestMeasurementTakenOn) && (
              <SCircularProgressContainer data-test-id={circularProgressContainerFleetOverview}>
                <SLeftSideIconContainer>
                  <MeasurementIcon fill={settings.colors.Primary.Grey_6} />
                </SLeftSideIconContainer>
                <CircularProgress size={64} strokeWidth={6} isProgressLaneShowing={true} progress={percentProgressToCriticalElapsedTimeSinceLastMeasurement} />
                <SCircularProgressTextContainer isActive={true} isCritical={false}>
                  <Tooltip title={tooltipLastMeasurementCreatedOn} placement="bottom">
                    <SCircularProgressTextWrapper>
                      <span>{timeSinceLastMeasurementNumber}</span>
                      <span>{timeSinceLastMeasurementText}</span>
                      <span>{t('vesselCard.circularProgressIndicator.ago', { ns: 'fleet-overview' })}</span>
                    </SCircularProgressTextWrapper>
                  </Tooltip>
                </SCircularProgressTextContainer>
              </SCircularProgressContainer>
            )}
            <SVerticalSpring />
            {/* REMAINING LINING THICKNESS BAR */}
            {isDefined(vessel.minBrickLength) && isDefined(vessel.maxBrickLength) ? (
              <SLiningThicknessBarContainer data-test-id={liningThicknessBarContainerFleetOverview}>
                <SThicknessBarContainer>
                  <SLeftSideIconContainer>
                    <LiningIcon fill={settings.colors.Primary.Grey_6} />
                  </SLeftSideIconContainer>
                  <Tooltip title={liningThicknessTooltip} placement="bottom" overlayStyle={{ maxWidth: 'unset' }}>
                    <LiningThicknessBar colorScales={colorScales} minValue={vessel.minBrickLength} maxValue={vessel.maxBrickLength} />
                  </Tooltip>
                </SThicknessBarContainer>
                <StyledLiningThicknessLabel isActive={true}>
                  <span>{t('vesselCard.liningThicknessBar.min', { ns: 'fleet-overview' })}</span>{' '}
                  <FormattedLength
                    locale={locale}
                    lengthValue={vessel.minBrickLength}
                    sourceUnit={MetricLengthUnit.mm}
                    targetUnitSystem={unitSystem}
                    maximumFractionDigits={3}
                  />
                </StyledLiningThicknessLabel>
              </SLiningThicknessBarContainer>
            ) : (
              <SNoRBLAvailableLabelContainer>
                <SNoRBLAvailableLabel>{t('vesselCard.noRemainingBrickLengthAvailable', { ns: 'fleet-overview' })}</SNoRBLAvailableLabel>
              </SNoRBLAvailableLabelContainer>
            )}
          </>
        ) : (
          <SNoMeasurementsYetContainer>
            <SNoMeasurementsYet>{t('vesselCard.noMeasurementsYet', { ns: 'fleet-overview' })}</SNoMeasurementsYet>
          </SNoMeasurementsYetContainer>
        )}
      </SBody>
      {/* FOOTER (Campaign-number & latest-heat) */}
      <SFooter>
        {isDefined(vessel.latestMeasurementCampaign) && isDefined(vessel.latestMeasurementHeat) && (
          <>
            <SFooterIconLabel
              label={vessel.latestMeasurementCampaign}
              color={settings.colors.Primary.Grey_7}
              tooltip={t('vesselCard.tooltips.campaignNumber', { ns: 'fleet-overview' })}
              tooltipPlacement="bottom"
              icon={<CampaignIcon fill={settings.colors.Primary.Grey_7} />}
            />
            <SFooterIconLabel
              label={vessel.latestMeasurementHeat}
              color={settings.colors.Primary.Grey_7}
              tooltip={t('vesselCard.tooltips.lastRecordedHeat', { ns: 'fleet-overview' })}
              tooltipPlacement="bottom"
              icon={<HeatIcon fill={settings.colors.Primary.Grey_7} />}
            />
          </>
        )}
      </SFooter>
    </SWrapper>
  );
};
export default React.memo(VesselCard);

const SWrapper = styled.div<{ isEnabled: boolean; isHovered: boolean; isPressed: boolean }>((props) => {
  const cursor: React.CSSProperties['cursor'] = props.isEnabled ? 'pointer' : 'default';
  const boxShadow: React.CSSProperties['boxShadow'] =
    props.isEnabled && props.isPressed ? 'none' : `0 2px 16px 0 rgba(0, 0, 0, ${props.isHovered ? 0.2 : 0.05});`;

  return css`
    cursor: ${cursor};
    width: 200px;
    background-color: ${settings.colors.Monochromatic.White};
    border-width: 1px;
    border-style: solid;
    border-color: ${settings.colors.Primary.Grey_3};
    border-radius: 6px;
    box-shadow: ${boxShadow};
    transition: box-shadow 0.2s ease-out;
    position: relative;
  `;
});

const SHeaderContainer = styled.div`
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${settings.colors.Primary.Grey_2};
`;

const SVesselNumber = styled.span`
  line-height: 31px;
  max-width: 95px;
  word-break: break-all;
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.Large};
  color: ${settings.colors.Primary.Grey_8};
`;

const SBody = styled.div`
  height: 168px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${settings.colors.Primary.Grey_2};
`;

const SCircularProgressContainer = styled.div`
  margin-top: ${settings.Spacing.Spacing_200};
  position: relative;
  display: flex;
  justify-content: center;
  align-self: stretch;
`;

const SLeftSideIconContainer = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${settings.Spacing.Spacing_150};
`;

const SCircularProgressTextContainer = styled.div<{ isActive: boolean; isCritical: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${(props) => {
    if (props.isActive) {
      return props.isCritical ? settings.colors.Monochromatic.Black : settings.colors.Primary.Grey_8;
    } else {
      return settings.colors.Primary.Grey_6;
    }
  }};
  transition: color 1s ease-out;
  z-index: 1;
`;

const SCircularProgressTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: ${settings.typography.FontSize.X_Small};
    line-height: 14px;
    font-family: ${settings.typography.FontFamily.Bold};

    &:last-of-type {
      font-family: ${settings.typography.FontFamily.Regular};
    }
  }
`;

const SLiningThicknessBarContainer = styled.div`
  position: relative;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${settings.Spacing.Spacing_200};
`;

const SThicknessBarContainer = styled.div`
  position: relative;
  align-self: stretch;
  display: flex;
  justify-content: center;
`;

const StyledLiningThicknessLabel = styled.div<{ isActive: boolean }>`
  margin-top: 6px;
  align-self: center;
  font-size: ${settings.typography.FontSize.X_Small};

  span:nth-of-type(1) {
    color: ${(props) => (props.isActive ? settings.colors.Primary.Grey_6 : settings.colors.Primary.Grey_4)};
  }

  span:nth-of-type(2) {
    color: ${(props) => (props.isActive ? settings.colors.Primary.Grey_8 : settings.colors.Primary.Grey_4)};
    font-family: ${settings.typography.FontFamily.Bold};
  }
`;

const SFooter = styled.div`
  height: 32px;
  margin: 0 ${settings.Spacing.Spacing_150};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SNoMeasurementsYetContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SNoMeasurementsYet = styled.span`
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_7};
`;

const SLiningThicknessTooltipHeading = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
`;

const SLiningThicknessTooltipMinimumValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SLiningThicknessTooltipPropertyLabel = styled.td`
  padding-right: ${settings.Spacing.Spacing_150};
`;

const SLiningThicknessTooltipMinimumValueColoredBox = styled.div<{ fill: string }>`
  margin-left: ${settings.Spacing.Spacing_100};

  --size: 16px;

  width: var(--size);
  height: var(--size);
  background-color: ${(props) => props.fill};
`;

const SLiningThicknessTooltipMinimumValueContainer = styled.div`
  margin-left: ${settings.Spacing.Spacing_150};
  display: flex;
  align-items: center;
`;

const SLastMeasurementCreatedOnTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SFooterIconLabel = styled(IconLabel)`
  flex-basis: 0;
`;

const SVerticalSpring = styled.span`
  flex-grow: 1;
`;

const SNoRBLAvailableLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${settings.Spacing.Spacing_200};
`;

const SNoRBLAvailableLabel = styled.span`
  text-align: center;
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_6};
`;
