/**
 * Please follow the naming convention: <name of Element> <type of Element> <location of Element>
 * e.g. customersDropdownAppBar
 */
//Navigation Bar
export const chevronBackLeftIconNavigationBar = 'chevronBackLeftIcon';
export const customerNameDropdownNavigationBar = 'customerNameDropdown';
export const detailsLabelNavigationBar = 'detailsNavigationLabel';
export const vesselSettingsDropdownNavigationBar = 'vesselSettingsDropdown';
export const vesselNameDropdownNavigationBar = 'vesselNameDropdown';
export const harmonizationDropdownNavigationBar = 'harmonizationDropdown';
export const filetypeDropdownNavigationBar = 'filetypeDropdown';

//Customers and Fleets Overview
export const customersTableCustomersFleetsOverview = 'customersTableCustomersFleetsOverview';
export const customersFleetsLayoutCustomersFleetsOverview = 'customersFleetsLayoutCustomers';

//Customer Overview
export const backArrowButtonCustomerOverview = 'backArrow';
export const customerInfoContainerCustomerOverview = 'customerInfoContainer';
export const customerDetailsLabelCustomerOverview = 'customerDetails';
export const contactPersonsLabelCustomerOverview = 'contactPersons';
export const featuresLabelCustomerOverview = 'features';
export const cronExpressionsLabelCustomerOverview = 'cronExpressions';
export const transferStorageLabelCustomerOverview = 'transferStorage';
export const chevronRightButtonCustomerOverview = 'chevronRightIcon';
export const userManagementContainerCustomerOverview = 'userManagementContainer';
export const vesselOverviewLabelCustomerOverview = 'vesselOverviewLabel';
export const copyVesselIdButtonCustomerOverview = 'copyVesselId';
export const deleteVesselButtonCustomerOverview = 'deleteVessel';
export const chevronRightVesselButtonCustomerOverview = 'chevronRightVessel';
export const vesselTypeDropdownCreateVesselModalCustomerOverview = 'vesselTypeDropdownCreateVesselModalCustomerOverview';

//Customer Details
export const customerDetailsTitleLabelCustomerOverview = 'customerDetailsTitle';
export const editCustomerButtonCustomerDetails = 'editCustomerButton';
export const cityLabelCustomerDetails = 'customerDetailsCity';
export const latitudeInformationLabelCustomerDetails = 'customerDetailsLatitude';
export const longitudeInformationLabelCustomerDetails = 'customerDetailsLongitude';
export const transferStorageLabelCustomerDetails = 'customerDetailsTransferStorage';
export const contactPersonsHeaderCustomerDetails = 'contactPersonsHeader';
export const manageContactPersonsButtonCustomerDetails = 'manageContactPersonsButton';
export const contactPersonsTableCustomerDetails = 'contactPersonsTable';
export const featureSwitchCustomerDetails = 'featureSwitch';
export const createCronExpressionButtonCustomerDetails = 'createCronExpression';
export const cronExpressionTableCustomerDetails = 'cronExpressionTable';
export const editCronExpressionButtonCustomerDetails = 'editCronExpression';
export const deleteCronExpressionButtonCustomerDetails = 'deleteCronExpression';
export const featuresTableCustomerDetails = 'featuresTableCustomerDetails';
export const newCustomerFiletypeButtonCustomerDetails = 'newCustomerFiletypeButtonCustomerDetails';
export const customerFiletypeTableCustomerDetails = 'customerFiletypeTableCustomerDetails';
export const chevronRightNavigationButtonCustomerDetails = 'chevronRightNavigationButtonCustomerDetails';
export const deleteCustomerFiletypeButtonCustomerDetails = 'deleteCustomerFiletypeButtonCustomerDetails';

//Manage Vessel Type
export const mlConfigurationHeaderLabelManageVesselType = 'mlConfigurationHeader';
export const editMLConfigurationButtonManageVesselType = 'editMLConfiguration';
export const deleteMLConfigurationButtonManageVesselType = 'deleteMLConfiguration';
export const hostingModeLabelManageVesselType = 'hostingModeLabel';
export const hostingModeValueLabelManageVesselType = 'hostingModeValue';
export const targetCustomerLabelManageVesselType = 'targetCustomerLabel';
export const targetCustomerValueLabelManageVesselType = 'targetCustomerValue';
export const colorScaleSectionManageVesselType = 'colorScaleSection';
export const colorScaleTypeDropdownManageVesselType = 'colorScaleTypeDropdown';
export const amountOfColorSetsLabelManageVesselType = 'amountOfColorSets';
export const addColorSetButtonManageVesselType = 'addColorSetButton';
export const saveChangesForColorScalesButtonManageVesselType = 'saveChangesForColorScalesButton';
export const colorScalesTableManageVesselType = 'colorScalesTable';
export const deleteColorSetButtonManageVesselType = 'deleteColorSet';
export const amountOfDisplayMarkersLabelManageVesselType = 'amountOfDisplayMarkers';
export const addDisplayMarkerButtonManageVesselType = 'addDisplayMarkerButton';
export const saveChangesForDisplayMarkerButtonManageVesselType = 'saveChangesForDisplayMarkerButton';
export const displayMarkerTableManageVesselType = 'displayMarkerTable';
export const deleteDisplayMarkerButtonManageVesselType = 'deleteDisplayMarker';

//Vessel Details
export const vesselSettingsHeaderLabelVesselDetails = 'vesselSettingsHeader';
export const editVesselSettingsButtonVesselDetails = 'editVesselSettings';
export const vesselBasicInfoFormVesselDetails = 'vesselBasicInfoForm';
export const vesselMeasurementViewFormDetails = 'vesselMeasurementViewForm';
export const vesselTypeDropdownVesselDetails = 'vesselTypeDropdown';
export const vesselShortNameInputVesselDetails = 'vesselShortNameInput';
export const vesselDisplayNameInputVesselDetails = 'vesselDisplayNameInput';
export const vesselExpectedHeatsInputVesselDetails = 'vesselExpectedHeatsInput';
export const vesselOuterHeightInputVesselDetails = 'vesselOuterHeightInput';
export const vesselOuterLengthInputVesselDetails = 'vesselOuterLengthInput';
export const vesselOuterWidthInputVesselDetails = 'vesselOuterWidthInput';
export const vesselOuterDiameterInputVesselDetails = 'vesselOuterDiameterInput';
export const amountOfLiningsLabelVesselDetails = 'amountOfLinings';
export const addLiningButtonVesselDetails = 'addLiningsButton';
export const liningsTableVesselDetails = 'liningsTableVesselDetails';
export const deleteLiningButtonVesselDetails = 'deleteLiningButton';
export const chevronRightForLiningDetailsButtonVesselDetails = 'chevronRightForLiningDetailsButton';
export const vesselFeaturesMenuPanelVesselDetails = 'vesselFeaturesMenuPanel';
export const coneMenuItemLabelVesselDetails = 'coneMenuItem';
export const addNewConeFeatureButtonVesselDetails = 'addNewConeFeatureButton';
export const barrelMenuItemLabelVesselDetails = 'barrelMenuItem';
export const addNewBarrelFeatureButtonVesselDetails = 'addNewBarrelFeatureButton';
export const bottomMenuItemLabelVesselDetails = 'bottomMenuItem';
export const addNewBottomFeatureButtonVesselDetails = 'addNewBottomFeatureButton';
export const vesselFeatureItemVesselDetails = 'vesselFeatureItem';
export const featureDefinitionSectionVesselDetails = 'featureDefinitionSection';
export const editVesselFeatureButtonVesselDetails = 'editVesselFeature';
export const duplicateVesselFeatureButtonVesselDetails = 'duplicateVesselFeature';
export const deleteVesselFeatureButtonVesselDetails = 'deleteVesselFeature';
export const featureDefinitionFormVesselDetails = 'featureDefinitionForm';
export const featureTypeDropdownVesselDetails = 'featureTypDropdown';
export const positionsSectionTitleLabelVesselDetails = 'positionsSectionTitle';
export const cylinderSectionTitleLabelVesselDetails = 'cylinderSectionTitle';

//Lining Details
export const liningDetailsHeaderContainerLiningDetails = 'liningDetailsHeaderContainer';
export const editLiningButtonLiningDetails = 'liningDetailsEditButton';
export const newLiningRegionButtonLiningDetails = 'newLiningRegion';
export const liningRegionItemsLiningDetails = 'liningRegionItems';
export const liningRegionItemLiningDetails = 'liningRegionItem';
export const liningRegionSidebarLiningDetails = 'liningRegionSidebar';
export const grabLiningRegionIconLiningDetails = 'grabLiningRegionIcon';
export const liningRegionDetailsSectionLiningDetails = 'liningRegionDetails';
export const liningRegionTabsLiningDetails = 'liningRegionTabs';
export const liningRegionFormLiningDetails = 'liningRegionForm';
export const editLiningRegionButtonLiningDetails = 'editLiningRegion';
export const deleteLiningRegionButtonLiningDetails = 'deleteLiningRegion';
export const liningRegionMapHeaderLiningDetails = 'liningRegionMapTitle';
export const liningRegionMinimapLiningDetails = 'liningRegionMinimap';
export const liningRegionMapLiningDetails = 'liningRegionsMap';
export const regionMapDetails = 'regionsMap';
export const rightAxisLabelsLiningDetails = 'rightAxisLabels';
export const rightRegionMapSectionLiningDetails = 'rightRegionMapSection';
export const leftRegionMapSectionLiningDetails = 'leftRegionMapSection';

//Regions
export const newRegionButtonRegions = 'newRegionButton';
export const regionItemRegions = 'regionItem';
export const regionSidebarRegions = 'regionSidebar';
export const regionTabsRegions = 'regionTabs';
export const editRegionButtonRegions = 'editRegion';
export const duplicateRegionButtonRegions = 'duplicateRegion';
export const deleteRegionButtonRegions = 'deleteRegion';
export const regionDefinitionFormRegions = 'regionDefinitionForm';
export const usedForTagValueRegions = 'usedForTagValue';
export const regionShapeDropdownRegions = 'regionShapeDropdown';
export const regionMapHeaderRegions = 'regionMapTitle';
export const thresholdCurvesFormRegions = 'thresholdCurvesForm';
export const thresholdCheckboxRegions = 'thresholdCheckbox';
export const markedCheckboxRegions = 'markedCheckbox';
export const thresholdSetUpValuesLabelRegions = 'thresholdSetUpValues';
export const firstUpperCurveRegions = 'firstUpperCurve';
export const targetCurveRegions = 'targetCurve';
export const firstLowerCurveRegions = 'firstLowerCurve';
export const secondLowerCurveRegions = 'secondLowerCurve';
export const criticalCurveRegions = 'criticalCurve';
export const alertTextAreaRegions = 'alertsTextArea';
export const startValueLabelRegions = 'startValue';
export const endValueLabelRegions = 'endValue';
export const previewThresholdCurvesSectionRegions = 'previewThresholdCurves';
export const thresholdCurveLineRegions = 'thresholdCurveLine';
export const thresholdCurveLabelRegions = 'thresholdCurveLabel';
export const thresholdValuesTooltipRegions = 'thresholdValuesTooltip';
export const tooltipHeaderLabelRegions = 'tooltipHeader';
export const tooltipCurveLabelRegions = 'tooltipCurve';
export const currentBoardingCircleRegions = 'currentBoardingCircle';

//Artificial Campaigns
export const toggleForArtificialCampaignsButtonArtificialCampaign = 'toggleForArtificialCampaigns';
export const toggleForHeatMappingButtonArtificialCampaign = 'toggleForHeatMapping';
export const heatMappingHeaderLabelArtificialCampaign = 'heatMappingHeader';
export const artificialCampaignsHeaderLabelArtificialCampaign = 'artificialCampaignsHeader';
export const campaignRulesAccordingToLabelArtificialCampaign = 'campaignRulesAccordingToLabel';
export const campaignRuleDropdownArtificialCampaign = 'campaignRuleDropdown';
export const penIconArtificialCampaign = 'penIcon';
export const amountOfCampaignRulesLabelArtificialCampaign = 'amountOfCampaignRules';
export const addNewCampaignRuleButtonArtificialCampaign = 'addNewCampaignRuleButton';
export const saveChangesForCampaignRulesButtonArtificialCampaign = 'saveChangesForCampaignRulesButton';
export const campaignRulesTableArtificialCampaign = 'campaignRulesTable';
export const deleteCampaignRuleButtonArtificialCampaign = 'deleteCampaignRuleButton';
export const timeZoneLabelArtificialCampaign = 'timeZoneLabel';
export const updateModalArtificialCampaign = 'updateModalArtificialCampaign';
export const modalTitleArtificialCampaign = 'modalTitleArtificialCampaign';
export const modalDescriptionArtificialCampaign = 'modalDescriptionArtificialCampaign';

//User Management
export const newUserButtonUserManagement = 'newUserButtonUserManagement';
export const userManagementLayoutUserManagement = 'userManagementLayout';
export const usersTableUserManagement = 'usersTable';
export const searchButtonUsersTableUserManagement = 'searchButtonUsersTableUserManagement';
export const customerAndRolesValuesUserManagement = 'customerAndRolesValues';

//Role Management
export const customerDropdownManageRoles = 'customerDropdownRolesModal';
export const rolesDropdownManageRoles = 'rolesDropdown';
export const roleOptionsManageRoles = 'roleOptions';
export const removeEntryButtonManageRoles = 'removeEntryButton';

//CRK Zones
export const zonesTableVesselDetails = 'zonesTableVesselDetails';
export const amountOfZoneSetsLabelVesselDetails = 'amountOfZoneSets';
export const addZoneSetButtonVesselDetails = 'addZoneSetButton';
